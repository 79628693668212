import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, computed, model } from '@angular/core';
import { DataTypes, HtItemType, HtRowData, HtTableColumn, HtTableRow } from '@hrs-ui/util-definitions';

const defaultColumn: HtTableColumn = {
    type: DataTypes.Text,
    name: '',
    displayName: '',
    columnUId: '',
    columnIndex: 0,
};

const defaultRow: HtTableRow = {
    rowData: {},
    rowUId: '',
    rowIndex: 0,
};

@Component({
    selector: 'ht-base-cell',
    template: '',
    styleUrls: ['./base-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseCellComponent<T extends HtItemType = HtItemType> {

    @Input()
    public disabled = false;

    @Input()
    public hidden = false;

    @Input()
    public tabIndex = -1;

    @Input()
    public editModeDisabled = false;

    @Output()
    public readonly valueChange = new EventEmitter<{ value: HtItemType; rowId: string; column: string }>();

    public readonly row$ = model<HtTableRow>(defaultRow, { alias: 'row' });
    public readonly column$ = model<HtTableColumn>(defaultColumn, { alias: 'column' });
    public readonly rowData$ = model<HtRowData>({}, { alias: 'rowData' });

    public readonly value$: Signal<T>;

    public abstract defaultValue: T;

    constructor() {
        this.value$ = computed(() => this._getValue(this.rowData$(), this.column$().name));
    }

    /**
     * evaluate Changes
     *
     * @param event
     */
    public onValueChange(event: HtItemType): void {
        this.valueChange.emit({ value: event, rowId: this.row$().rowUId, column: this.column$().name });
    }

    /**
     * determine cell value from column and row data.
     */
    protected _getValue(rowData: HtRowData, column: string): T {
        return rowData[column] as T ?? this.defaultValue;
    }
}
