import { Pipe, PipeTransform } from '@angular/core';
import { SettingsUtil } from '@hrs-ui/util-core';
import { ResponseRow } from '@hrs-ui/util-definitions';

@Pipe({
    name: 'tableRowToColorClass',
    standalone: true,
})
export class TableRowColorClassPipe implements PipeTransform {
    public transform(row: ResponseRow): string {
        const tableRowColor = SettingsUtil.getTableRowColor(row);

        return tableRowColor ? `ht-table-cell__color--${ tableRowColor }` : '';
    }
}
