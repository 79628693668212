import { MainmenuApi } from '@hrs-ui/apiclient';

type MenuItemsAjaxannotate = MainmenuApi.MenuItemsAjaxannotate;

export class LinkModel {

    /**
     * The name of a menu dropdown
     */
    public text: string;

    /**
     * the location the user will be taken to when clicking a link. Is hidden in the @attr() 'text'
     */
    public operationId: string;

    /**
     * Controls if this Link interactable?
     */
    public disabled?: boolean;

    /**
     * if this Link is an Menu Link?
     */
    public hasSubmenu?: boolean;

    /**
     * if this Link is an Menu Link?
     */
    public ajaxannotate?: MenuItemsAjaxannotate;

    /**
     * Function thats executed after Click eg. BackToMainMenu
     */
    public onClickFunction?: ($event: MouseEvent, linkItem: LinkModel) => void;

    /**
     * URL to an external page
     */
    public httpaddress?: string;

    constructor(
        data: LinkModelParameters = {},
    ) {
        this.text = data.text || '';
        this.operationId = data.operationId || '';
        this.disabled = data.disabled;
        this.hasSubmenu = data.hasSubmenu;
        this.ajaxannotate = data.ajaxannotate;
        this.httpaddress = data.httpaddress;
        this.onClickFunction = data.onClickFunction;
    }
}

interface LinkModelParameters {
    text?: string;
    operationId?: string;
    disabled?: boolean;
    hasSubmenu?: boolean;
    ajaxannotate?: MenuItemsAjaxannotate;
    httpaddress?: string;

    /**
     * function which gets executed after click on link
     *
     * @param $event
     * @param linkItem
     */
    onClickFunction?($event: Event, linkItem: LinkModel): void;
}
