/**
 * hTrak HRS API
 * Support API for login, logout, supported languages list, and list of orgunits available for user. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: v.shetty@prospitalis-htrak.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorMessage } from '../model/models';
import { InlineResponse200 } from '../model/models';
import { InlineResponse2001 } from '../model/models';
import { UserAccessToken } from '../model/models';
import { UserAuthentication } from '../model/models';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AuthApi {

    protected basePath = 'https://dev.htrak.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Switch the orgUnitID that will be used for all subsequent API request.
     * Changes the org unit associated with the current token. 
     * @param orgUnitID 
     */
    public authChangeOrgunit(orgUnitID: string): Observable<UserAccessToken> {
        if (orgUnitID === null || orgUnitID === undefined) {
            throw new Error('Required parameter orgUnitID was null or undefined when calling authChangeOrgunit.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<UserAccessToken>('get', `${this.configuration.basePath}/auth/orgUnitId/${encodeURIComponent(String(orgUnitID))}`,
            {
                body: null,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Retrieves the list of languages available on the server
     * Retrieves the list of languages available on the server
     */
    public authLanguages(): Observable<InlineResponse2001> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<InlineResponse2001>('get', `${this.configuration.basePath}/auth/languages`,
            {
                body: null,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Authenticate the user for further requests. Response token will be used to any other API endpoint.
     * OrgUnitID is optional. If mentioned then user is logged in into given orgunit. If not mentioned in input request, Access Token will be generated and logged into default top level orgunit in the organisation hierarchy. How ever client need to make an seperate explicit call for list of accesible orgUnits using auth/orgUnits end point.
     * @param userAuthentication 
     */
    public authLogin(userAuthentication?: UserAuthentication): Observable<UserAccessToken> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<UserAccessToken>('post', `${this.configuration.basePath}/auth/login`,
            {
                body: userAuthentication,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Logs out the user from HRS system.
     * Log the user out from the session specified in the session key
     */
    public authLogout(): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<any>('post', `${this.configuration.basePath}/auth/logout`,
            {
                body: null,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Request an updated user access token
     * Resets the timeout on the current timout key
     */
    public authMe(): Observable<UserAccessToken> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<UserAccessToken>('get', `${this.configuration.basePath}/auth/me`,
            {
                body: null,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Request for list of user accessible orgUnits.
     * Returns an array of objects that represent the org units this user is authorised to log into. The objects are the OrgUnitId and the display name of that org unit.
     */
    public authOrgunits(): Observable<InlineResponse200> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<InlineResponse200>('get', `${this.configuration.basePath}/auth/orgUnits`,
            {
                body: null,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Request a change of language.
     * Overrides the language of the user in the HRS for the current session only
     * @param cultureCode 
     */
    public authSwitchLanguage(cultureCode: string): Observable<any> {
        if (cultureCode === null || cultureCode === undefined) {
            throw new Error('Required parameter cultureCode was null or undefined when calling authSwitchLanguage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<any>('get', `${this.configuration.basePath}/auth/cultureCode/${encodeURIComponent(String(cultureCode))}`,
            {
                body: null,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

}
