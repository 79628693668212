import { ChangeDetectionStrategy, Component, Input, Signal, computed, model, signal } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';

@Component({
    selector: 'ht-textarray-dropdown',
    templateUrl: './ht-textarray-dropdown.component.html',
    styleUrls: [
        './ht-textarray-dropdown.component.scss',
    ],
    standalone: true,
    imports: [
        UiIconComponent,
        TranslationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class HtTextarrayDropdownComponent {
    @Input()
    public readOnly = false;

    public showAddInput = false;

    public readonly values$ = model<Array<string>>([], { alias: 'value' });
    public readonly filteredValues$: Signal<Array<string>>;

    private readonly _filterString$ = signal<string>('');

    constructor() {
        this.filteredValues$ = computed(() => {
            const filterString = this._filterString$();

            return this.values$()
                .filter(value =>
                    filterString ?
                        value.toLowerCase()
                            .includes(filterString.toLowerCase())
                        : true,
                );
        });
    }

    /**
     * opens and close the add-input
     */
    public toggleAdd(value?: boolean): void {
        this.showAddInput = value ? value : !this.showAddInput;
    }

    /**
     * add string to values-array
     *
     * @param value
     */
    public addText(value: string): void {
        if (this.values$()) {
            const newValue = [value, ...this.values$()];

            this.values$.set(newValue);
            this.toggleAdd();
        }
    }

    /**
     * set filter string
     *
     * @param $event
     */
    public setFilterString($event: Event): void {
        this._filterString$.set(($event.target as HTMLInputElement).value);
    }

    /**
     * delete value with given index
     *
     * @param index
     */
    public deleteIndex(index: number): void {
        if (this.values$()) {
            const newArray = [...this.values$()];

            newArray.splice(index, 1);
            this.values$.set(newArray);
        }
    }
}
