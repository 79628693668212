import { HtButtType, HtButton } from '@hrs-ui/util-definitions';

/**
 * A series
 */
export class ButtonUtil {

    // These ButtTypes will be filtered out of a button list
    private static readonly _neededButtonTypesFromOldSystem: Array<HtButtType> = [
        HtButtType.Submit,
        HtButtType.Reset,
        HtButtType.SubmitCrud,
        HtButtType.SubmitCrudAll,
        HtButtType.Back,
        HtButtType.Modal,
        HtButtType.Download,
        HtButtType.Redirect,
        HtButtType.CheckboxTrue,
        HtButtType.CheckboxFalse,
        HtButtType.SaveMultiple,
    ];

    /**
     * Takes a list of buttons and filters out ones we dont need based on a list defined in
     * this class (unneededButtonTypesFromOldSystem)
     *
     * @param buttons
     */
    public static filterNeededButtonTypes(buttons: Array<HtButton>): Array<HtButton> {
        return buttons.filter(button => this._neededButtonTypesFromOldSystem.indexOf(button.buttType) !== -1);
    }

    /**
     * find default button if exist by searching for a Settings property called default_enter
     * that is located in a button
     *
     * @param buttons
     * @constructor
     */
    public static filterDefaultButton(buttons: Array<HtButton>): HtButton | undefined {
        let defaultButton: HtButton | undefined;

        buttons.forEach(button => {
            if (button && button.settings
                && (button.settings.default_enter === 'true' || button.settings.default_enter === true)
            ) {
                defaultButton = button;
            }
        });

        return defaultButton;
    }
}
