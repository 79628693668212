@if (!!value$() && !hidden) {
    <div
        class="button-container"
        (click)="click()"
    >
        <ui-icon
            [iconName]="iconName$()"
            [iconSize]="20"
        />

        @if (buttonText$(); as buttonText) {
            <span class="button-text">
                {{ buttonText }}
            </span>
        }
    </div>
}
