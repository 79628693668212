<div #main class="main">
    <router-outlet />
</div>

<div class="dialog-manager">
    <!--Insert global components here-->
    <ht-modal />
</div>

<ht-notification-list />
