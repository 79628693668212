import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslationService } from './translation.service';

@Injectable({
    providedIn: 'root',
})
export class NgxTranslationService implements TranslationService {
    private readonly _ngxTranslateService = inject(TranslateService);

    public translate(key: string, parameters?: object): string {
        return this._ngxTranslateService.instant(key, parameters);
    }

    public translate$(key: string, parameters?: object): Observable<string> {
        return this._ngxTranslateService.get(key, parameters);
    }

    public streamTranslate$(key: string, parameters?: object): Observable<string> {
        return this._ngxTranslateService.stream(key, parameters);
    }

    public setLanguage(lang: string): void {
        this._ngxTranslateService.use(lang);
    }

    public setDefaultLanguage(lang: string): void {
        this._ngxTranslateService.setDefaultLang(lang);
    }
}
