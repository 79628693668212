export enum DataTypes {
    Numeric = 'numeric',
    NumericNonNeg = 'numeric_nonneg',
    Text = 'text',
    Textarea = 'textarea',
    Boolean = 'boolean',
    Date = 'date',
    DateTime = 'datetime',
    Button = 'button',
    Password = 'password',
    TextArray = 'textarray',
    EmailArray = 'emailarray',
    Delete = 'delete',
    AjaxArray = 'ajaxarray',
    ImageLink = 'image_link',
    Multiselect = 'multiselect',
}
