import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatOptionModule } from '@angular/material/core';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { SelectDropdownActionComponent } from '../select-dropdown-action/select-dropdown-action.component';
import { HtSelectComponent } from '../ht-select/ht-select.component';

@Component({
    selector: 'ht-multi-select',
    templateUrl: '../ht-select/ht-select.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatOptionModule,
        TranslationModule,
        SelectDropdownActionComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class HtMultiSelectComponent extends HtSelectComponent<Array<string>> {
    public override isMultiSelect = true;
}
