import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormElement } from '@hrs-ui/util-definitions';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { NoteComponent } from '@hrs-ui/ui/ui-presentation';
import { HtInputMapperComponent } from '@hrs-ui/ui/ui-input';
import { ButtonMapperComponent } from '../../button-mapper/button-mapper.component';
import { FilterComponent } from '../filter/filter.component';

@Component({
    selector: 'ht-quickfilter',
    templateUrl: './quickfilter.component.html',
    styleUrls: ['./quickfilter.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        FormsModule,
        UiIconComponent,
        NoteComponent,
        ButtonMapperComponent,
        HtInputMapperComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickfilterComponent extends FilterComponent {
    // In this component, hidden inputs and checkboxes are not displayed.
    protected override _isItemVisible(item: FormElement): boolean {
        return !item.hidden;
    }
}
