import { Injectable, Injector, inject } from '@angular/core';
import { Location } from '@angular/common';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { HttpStatusCode } from '@angular/common/http';
import { NotificationSeverity } from '@hrs-ui/notification/util-notification';
import { NotificationService } from '@hrs-ui/notification/domain-notification';
import { ResponseData } from '@hrs-ui/util-definitions';
import { MagicRouterService } from './magic-router.service';
import { RequestDataService } from './request-data.service';

type ResponseBody<T = object & { operationId: string }> = Record<string, T>;

@Injectable({
    providedIn: 'root',
})
export class HttpStatusCodeHandlerService {
    private readonly _injector = inject(Injector);
    private readonly _location = inject(Location);

    /**
     * Handles message http response body.
     *
     * @param responseBody - The http response body to handle.
     */
    public handleMessageResponseBody(responseBody: ResponseBody<ResponseData>): void {
        const responseData = responseBody['HRSMessageResponse'];

        if (responseData.message) {
            this._injector.get<ModalService>(ModalService).openMessage(responseData);
        }
    }

    /**
     * Handles redirect http response body.
     *
     * @param responseBody - The http response body to handle.
     * */
    public handleRedirectResponseBody(responseBody: ResponseBody): void {
        const { operationId, ...params } = responseBody['HRSRedirect'];

        this._location.back(); // remove 303-Redirect Response from Browser History
        this._injector.get<MagicRouterService>(MagicRouterService).operationId = operationId;
        this._injector.get<RequestDataService>(RequestDataService).data = params;
    }

    /**
     * Handle error response messages.
     *
     * @param httpStatusCode The http status code.
     * @param message Http message.
     */
    public handleErrorResponseMessage(httpStatusCode: HttpStatusCode, message: string): void {
        if ((httpStatusCode === HttpStatusCode.Forbidden) || (httpStatusCode >= HttpStatusCode.InternalServerError)) {
            this._injector.get(NotificationService).push(message, NotificationSeverity.Error);

            return;
        }

        if (httpStatusCode >= HttpStatusCode.BadRequest) {
            this._injector.get(NotificationService).push(message, NotificationSeverity.Info);

            return;
        }

        this._injector.get(NotificationService).push(message, NotificationSeverity.Error);
    }

    /**
     * Return nested values of an object.
     *
     * @param data The data to get nested values from.
     * */
    public getValues(data: object | string): Array<string> {
        if (!data) {
            return [];
        }

        if (typeof data === 'object') {
            return Object.values(data)
                .reduce((arr, value) => [...arr, ...this.getValues(value)], []);
        }

        return [data];
    }
}
