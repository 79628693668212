import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    inject,
} from '@angular/core';
import { PageData } from '@hrs-ui/util-definitions';
import { RequestDataService } from '@hrs-ui/api/domain-api';
import { saveAs } from 'file-saver-es';

@Component({
    selector: 'ht-blob-mapper',
    templateUrl: './blob-mapper.component.html',
    styleUrls: [
        './blob-mapper.component.scss',
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlobMapperComponent implements OnChanges {

    @Input()
    public blob?: PageData['blob'];

    protected readonly _requestDataService = inject(RequestDataService);

    /**
     * parse data on change
     *
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        // debounce download and get back in history
        if (changes['blob'] && !changes['blob'].firstChange) {
            this._reset();

            return;
        }

        if (!this.blob) {
            return;
        }

        if (this.blob && this.blob.type !== 'application/json') {
            const filename = `download.${ this.blob.type.substring(this.blob.type.indexOf('/') + 1) }`;

            saveAs(this.blob, filename);

            this._reset();
        }
    }

    /**
     * reset mapper data
     *
     * @private
     */
    private _reset(): void {
        this._requestDataService.resetData();
        window.history.back();
    }
}
