import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ngxTranslationServiceProvider } from './providers/ngx-translation-service-provider';
import { ngxRootTranslationModule } from './definitions/ngx-root-translation-module';
import { AsyncTranslatePipe } from './pipes/async-translate.pipe';

@NgModule({
    declarations: [
        AsyncTranslatePipe,
    ],
    imports: [
        TranslateModule,
    ],
    exports: [
        TranslateModule,
    ],
    providers: [
        ngxTranslationServiceProvider,
    ],
})
export class TranslationModule {
    public static forRoot(): Array<ModuleWithProviders<TranslateModule | TranslationModule>> {
        return [
            {
                ngModule: TranslationModule,
            },
            ngxRootTranslationModule,
        ];
    }
}
