import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ApiService } from '@hrs-ui/api/util-api';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { catchError, debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { QuickAccessItem, QuickAccess } from '@hrs-ui/quick-access/util-quick-access';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';
import { adapt } from '@state-adapt/angular';
import { toSource } from '@state-adapt/rxjs';

const storeDataDebounceTime = 500;

/**
 * Manages the storage of quick-access elements (favorites) for users depending on their active organisation.
 *
 * The legacy store does not know user ids, but does only hold favorites for entire organisations.
 */
@Injectable({
    providedIn: 'root',
})
export class QuickAccessService {
    public data$: Observable<QuickAccess>;

    private readonly _data;
    private readonly _userQuickAccessStorageKey = 'userQuickAccess';
    private readonly _legacyQuickAccessStorageKey = 'quickAccess'; // Only used for reading/migration
    private readonly _userChanged$ = new BehaviorSubject<undefined>(undefined);
    private readonly _dataChanged$ = new Subject<QuickAccess>();
    private _allUsersData: { [key: string]: QuickAccess } = {};

    private readonly _apiService = inject(ApiService);
    private readonly _tokenService = inject(TokenService);

    constructor() {
        // Fetch and process data everytime the user changes, including once at initialization.
        const storeData$ =
            this._userChanged$
                .pipe(
                    // Only update data if logged in
                    filter(() => !!this._tokenService.getUserName()),
                    switchMap(() => this._apiService.getFunction<Record<string, QuickAccess>>('get-me')(this._userQuickAccessStorageKey)),
                    switchMap(response =>
                        (this._tokenService.getUserName() in response)
                            // The organisation has a per-user store with data for this user
                            ? of(response)
                            // The organisation has a per-user store, but no data for this user
                            : this._migrateAndSetData$(),
                    ),
                    catchError(error => {
                        // The organisation does not have a per-user store yet
                        if (error.error.code === '6002') { // Error Code: invalid storage key
                            // Create the storage key, then migrate the data
                            return this._initStoreKey$(this._userQuickAccessStorageKey)
                                .pipe(
                                    switchMap(() => this._migrateAndSetData$()),
                                );
                        }

                        // For any other errors, return an empty store
                        return of({});
                    }),
                    switchMap((response: { [key: string]: QuickAccess }) => {
                        // The user does not have data in the per-user store
                        if (!(this._tokenService.getUserName() in response)) {
                            // Migrate the user's data from the per-org store
                            return this._migrateAndSetData$();
                        }

                        return of(response);
                    }),
                    tap(response => {
                        // Track the other users' data to keep it unchanged when writing to the store
                        this._allUsersData = { ...this._allUsersData, ...response };
                    }),
                    map(response => response[this._tokenService.getUserName()]),
                    shareReplay(1),
                );

        this._data = adapt(
            {} as QuickAccess,
            {
                adapter: {
                    set: (_, newState) => newState,
                    toggleItem: (state, item: QuickAccessItem) =>
                        this._isFavorite(item, state)
                            ? this._removeFromFavorites(item, state)
                            : this._addToFavorites(item, state),
                    removeItem: (state, item: QuickAccessItem) => this._removeFromFavorites(item, state),
                    updateItem: (state, item: QuickAccessItem) => this._updateQuickAccessItem(item, state),
                    moveItemPosition: (
                        state,
                        { dragItem, dropItem, orgUnitId }: { dragItem: QuickAccessItem; dropItem: QuickAccessItem; orgUnitId: string },
                    ) =>
                        this._moveItemPosition(orgUnitId, dragItem, dropItem, state),
                },
                sources: {
                    set: storeData$
                        .pipe(
                            toSource('storeData$'),
                        ),
                },
            },
        );

        this.data$ = this._data.state$;

        this._dataChanged$
            .pipe(
                debounceTime(storeDataDebounceTime),
            )
            .subscribe(data => {
                this._writeData(data);
            });
    }

    /**
     * compare two QuickAccessItems
     */
    public static compareQuickAccessItems(item1: QuickAccessItem, item2: QuickAccessItem): boolean {
        return JSON.stringify(item1.requestData) === JSON.stringify(item2.requestData)
            && item1.operationId === item2.operationId;
    }

    public onUserChanged(): void {
        this._userChanged$.next(undefined);
    }

    public toggleFavorite(item: QuickAccessItem): void {
        this._data.toggleItem(item);
    }

    public isFavorite$(item: QuickAccessItem): Observable<boolean> {
        return this._data.state$
            .pipe(
                map(quickAccess => this._isFavorite(item, quickAccess)),
            );
    }

    /**
     * moves item in orgUnit from index to other index
     */
    public moveItemInQuickAccess(orgUnitId: string, dragItem: QuickAccessItem, dropItem: QuickAccessItem): void {
        this._data.moveItemPosition({ dragItem, dropItem, orgUnitId });
    }

    public removeFromQuickAccess(item: QuickAccessItem): void {
        this._data.removeItem(item);
    }

    public updateQuickAccessItem(
        updatedQuickAccessItem: QuickAccessItem,
    ): void {
        this._data.updateItem(updatedQuickAccessItem);
    }

    public _isFavorite(item: QuickAccessItem, data: QuickAccess): boolean {
        const orgUnitData = data[item.orgUnitId];

        if (!orgUnitData) {
            return false;
        }

        return orgUnitData.some(
            existingItem => QuickAccessService.compareQuickAccessItems(existingItem, item),
        );
    }

    private _updateQuickAccessItem(
        updatedQuickAccessItem: QuickAccessItem,
        data: QuickAccess,
    ): QuickAccess {
        const orgUnitData = data[updatedQuickAccessItem.orgUnitId];

        if (!orgUnitData) {
            return data;
        }

        orgUnitData.forEach((quickAccessItem, i) => {
            if (QuickAccessService.compareQuickAccessItems(quickAccessItem, updatedQuickAccessItem)) {
                orgUnitData[i] = updatedQuickAccessItem;
            }
        });

        return this._updateOrgUnit(updatedQuickAccessItem.orgUnitId, orgUnitData, data);
    }

    /**
     * Rearranges the items in an org unit's quick access to move the dragged item to the index of the item it was dropped on.
     */
    private _moveItemPosition(orgUnitId: string, dragItem: QuickAccessItem, dropItem: QuickAccessItem, data: QuickAccess): QuickAccess {
        const orgUnitData = data[orgUnitId];

        if (!orgUnitData) {
            return data;
        }

        const previousIndex = orgUnitData
            .indexOf(dragItem);
        const index = orgUnitData
            .indexOf(dropItem);

        moveItemInArray(orgUnitData, previousIndex, index);

        return this._updateOrgUnit(orgUnitId, orgUnitData, data);
    }

    /**
     * removes provided QuickAccessItem from favorites when it exist
     */
    private _removeFromFavorites(item: QuickAccessItem, data: QuickAccess): QuickAccess {
        const orgUnitData = data[item.orgUnitId];

        if (!orgUnitData) {
            return data;
        }

        const deleteIndex = orgUnitData
            .findIndex(existingItem => QuickAccessService.compareQuickAccessItems(existingItem, item));

        if (deleteIndex >= 0) {
            orgUnitData.splice(deleteIndex, 1);
        }

        return this._updateOrgUnit(item.orgUnitId, orgUnitData, data);
    }

    /**
     * add provided QuickAccessItem to Favorites
     */
    private _addToFavorites(item: QuickAccessItem, data: QuickAccess): QuickAccess {
        const orgUnitData = data[item.orgUnitId] ?? [];

        orgUnitData.push(item);

        return this._updateOrgUnit(item.orgUnitId, orgUnitData, data);
    }

    /**
     * updates the Data for a single orgUnit
     * creates a new object in order to correctly update the state management
     */
    private _updateOrgUnit(orgUnit: string, orgUnitData: Array<QuickAccessItem>, data: QuickAccess): QuickAccess {
        const newData = { ...data, [orgUnit]: orgUnitData };

        this._dataChanged$.next(newData);

        return newData;
    }

    /**
     * Write the quick-access-data to the store without touching the favorites of other users in the organisations.
     */
    private _writeData(data: QuickAccess): void {
        this._apiService.getFunction('put-me')(
            this._userQuickAccessStorageKey,
            {
                ...this._allUsersData,
                [this._tokenService.getUserName()]: data,
            },
        ).subscribe();
    }

    /**
     * inits storage for given key
     */
    private _initStoreKey$(key: string): Observable<unknown> {
        return this._apiService.getFunction('post-me')(key, {});
    }

    /**
     * The favorites are now stored per-user instead of per-organisation.
     * This fetches the old per-organisation favorites and copies them to the user's storage.
     */
    private _migrateAndSetData$(): Observable<{ [key: string]: QuickAccess }> {
        return this._apiService.getFunction<QuickAccess>('get-me')(this._legacyQuickAccessStorageKey)
            .pipe(
                // No legacy store found. This is fine, just create an empty store then.
                catchError(() => of({})),
                // Copy all old data to new storage solution.
                tap<QuickAccess>(response => {
                    this._writeData(response);
                }),
                map(response => ({ [this._tokenService.getUserName()]: response })),
            );
    }
}
