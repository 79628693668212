@if (multitables) {
    @if (multitables.length) {
        @for (multitable of multitables; track $index) {
            <div class="widget--card">
                @for (tableObject of multitable | keyvalue; track $index) {
                    <ht-table-mapper
                        [tables]="tableObject.value"
                        [modalId]="modalId"
                    />
                }
            </div>
        }
    } @else {
        <div class="widget__wrapper">
            <span class="widget no-data">
                <ui-icon
                    iconName="error-circle"
                    [iconSize]="20"
                />

                {{ 'content.multitableMapper.noData' | translate }}
            </span>
        </div>
    }
}
