<div class="login-wrapper">
    <div class="login-box">
        <ht-title-screen-logo />

        <div class="login-box__text">
            <h3 class="headline">{{ 'login.oneTimePassword' | translate }}</h3>
        </div>

        <form (ngSubmit)="loginWithOTP()">
            <div class="login-row otp">
                <ui-input
                    #digitField
                    type="text"
                    placeholder=""
                    id="digit1"
                    name="digit1"
                    [tabIndex]="0"
                    [inputValidationFn]="inputValidationFns[0]"
                    [keyDownFn]="keyDownFns[0]"
                    [hideValidityIcons]="true"
                    [(ngModel)]="digits[0]"
                />
                <ui-input
                    #digitField
                    type="text"
                    placeholder=""
                    id="digit2"
                    name="digit2"
                    [tabIndex]="1"
                    [inputValidationFn]="inputValidationFns[1]"
                    [keyDownFn]="keyDownFns[1]"
                    [hideValidityIcons]="true"
                    [(ngModel)]="digits[1]"
                />
                <ui-input
                    #digitField
                    type="text"
                    placeholder=""
                    id="digit3"
                    name="digit3"
                    [tabIndex]="2"
                    [inputValidationFn]="inputValidationFns[2]"
                    [keyDownFn]="keyDownFns[2]"
                    [hideValidityIcons]="true"
                    [(ngModel)]="digits[2]"
                />
                <ui-input
                    #digitField
                    type="text"
                    placeholder=""
                    id="digit4"
                    name="digit4"
                    [tabIndex]="3"
                    [inputValidationFn]="inputValidationFns[3]"
                    [keyDownFn]="keyDownFns[3]"
                    [hideValidityIcons]="true"
                    [(ngModel)]="digits[3]"
                />
                <ui-input
                    #digitField
                    type="text"
                    placeholder=""
                    id="digit5"
                    name="digit5"
                    [tabIndex]="4"
                    [inputValidationFn]="inputValidationFns[4]"
                    [keyDownFn]="keyDownFns[4]"
                    [hideValidityIcons]="true"
                    [(ngModel)]="digits[4]"
                />
                <ui-input
                    #digitField
                    type="text"
                    placeholder=""
                    id="digit6"
                    name="digit6"
                    [tabIndex]="5"
                    [inputValidationFn]="inputValidationFns[5]"
                    [keyDownFn]="keyDownFns[5]"
                    [hideValidityIcons]="true"
                    [(ngModel)]="digits[5]"
                />
            </div>
            <div class="login-row login">
                <ui-button
                    type="submit"
                    icon="arrow-right"
                    [class]="'ui-button ui-button--white login-button'"
                    [label]="'login.confirmCode' | translate"
                    [iconSize]="16"
                    [hasIconLeft]="true"
                    [tabIndex]="6"
                    [disabled]="!digitsValid"
                />
            </div>
            <div class="login-row logout">
                <ui-button
                    type="button"
                    icon="arrow-left"
                    [class]="'ui-button ui-button--white login-button'"
                    [label]="'login.back' | translate"
                    [iconSize]="16"
                    [hasIconLeft]="true"
                    [tabIndex]="7"
                    (click)="returnToLogin()"
                />
            </div>
        </form>
    </div>
</div>
