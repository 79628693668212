<mat-select
    #target
    [placeholder]="placeholder ? placeholder : ''"
    [ngModel]="value"
    [name]="name ? name : ''"
    [panelWidth]="'auto'"
    [disabled]="disabled"
    [required]="required"
    [tabIndex]="tabIndex"
    [multiple]="isMultiSelect"
    (selectionChange)="onSelectionChange($event.value)"
>
    @if (allowSearch) {
        <mat-option class="mat-select-search">
            <ngx-mat-select-search
                [formControl]="selectFilterControl"
                [placeholderLabel]="'components.select.dropdown.search' | translate"
            />
        </mat-option>
    }

    @for (option of filteredOptions$(); track option.id) {
        <mat-option [value]="option.id">
            {{ option.value }}
        </mat-option>
    }
</mat-select>

<ht-select-dropdown-action [disabled]="disabled" />
