<!--Cannot be edited-->
@if (editModeDisabled) {
    <span>
        {{ formattedDate }}

        <span class="time">
            {{ formattedTime }}
        </span>
    </span>
}

@if (!editModeDisabled) {
    <ht-datepicker
        [name]="'datepicker'"
        [dataType]="dataType"
        [value]="dateTime"
        [disabled]="false"
        [class]="'datepicker-cell'"
        [tabIndex]="tabIndex"
        (valueChange)="valueChange.emit($event)"
    />
}
