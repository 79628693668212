<div class="widget__wrapper quickfilter">
    <form #filterForm="ngForm">
        @if (filterItems$().length) {
            <div class="filter-items">
                @for (filterItem of filterItems$(); track filterItem.name) {
                    <div class="options-wrapper">
                        <ht-input-mapper
                            [item]="filterItem"
                            [greyInput]="false"
                        />
                    </div>
                }
            </div>
        }

        @if (checkboxes$().length) {
            <div class="checkboxes">
                @for (checkbox of checkboxes$(); track checkbox.name) {
                    <ht-input-mapper
                        [item]="checkbox"
                        [greyInput]="false"
                    />
                }
            </div>
        }
    </form>

    @if (buttons$().length) {
        <ht-button-mapper
            class="button-mapper"
            [buttons]="buttons$()"
        />
    }

    @if (itemData$()?.note; as note) {
        <ht-note [note]="note" />
    }
</div>
