import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    Signal,
    ViewChild,
    computed,
    inject,
    model,
} from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MagicRouterService } from '@hrs-ui/api/domain-api';
import { EventService } from '@hrs-ui/domain-event';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { ButtonUtil } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { HtButton } from '@hrs-ui/util-definitions';
import { MatInputModule } from '@angular/material/input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-input',
    templateUrl: './ht-input.component.html',
    styleUrls: [
        './ht-input.component.scss',
    ],
    standalone: true,
    imports: [
        FormsModule,
        MatInputModule,
        MatAutocompleteModule,
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HtInputComponent implements OnInit, AfterViewInit {
    @HostBinding('class') public class = 'form-element';
    @ViewChild('htInput', { static: false }) public htInput?: ElementRef;

    @Input()
    public placeholder?: string;

    @Input()
    public name?: string;

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public type = 'input';

    @Input()
    public autocompleteBinding?: MatAutocomplete;

    @Input()
    public tabIndex = -1;

    @Input()
    public nonNeg = false;

    /**
     * Sets if one of the inputs is the 'Default Input'.
     * The Default Input has a function that is called upon the user hitting the Enter key
     * & calls the function of the corresponding Default button.
     * Use case: Hospital workers scanning many things in quick succession
     */
    @Input()
    public isDefaultTextEntry = false;

    // Use EventEmitter() for output instead of the model() because the output needs to be a different format.
    // When transform functions come to model(), this can be consolidated.
    @Output()
    public readonly valueChange: EventEmitter<string> = new EventEmitter();

    public readonly value$ = model<string | number | undefined>(undefined, { alias: 'value' });

    public readonly inputLength$: Signal<number>;

    private _defaultButton?: HtButton;

    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _buttonService = inject(ButtonService);
    private readonly _eventService = inject(EventService);
    private readonly _destroyRef = inject(DestroyRef);

    constructor() {
        this.inputLength$ = computed(() => this._inputLength(`${ this.value$() }` ?? ''));
    }

    public ngOnInit(): void {
        if (this.isDefaultTextEntry) {
            this._magicRouterService.pageData$.subscribe(pageData => {
                if (pageData && pageData.buttons) {
                    this._defaultButton = ButtonUtil.filterDefaultButton(pageData.buttons);
                }
            });
        }
    }

    public ngAfterViewInit(): void {
        if (this.isDefaultTextEntry) {
            this._focus();
        }

        this._eventService.focusFirstEditableCellByTabIndex$
            .pipe(
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe(firstEditableCellsTabIndex => {
                if (this.tabIndex === firstEditableCellsTabIndex) {
                    this._focus();
                }
            });
    }

    /**
     * Catch any input that doesn't match the expected type
     * Most input types validate automatically, so this is only for non-negative numerical values for now.
     */
    public validateInput($event: InputEvent): boolean {
        return !(this.nonNeg && $event.data && !$event.data.match(/^\d*$/));
    }


    /**
     * sed for quick scans
     *
     * @returns
     */
    public onEnterKeyPress(): void {
        if (!this.isDefaultTextEntry || !this._defaultButton) {
            return;
        }

        if (this.htInput) {
            this.htInput.nativeElement.blur();
        }

        this._buttonService.handleHtButtonClick(this._defaultButton);
    }

    /**
     * calculate width based on value
     */
    private _inputLength(value: string): number {
        const minLength = 8;
        const minLengthAdj = -0.1;
        const length = value?.toString().length ?? 0;

        return length ? length + Math.max(1, length * minLengthAdj + minLength) : minLength;
    }

    /**
     * Focuses this element without scrolling to the element
     */
    private _focus(): void {
        this.htInput?.nativeElement.focus({
            preventScroll: true,
        });
    }

}
