import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiInputComponent } from '../../ui-input/ui-input.component';

@Component({
    selector: 'ht-editable-password',
    templateUrl: './editable-password.component.html',
    standalone: true,
    imports: [
        FormsModule,
        UiInputComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditablePasswordComponent {

    @Input()
    public textValue = '';

    @Input()
    public editModeDisabled = true;

    @Input()
    public placeholderText = '';

    @Output()
    public readonly valueChange: EventEmitter<string> = new EventEmitter();
}
