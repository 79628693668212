import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { keyValueToSelectOption } from '../../../utils/key-value-to-select-option-mapper';
import { SelectOptions } from '../../../definitions/select-options';
import { FormsModule } from '@angular/forms';
import { HtTextComponent } from '../../ht-text/ht-text.component';

@Component({
    selector: 'ht-editable-number',
    templateUrl: './editable-number.component.html',
    standalone: true,
    imports: [
        FormsModule,
        HtTextComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableNumberComponent implements OnChanges {

    @Input()
    public textValue = '';

    @Input()
    public values?: Array<string>;

    @Input()
    public keys?: Array<string>;

    @Input()
    public nonNeg = false;

    @Input()
    public placeholderText = '';

    @Input()
    public readOnly = true;

    @Input()
    public tabIndex = -1;

    @Output()
    public readonly valueChange: EventEmitter<string> = new EventEmitter();

    public selectValues?: Array<SelectOptions>;

    public get disabledValue(): string {
        const numberTen = 10;

        const keyIndex = this.keys && this.keys.indexOf(this.textValue, numberTen);
        const value = keyIndex !== undefined && keyIndex !== -1 && this.values && this.values[keyIndex];

        return value ? value : this.textValue;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['values'] || changes['keys']) {
            this.selectValues = keyValueToSelectOption(
                changes['values']?.currentValue,
                changes['keys']?.currentValue,
            );
        }
    }
}
