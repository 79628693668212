import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { Modal } from '@hrs-ui/modal/util-modal';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { CommonModule } from '@angular/common';

const enterAnimation = trigger(
    'enterAnimation',
    [
        transition(
            ':enter',
            [
                style({ opacity: 0 }),
                animate('200ms 50ms ease-out', style({ opacity: 1 })),
            ],
        ),
        transition(
            ':leave',
            [
                style({ opacity: 1 }),
                animate('200ms ease-in', style({ opacity: 0 })),
            ],
        ),
    ],
);

@Component({
    selector: 'ht-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        UiIconComponent,
        ModalContentComponent,
    ],
    animations: [enterAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {

    @ViewChild('component', { read: ViewContainerRef, static: false })
    private readonly _viewContainerRef?: ViewContainerRef;

    public readonly modals$: Observable<Array<Modal>>;

    private readonly _modalService = inject(ModalService);

    constructor() {
        this.modals$ = this._modalService.modals$;
    }

    /**
     * close modal dialog
     */
    public close(id: number): void {
        this._modalService.closeModal(id);
    }
}
