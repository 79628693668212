import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, firstValueFrom } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiService } from '@hrs-ui/api/util-api';
import _ from 'lodash';

interface Sidebar {
    width: number;
    isOpen: boolean;
}

const defaultDebounceTime = 500;

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    public debouncedUpdateData = _.debounce(this.updateData, defaultDebounceTime);
    private readonly _sidebarStorageKey: string = 'sidebar';
    private readonly _defaultSidebarWidth = 250;
    private readonly _data$: BehaviorSubject<Sidebar> = new BehaviorSubject<Sidebar>({ width: this._defaultSidebarWidth, isOpen: true });
    private readonly _getData: () => Observable<Sidebar>;
    private readonly _setData: (data: Sidebar) => Promise<undefined>;

    private readonly _apiService = inject(ApiService);

    constructor() {
        this._getData = () => this._apiService.getFunction<Sidebar>('get-me')(this._sidebarStorageKey);
        this._setData = async (data: Sidebar) =>
            firstValueFrom(
                this._apiService.getFunction<undefined>('put-me')(this._sidebarStorageKey, data),
            );

        this._getData()
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.error.code === '6002') { // Error Code: invalid storage key
                        this._initStoreKey(this._sidebarStorageKey);
                    }

                    return NEVER;
                }),
            )
            .subscribe((response: Sidebar) => {
                this._data$.next(response);
            });
    }

    public get data$(): Observable<Sidebar> {
        return this._data$.asObservable();
    }

    /**
     * setSidebarWidth
     *
     * @param sidebarWidth
     */
    public setSidebarState(state: Partial<Sidebar>): void {
        this._data$.next({ width: state.width ?? this._data$.value.width, isOpen: state.isOpen ?? this._data$.value.isOpen });
        this.debouncedUpdateData();
    }

    /**
     * returns width value out of sidebar object
     */
    public getSidebarState$(): Observable<Sidebar> {
        return this.data$;
    }

    /**
     * update Data on Server
     */
    public updateData(): void {
        this._setData(this._data$.getValue());
    }

    /**
     * inits storage for given key
     *
     * @param key
     */
    private _initStoreKey(key: string): void {
        this._apiService.getFunction('post-me')(key, {})
            .pipe(
                take(1),
            )
            .subscribe();
    }

}
