import { ChangeDetectionStrategy, Component, Signal, computed, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LinkModel } from '../../../definitions/link.model';
import { ApiService } from '@hrs-ui/api/util-api';
import { MagicRouterService } from '@hrs-ui/api/domain-api';
import { MenuService } from '../../../services/menu.service';
import { HotReloadService } from '@hrs-ui/app-status/domain-app-status';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-sidebar-link-item',
    templateUrl: './link-item.component.html',
    styleUrls: ['./link-item.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkItemComponent {
    public readonly linkItemAjax$: Observable<string>;
    public readonly isActive$: Observable<boolean>;
    public readonly linkUrl$: Signal<string | undefined>;

    public readonly linkItem$ = input<LinkModel | undefined>(undefined, { alias: 'linkItem' });

    private readonly _menuService = inject(MenuService);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _apiService = inject(ApiService);
    private readonly _hotReloadService = inject(HotReloadService);

    constructor() {
        this.linkUrl$ = computed(() => {
            const linkItem = this.linkItem$();

            if (linkItem?.hasSubmenu) {
                return undefined;
            }

            return linkItem
                ? `${ window.location.origin }/#/content?operationId=${ linkItem.operationId }`
                : `${ window.location.origin }/#/dashboard`;
        });

        this.isActive$ = this._magicRouterService.operationId$
            .pipe(
                map(operationId =>
                    operationId === this.linkItem$()?.operationId,
                ),
            );

        const ajaxSettings$ = computed(() => this.linkItem$()?.ajaxannotate);

        this.linkItemAjax$ =
            toObservable(ajaxSettings$)
                .pipe(
                    switchMap(ajaxSettings => {
                        if (ajaxSettings?.interval && ajaxSettings.operationId) {
                            const operationId = ajaxSettings.operationId;

                            return timer(ajaxSettings.interval)
                                .pipe(
                                    switchMap(() => this._apiService.executeOperationId<{ [key: string]: number }>(operationId)),
                                    map(value => `(${ Object.values(value)[0] })`),
                                );
                        }

                        return of('');
                    }),
                );
    }

    /**
     * loads content or new menu-data based on linkItem
     *
     * @param $event
     * @param linkItem
     */
    public clickLinkItem($event: MouseEvent, linkItem: LinkModel): void | Promise<boolean> {
        $event.preventDefault();

        this._hotReloadService.checkHotReload();

        if (linkItem.onClickFunction) {
            return linkItem.onClickFunction($event, linkItem);
        }

        if (!linkItem.hasSubmenu) {
            this._magicRouterService.resetBackPacket();
            this._magicRouterService.operationId = linkItem.operationId;
        } else {
            this._menuService.menuOperationId = linkItem.operationId;
        }
    }
}
