<table
    class="ht-dynamic-table"
    (document:mousemove)="mousemove($event)"
    (document:mouseup)="mouseup()"
>
    <tbody>
        <tr
            #tableHeaderRow
            class="ht-dynamic-table-header-row"
        >
            @for (column of columns$(); track column.columnUId) {
                @if (tableKey$(); as tablekey) {
                    <th
                        #tableHeader
                        class="ht-dynamic-table-header-cell"
                        [style.textAlign]="column.headerAlign"
                        [style.width.px]="columnWidths$()[$index] | async"
                        [style.minWidth.px]="columnMinWidths$()[$index]"
                        [attr.data-name]="column.name"
                    >
                        <span class="ht-dynamic-table-header-cell__display-name">
                            @if (!column.readOnly && column.type === 'boolean' && column.grouped) {
                                <form #form="ngForm">
                                    <ht-checkbox
                                        name="select-all"
                                        [value]="rowsCheckedStatusByColumn$()[column.name]"
                                        (valueChange)="triggerCheckAllRowsOfColumn(column.name)"
                                    />
                                </form>
                            }

                            {{ column.displayName | translate }}

                            @if (column.hint) {
                                <ui-icon
                                    [iconName]="'info'"
                                    [iconSize]="16"
                                    [matTooltip]="column.hint"
                                />
                            }
                        </span>

                        <div
                            class="resize-trigger"
                            (mousedown)="mousedown($event, tableHeader, column.name)"
                        ></div>
                    </th>
                }
            }
        </tr>

        @for (row of rows$(); track row.rowUId) {
            <tr class="ht-dynamic-table-table-row">
                @for (column of columns$(); track column.columnUId) {
                    <ht-table-cell-mapper
                        class="ht-table-cell ht-dynamic-table-cell"
                        [row]="row"
                        [rowData]="rowData$()[row.rowUId]"
                        [column]="column"
                        (valueChange)="handleCellValueChange($event)"
                    />
                }
            </tr>
        }
    </tbody>
</table>
