import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { FormsModule } from '@angular/forms';
import { HtTextareaComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-textarea-cell',
    templateUrl: './textarea-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './textarea-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HtTextareaComponent,
        FormsModule,
    ],
})
export class TextareaCellComponent extends BaseCellComponent<string> implements HtCellComponent {
    public defaultValue = '';
}
