/**
 * Util functions that help deal with our Attributes (not to be confused with Attribute!)
 */
export class UrlParserUtil {

    /**
     * parse data to URL-string
     *
     * @param data
     */
    public static parseDataToUrl(data: object | string): string {
        return encodeURIComponent(JSON.stringify(data));
    }

    /**
     * parse URL-string to data
     *
     * @param url
     */
    public static parseUrlToData(url: string): string | object {
        return JSON.parse(decodeURIComponent(url));
    }

    /**
     * parse record to URL parameters
     *
     * @param data
     */
    public static parseDataToUrlParams(data?: Record<string, string | number | boolean>): string {
        const params = new Array<string>();

        for (const key in data) {
            if (data[key]) {
                params.push(`${ key.toLowerCase() }=${ data[key] }`);
            }
        }

        if (params.length) {
            return `?${ params.join('&') }`;
        }

        return '';
    }
}
