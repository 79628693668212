import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private readonly _loadingStatusBehaviorSubject: BehaviorSubject<boolean>
        = new BehaviorSubject<boolean>(true);

    public get loadingStatus$(): BehaviorSubject<boolean> {
        return this._loadingStatusBehaviorSubject;
    }

    public set loadingStatus(status: boolean) {
        this._loadingStatusBehaviorSubject.next(status);
    }

}
