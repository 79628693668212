@for (modal of modals$ | async; track modal.id) {
    <div
        class="modal"
        @enterAnimation
    >
        <div
            class="modal__container"
            [class.loading]="modal.loading"
        >
            <div class="modal__backdrop"></div>

            <div class="modal__content">
                <div class="loading__icon">
                    <img
                        src="/assets/images/loading.gif"
                        alt=""
                    />
                </div>

                <ui-icon
                    class="icon"
                    [iconName]="'close'"
                    [iconSize]="16"
                    (click)="close(modal.id)"
                />

                <div class="scroll-container">
                    <div class="modal__content-wrapper">
                        <ht-modal-content
                            [modal]="modal"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
