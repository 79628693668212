import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    Signal,
    ViewChild,
    computed,
    effect,
    inject,
    input,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormControl, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { EventService } from '@hrs-ui/domain-event';
import { debounceTime } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectDropdownActionComponent } from '../select-dropdown-action/select-dropdown-action.component';

const selectFilterDelay = 200;

interface FilterOption {
    id: string;
    value: string | number;
}

interface SearchableFilterOption extends FilterOption {
    searchValue: string;
}

@Component({
    selector: 'ht-select',
    templateUrl: './ht-select.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatOptionModule,
        TranslationModule,
        SelectDropdownActionComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HtSelectComponent<T = string> implements AfterViewInit {
    @HostBinding('class') public class = 'form-element';

    @ViewChild('target', { read: ElementRef, static: false })
    public target?: ElementRef;

    @Input()
    public placeholder?: string;

    @Input()
    public value?: T;

    @Input()
    public name?: string;

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public tabIndex = -1;

    @Input()
    public isDefaultSelect = false;

    @Input()
    public allowSearch = false;

    @Output()
    public readonly valueChange: EventEmitter<T> = new EventEmitter();

    public readonly options$ = input<Array<SearchableFilterOption>, Array<FilterOption> | undefined | null>(
        [],
        {
            alias: 'values',
            // Generate searchable options from input values
            transform: values =>
                values?.map(option => ({
                    ...option,
                    searchValue: option.value.toString().toLowerCase(),
                })) ?? [],
        });

    public readonly optionsFilter$ = signal<string>('');
    public readonly filteredOptions$: Signal<Array<FilterOption>>;

    public isMultiSelect = false;

    public selectFilterControl: FormControl<string | null> = new FormControl('');

    private readonly _eventService = inject(EventService);
    private readonly _destroyRef = inject(DestroyRef);

    constructor() {
        effect(() => {
            if (this.options$()) {
                // Whenever the options change, reset the filter control.
                this.selectFilterControl.setValue('');
            }
        });

        this.filteredOptions$ = computed(() =>
            this.generateFilteredOptions(this.options$(), this.optionsFilter$()),
        );

        this.selectFilterControl.valueChanges
            .pipe(
                takeUntilDestroyed(),
                debounceTime(selectFilterDelay),
            )
            .subscribe(filterValue => {
                this.optionsFilter$.set(filterValue ?? '');
            });
    }

    /**
     * After the view inits, call this function
     */
    public ngAfterViewInit(): void {
        if (this.isDefaultSelect) {
            this._focus();
        }

        this._eventService.focusFirstEditableCellByTabIndex$
            .pipe(
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe(firstEditableCellsTabIndex => {
                if (this.tabIndex === firstEditableCellsTabIndex) {
                    this._focus();
                }
            });
    }

    /**
     * Filters the options by the search value.
     * Removes the search values from the results.
     *
     * @param options
     * @param filterValue
     */
    public generateFilteredOptions(
        options: Array<SearchableFilterOption>,
        filterValue?: string | null,
    ): Array<FilterOption> {
        const lowerCaseFilterValue = filterValue?.toLowerCase() ?? '';

        return (
            filterValue
                ? options.filter(option =>
                    option.searchValue.includes(lowerCaseFilterValue),
                )
                : options
        )
            .map(({ id, value }) => ({ id, value }));
    }

    /**
     * Emits new value
     *
     * @param $event
     */
    public onSelectionChange($event: T): void {
        this.valueChange.emit($event);
    }

    /**
     * Focuses this element without scrolling to the element
     */
    private _focus(): void {
        this.target?.nativeElement.focus({
            preventScroll: true,
        });
    }
}
