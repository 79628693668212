@switch (column$().type) {
    @case (DataTypes.Boolean) {
        <ht-boolean-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.Button) {
        <ht-button-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.DateTime) {
        <ng-container [ngTemplateOutlet]="dateTimeCell" />
    }

    @case (DataTypes.Date) {
        <ng-container [ngTemplateOutlet]="dateTimeCell" />
    }

    @case (DataTypes.Delete) {
        <ht-delete-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.TextArray) {
        <ng-container [ngTemplateOutlet]="listCell" />
    }

    @case (DataTypes.EmailArray) {
        <ng-container [ngTemplateOutlet]="listCell" />
    }

    @case (DataTypes.Numeric) {
        <ht-numeric-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.NumericNonNeg) {
        <ht-numeric-nonneg-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.Password) {
        <ht-password-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.Text) {
        <ht-text-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.Textarea) {
        <ht-textarea-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @case (DataTypes.ImageLink) {
        <ht-image-link-cell
            class="ht-dynamic-table-cell"
            [tabIndex]="tabIndex$()"
            [disabled]="isDisabled$()"
            [hidden]="isHidden$()"
            [row]="row$()"
            [rowData]="rowData$()"
            [column]="column$()"
            (valueChange)="valueChange.emit($event)"
        />
    }

    @default {
        <ht-default-cell
            class="ht-dynamic-table-cell"
            [hidden]="isHidden$()"
            [rowData]="rowData$()"
            [column]="column$()"
        />
    }
}

<ng-template #dateTimeCell>
    <ht-datetime-cell
        class="ht-dynamic-table-cell"
        [dataType]="column$().type"
        [tabIndex]="tabIndex$()"
        [disabled]="isDisabled$()"
        [hidden]="isHidden$()"
        [row]="row$()"
        [rowData]="rowData$()"
        [column]="column$()"
        (valueChange)="valueChange.emit($event)"
    />
</ng-template>

<ng-template #listCell>
    <ht-list-cell
        class="ht-dynamic-table-cell"
        [tabIndex]="tabIndex$()"
        [disabled]="isDisabled$()"
        [hidden]="isHidden$()"
        [row]="row$()"
        [rowData]="rowData$()"
        [column]="column$()"
        (valueChange)="valueChange.emit($event)"
    />
</ng-template>
