@if (pageData$ | async; as pageData) {
    @if (pageData.pageTitle) {
        <h1 class="page-title">
            {{ pageData.pageTitle }}

            <div class="page-title__save-quick-access">
                <ht-save-quick-access
                    [isFavorite]="isFavorite$ | async"
                    [pageData]="pageData"
                />
            </div>
        </h1>
    }

    <ht-content-widget
        trackFocus
        [pageData]="pageData"
        [wrapped]="true"
    />
} @else {
    <span class="widget no-data">
        <ui-icon
            iconName="error-circle"
            [iconSize]="20"
        />

        {{ 'content.wrapper.noData' | translate }}
    </span>
}
