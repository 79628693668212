import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ht-title-screen-logo',
    templateUrl: './title-screen-logo.component.html',
    styleUrls: ['./title-screen-logo.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleScreenLogoComponent {
    public logo = 'assets/images/title-logo.svg';
}
