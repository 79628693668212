import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    HostBinding,
    Input,
    ViewChild,
    effect,
    inject,
    model,
    signal,
} from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HtInputComponent } from '../ht-input/ht-input.component';
import { ApiService } from '@hrs-ui/api/util-api';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { HtSelectComponent } from '../ht-select/ht-select/ht-select.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type Options = Array<{ [key: string]: string }>;
interface ServerResponse { [key: string]: Options }

const defaultDebounceTime = 300;

/**
 * This component is an input that triggers a search to the API after the user types a couple characters,
 * and populates a dropdown of possible options that can be selected for the input.
 * If the input type is set to ajaxarray, this component will be used.
 */
@Component({
    selector: 'ht-ajax-autocomplete',
    templateUrl: './ht-ajax-autocomplete.component.html',
    styleUrls: ['./ht-ajax-autocomplete.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatOptionModule,
        UiIconComponent,
        HtInputComponent,
        HtSelectComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HtAjaxAutocompleteComponent {
    @HostBinding('class') public class = 'form-element';
    @ViewChild('input', { static: false })
    public inputElem?: HtInputComponent;

    @Input()
    public placeholder?: string;

    @Input()
    public name?: string;

    @Input()
    public debounceTime = defaultDebounceTime;

    /**
     * This represents how many characters are typed until a result is found
     */
    @Input()
    public minLength = 1;

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public tabIndex = -1;

    @Input()
    public ajaxoperationId?: string;

    @Input()
    public ajaxproperty?: string;

    @Input()
    public filters?: object;

    @Input()
    public isDefaultTextEntry = false;

    public readonly value$ = model<string | undefined>(undefined, { alias: 'value' });

    public readonly isLoading$ = signal(true);
    public listeningForAjaxTrigger?: boolean;

    public readonly values$: Observable<Array<{ id: string; value: string }>>;

    private readonly _inputValue$ = new BehaviorSubject('');
    private readonly _ajaxTriggered$ = new Subject();
    private readonly _stopAjaxListener$ = new Subject();

    private readonly _apiService = inject(ApiService);
    private readonly _form = inject(NgForm);
    private readonly _destroyRef = inject(DestroyRef);

    constructor() {
        effect(() => {
            const value = this.value$();

            this._inputValue$.next(value ?? '');
        });

        // Generate new options from the backend whenever the ajax element is triggered or the input value changes.
        this.values$ = merge(
            this._ajaxTriggered$
                .pipe(
                    map(() => true),
                ),
            this._inputValue$
                .pipe(
                    // Give some grace time while typing in the input.
                    debounceTime(this.debounceTime),
                    map((input: string) =>
                        // If the input value is too short, generate empty options.
                        input.length >= this.minLength,
                    ),
                ),
        )
            .pipe(
                tap(() => this.isLoading$.set(true)),
                switchMap(shouldFetchOptions =>
                    shouldFetchOptions
                        ? this._fetchOptions$()
                        : of(undefined),
                ),
                map((response?: ServerResponse) => response ? Object.values(response)[0] : []),
                map((data?: Array<{ [key: string]: string }>) => {
                    if (!data) {
                        return [];
                    }

                    const options = data.map(item => ({
                        id: Object.values(item)[0],
                        value: Object.values(item)
                            .join(' - '),
                    }));

                    return options;
                }),
                tap(() => this.isLoading$.set(false)),
                tap(options => {
                    if (
                        this.listeningForAjaxTrigger
                    ) {
                        const value = this.value$();

                        // For inputs that have an ajax trigger, whenever the options change,
                        // and the current option is no longer available, automatically select the first one.
                        // ajax-triggered options only change when the triggering input changes,
                        // so changing the selected option doesn't change the generated values.
                        if (!options.some(option => option.id === value)) {
                            this.value$.set(options[0]?.id);
                        }

                        // If an options update was triggered, but the value is unchanged,
                        // observers should still be triggered as they may use the previous trigger's changed value for their options.
                        // To achieve this, the form control's value is updated and will emit valueChanges.
                        if (this.value$() === value && this.name) {
                            this._form.form.get(this.name)?.updateValueAndValidity({ onlySelf: true, emitEvent: true });
                        }
                    }
                }),
                distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
            );
    }

    @Input()
    public set ajaxtrigger(value: string | undefined) {
        this.listeningForAjaxTrigger = !!value;
        this._stopAjaxListener$.next(undefined);

        // wait for first complete frame is rendered
        if (value) {
            requestAnimationFrame(() => {
                const formElem = this._form && this._form.form.get(value);

                if (formElem) {
                    this._ajaxTriggered$.next(undefined);
                    formElem
                        .valueChanges
                        .pipe(
                            takeUntilDestroyed(this._destroyRef),
                            takeUntil(this._stopAjaxListener$),
                        )
                        .subscribe(() => this._ajaxTriggered$.next(undefined));
                }
            });
        }
    }

    /**
     * fetch options based on input
     *
     * @param event
     */
    public requestOptions(event: Event): void {
        if (this.listeningForAjaxTrigger) {
            return;
        }

        const target = event.target as HTMLInputElement;

        this._inputValue$.next(target?.value ?? '');
    }

    private _fetchOptions$(): Observable<ServerResponse | undefined> {
        const fetchFn = this._apiService.getFunction<ServerResponse>(this.ajaxoperationId || '');

        if (!fetchFn) {
            console.warn('invalid ajax operationId', this.ajaxoperationId);

            return of(undefined);
        }

        // Use all set form values as request params
        const params: { [key: string]: unknown } = {};

        Object.keys(this._form.form.controls)
            .forEach(key => {
                params[key] = this._form.form.controls[key].value;
            });

        if (this.filters) {
            params['filters'] = this.filters;
        }

        return fetchFn(params);
    }
}
