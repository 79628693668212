@if (pageData$(); as pageData) {
    @if (pageData.pageTitle && !wrapped) {
        <h1 class="page-title">
            {{ pageData.pageTitle }}
        </h1>
    }

    <ht-legend-mapper
        [legend]="pageData.legend"
    />

    <ht-multitable-mapper
        [multitables]="pageData.multitables"
        [modalId]="modalId"
    />

    <ht-table-mapper
        [tables]="pageData.tables"
        [modalId]="modalId"
    />

    <ht-button-mapper
        [buttons]="filteredButtons$()"
        [modalId]="modalId"
    />

    @if (pageData.blob) {
        <ht-blob-mapper
            [blob]="pageData.blob"
        />
    }
}
