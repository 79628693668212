import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { HtButton, PageData } from '@hrs-ui/util-definitions';
import { ButtonCallbacks } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { HtButtonComponent } from '@hrs-ui/ht-button/ui-ht-button';

@Component({
    selector: 'ht-button-mapper',
    templateUrl: './button-mapper.component.html',
    styleUrls: [
        './button-mapper.component.scss',
    ],
    standalone: true,
    imports: [
        HtButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMapperComponent {

    @Input()
    public buttons?: PageData['buttons'];

    @Input()
    public modalId?: number;

    private readonly _buttonService = inject(ButtonService);

    /**
     * handle click on Button
     *
     * @param button
     * @param buttonCallbacks
     */
    public handleButtonClick(button: HtButton, buttonCallbacks: ButtonCallbacks): void {
        this._buttonService.handleHtButtonClick(button, this.modalId, buttonCallbacks);
    }
}
