@if (openInModal) {
    <ui-button
        type="button"
        icon="add"
        [label]="insertData?.tableTitle || 'content.insertImage.manage' | translate"
        [class]="'insert-add-button'"
        [iconSize]="16"
        [hasIconLeft]="true"
        (click)="openInsertInModal()"
    />
} @else {
    <div
        #wrapper
        class="widget--card"
    >
        @if (insertData?.tableTitle) {
            <h2 class="edit-title">
                {{ insertData?.tableTitle }}
            </h2>
        }

        <form
            #insertForm="ngForm"
            (ngSubmit)="saveUpload()"
        >
            <div class="form--elements">
                <!-- Fields generated from table data -->
                @for (item of insertData?.items; track item.name) {
                    @if (!item.hidden) {
                        <div class="item">
                            <div class="title">
                                {{ item.displayName }}
                            </div>

                            <div class="value">
                                <ht-input-mapper
                                    [isFirstInput]="$first"
                                    [item]="item"
                                    [tabIndex]="0"
                                />
                            </div>
                        </div>
                    }
                }

                <!-- Image upload field-->
                <div class="item">
                    <div class="title">
                        {{ 'content.insertImage.new' | translate }}
                    </div>

                    <div class="value">
                        <ht-image-input
                            name="newImage"
                            [titleText]="'content.insertImage.new' | translate"
                            [required]="true"
                            [tabIndex]="0"
                            (valueChange)="imageSelected($event)"
                        />
                    </div>
                </div>

                <!-- New image thumbnail & preview -->
                <ht-image-preview
                    title="content.insertImage.preview"
                    [imageUrl]="newImageUrl$ | async"
                    (showImage)="showImage($event)"
                >
                    <ng-container ngProjectAs="no-image-template">
                        <label for="newImage">
                            {{ 'content.insertImage.noNewImage' | translate }}
                        </label>
                    </ng-container>
                </ht-image-preview>

                <!-- Current image thumbnail & preview -->
                <ht-image-preview
                    title="content.insertImage.current"
                    [imageUrl]="currentImageUrl$ | async"
                    [hasError]="hasError$()"
                    (showImage)="showImage($event)"
                >
                    <ng-container ngProjectAs="no-image-template">
                        {{ 'content.insertImage.noCurrentImage' | translate }}
                    </ng-container>
                </ht-image-preview>
            </div>

            <div class="button-row">
                <div class="spacer"></div>
                @if (modalId) {
                    <ui-button
                        icon="close"
                        [label]="'content.insertImage.close' | translate"
                        [processing]="isProcessing$()"
                        [iconSize]="16"
                        [hasIconLeft]="true"
                        [isOutlined]="true"
                        (click)="closeModal(modalId)"
                    />
                }

                <ng-container>
                    <ui-button
                        type="button"
                        icon="delete"
                        [label]="'content.insertImage.delete' | translate"
                        [disabled]="!currentImageUrl$"
                        [processing]="isProcessing$()"
                        [iconSize]="16"
                        [hasIconLeft]="true"
                        [tabIndex]="0"
                        (focusout)="resetFocus()"
                        (click)="delete()"
                    />
                </ng-container>

                <ng-container>
                    <ui-button
                        type="submit"
                        icon="check"
                        [label]="'content.insertImage.save' | translate"
                        [disabled]="!newImage"
                        [processing]="isProcessing$()"
                        [iconSize]="16"
                        [hasIconLeft]="true"
                        [tabIndex]="0"
                        (focusout)="resetFocus()"
                    />
                </ng-container>
            </div>
        </form>
    </div>
}
