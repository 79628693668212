import { EventService } from '@hrs-ui/domain-event';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    Input,
    Signal,
    ViewChild,
    computed,
    inject,
    input,
    model,
} from '@angular/core';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { FormsModule } from '@angular/forms';
import { HtCheckboxComponent } from '../../ht-checkbox/ht-checkbox.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-editable-boolean',
    templateUrl: './editable-boolean.component.html',
    styleUrls: ['./editable-boolean.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
        FormsModule,
        HtCheckboxComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableBooleanComponent implements AfterViewInit {
    // @ViewChild('htCheckbox', {read: ElementRef, static: false}) public htCheckbox?: ElementRef;
    @ViewChild('htCheckbox', { read: ElementRef, static: false })
    public target?: ElementRef;

    @Input()
    public editModeDisabled = true;

    @Input()
    public label = '';

    @Input()
    public tabIndex = 0;

    public readonly isChecked$ = model<boolean>(false, { alias: 'checked' });
    public readonly isDisabled$ = input<boolean>(false, { alias: 'disabled' });

    public readonly iconStyle$: Signal<string>;
    public readonly iconName$: Signal<string>;

    protected readonly _eventService = inject(EventService);

    private readonly _destroyRef = inject(DestroyRef);

    constructor() {
        this.iconStyle$ = computed(() => {
            const isDisabled = this.isDisabled$();
            const isChecked = this.isChecked$();

            if (isDisabled) {
                return 'error-circle--disabled';
            }

            if (isChecked) {
                return 'check-circle';
            }

            return 'error-circle';
        });

        this.iconName$ = computed(() => {
            const isChecked = this.isChecked$();

            if (isChecked) {
                return 'check-circle';
            }

            return 'error-circle';
        });
    }

    /**
     * After the view inits, call this function
     */
    public ngAfterViewInit(): void {
        this._eventService.focusFirstEditableCellByTabIndex$
            .pipe(
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe(firstEditableCellsTabIndex => {
                if (this.tabIndex === firstEditableCellsTabIndex) {
                    this._focus();
                }
            });
    }

    /**
     * Focuses this element without scrolling to the element
     */
    private _focus(): void {
        this.target?.nativeElement.focus({
            preventScroll: true,
        });
    }
}
