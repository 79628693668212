import { EventAction } from './event-action.type';
import { EventType } from './event.type';

export class EventModel {
    constructor(
        public payload: unknown,
        public type: EventType,
        public action?: EventAction,
    ) {

    }
}
