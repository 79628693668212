export class EventButtonsModel {
    constructor(
        public payload: unknown,
        public type: Array<string>,
        public values: Array<string>,
        public buttonType?: string,
    ) {

    }
}
