import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TabTitleService } from '@hrs-ui/app-status/domain-app-status';
import { UiInputComponent } from '@hrs-ui/ui/ui-input';
import { AuthApi } from '@hrs-ui/apiclient';
import { TranslationModule, TranslationService, UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { NotificationSeverity } from '@hrs-ui/notification/util-notification';
import { NotificationService } from '@hrs-ui/notification/domain-notification';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { FormsModule } from '@angular/forms';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TitleScreenLogoComponent } from '../title-screen-logo/title-screen-logo.component';

type UserAuthentication = AuthApi.UserAuthentication;

const maxDigitIndex = 5;

type InputValidationFn = (event: InputEvent) => boolean;
type KeyDownFn = (event: KeyboardEvent) => void;

@Component({
    selector: 'ht-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        UiButtonComponent,
        FormsModule,
        UiInputComponent,
        UiIconComponent,
        TitleScreenLogoComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OTPComponent implements OnInit, AfterViewInit {
    @ViewChildren('digitField') public digitComponents?: QueryList<UiInputComponent>;

    public digits: Array<string> = ['', '', '', '', '', ''];
    public digitInputs: Array<HTMLInputElement | undefined> = [];

    public invalidTokenErrorMessage?: string;

    public inputValidationFns: Array<InputValidationFn>;
    public keyDownFns: Array<KeyDownFn>;

    private readonly _authService = inject(AuthService);
    private readonly _tabTitleService = inject(TabTitleService);
    private readonly _notificationService = inject(NotificationService);
    private readonly _translationService = inject(TranslationService);
    private readonly _quickAccessService = inject(QuickAccessService);
    // Inject UserLanguageService to initialize the translation language.
    private readonly _userLanguageService = inject(UserLanguageService);

    private _hasAutoSentCode = false;

    constructor() {
        this._translationService.translate$('errorCodes.invalidToken')
            .subscribe(errorMessage => {
                this.invalidTokenErrorMessage = errorMessage;
            });

        this.inputValidationFns =
            Array.from(Array(maxDigitIndex + 1).keys())
                .map(index => this._inputValidationFn(index, { isFinalField: index === maxDigitIndex }));

        this.keyDownFns =
            Array.from(Array(maxDigitIndex + 1).keys())
                .map(index => this._keyDownFn(index));
    }

    public get digitsValid(): boolean {
        return this.digits.every(digit => !!digit);
    }

    public ngOnInit(): void {
        this._authService.updateAccessToken();

        this._tabTitleService.setTitleByTranslationKey('login.tabTitle');
    }

    public ngAfterViewInit(): void {
        this.digitInputs = this.digitComponents?.toArray().map(component => component?.input?.nativeElement) ?? [];

        // Focus the first input.
        this.digitInputs[0]?.focus();
    }

    /**
     * confirm OTP code to login
     */
    public loginWithOTP(): void {
        if (!this._authService.userWaitingForMFA) {
            return;
        }

        const authentication: UserAuthentication = {
            ...this._authService.userWaitingForMFA,
            oneTimePassword: this.digits.join('').substring(0, maxDigitIndex + 1),
        };

        this._authService.loginUser$(authentication)
            .subscribe({
                next: () => {
                    this._quickAccessService.onUserChanged();
                },
                error: error => {
                    if (this.invalidTokenErrorMessage && error.message === 'errorCodes.invalidToken') {
                        this._notificationService.push(this.invalidTokenErrorMessage, NotificationSeverity.Error);
                    }
                },
            });
    }

    public returnToLogin(): void {
        this._authService.logoutUser();
    }

    private _inputValidationFn(index: number, { isFinalField }: { isFinalField?: boolean }): (event: InputEvent) => boolean {
        return (event: InputEvent) => {
            const inputText = event.data;
            const inputType = event.inputType;
            const selectionStart = this.digitInputs?.[index]?.selectionStart ?? 0;
            const selectionEnd = this.digitInputs?.[index]?.selectionEnd ?? 0;

            let nextFieldIndex: number | undefined;
            let shouldPlaceCursorAtEnd = false;

            if (inputText && (inputText?.length ?? 0) === 1) {
                if (selectionStart === 0) {
                    this.digits[index] = inputText ?? '';
                    nextFieldIndex = index + 1;
                } else {
                    this.digits[Math.min(index + 1, maxDigitIndex)] = inputText ?? '';
                    nextFieldIndex = index + 1;
                    shouldPlaceCursorAtEnd = true;
                }
            }

            if (inputText && (inputText?.length ?? 0) > 1) {
                let lastIndex = index;

                inputText.split('').forEach((char, charIndex) => {
                    if (index + charIndex <= maxDigitIndex) {
                        this.digits[index + charIndex] = char;
                        lastIndex = Math.min(index + charIndex, maxDigitIndex);
                    }
                });

                nextFieldIndex = lastIndex;
            }

            if (inputType === 'deleteContentBackward') {
                if (selectionEnd > 0) {
                    this.digits[index] = '';
                } else {
                    this.digits[index - 1] = '';
                    nextFieldIndex = index - 1;
                }
            }

            if (inputType === 'deleteContentForward') {
                if (selectionStart === 0 && this.digits[index].length) {
                    this.digits[index] = '';
                } else {
                    this.digits.splice(index + 1, 1);
                    this.digits.push('');
                }
            }

            if (nextFieldIndex !== undefined) {
                const nextField = this.digitInputs[nextFieldIndex];

                if (nextField) {
                    nextField.focus({ preventScroll: true });
                    nextField.selectionStart = shouldPlaceCursorAtEnd ? 1 : 0;
                    nextField.selectionEnd = 1;
                }
            }

            this._submitIfCompleted(isFinalField);

            return false;
        };
    }

    private _keyDownFn(index: number): (event: KeyboardEvent) => void {
        return (event: KeyboardEvent) => {
            if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                const selectionStart = this.digitInputs?.[index]?.selectionStart ?? 0;
                const selectionEnd = this.digitInputs?.[index]?.selectionEnd ?? 0;

                if (event.key === 'ArrowLeft' && selectionEnd === 0) {
                    const nextField = this.digitInputs[index - 1];

                    if (nextField) {
                        nextField.focus({ preventScroll: true });
                        nextField.selectionStart = nextField.selectionEnd = 1;

                        event.stopPropagation();
                        event.preventDefault();
                    }
                }

                if (event.key === 'ArrowRight' && selectionStart === this.digits[index].length) {
                    const nextField = this.digitInputs[index + 1];

                    if (nextField) {
                        nextField.focus({ preventScroll: true });
                        nextField.selectionStart = nextField.selectionEnd = 0;

                        event.stopPropagation();
                        event.preventDefault();
                    }
                }
            }

            if (event.key === 'Enter' && this.digitsValid) {
                this.loginWithOTP();
            }
        };
    }

    private _submitIfCompleted(isFinalField?: boolean): void {
        if (!this._hasAutoSentCode && isFinalField && this.digits.every(digit => !!digit)) {
            this.loginWithOTP();

            this._hasAutoSentCode = true;
        }
    }
}
