import { Injectable, Injector, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { OrgUnitService, TokenService } from '@hrs-ui/app-status/domain-app-status';

@Injectable({
    providedIn: 'root',
})
export class SSOGuard {
    private readonly _tokenService = inject(TokenService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _injector = inject(Injector);

    /**
     * Never fails, but checks for an SSO-Token and logs in automatically if available.
     */
    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        const ssoToken = this._tokenService.getAndDestroySsoToken();

        if (ssoToken) {
            this._tokenService.setToken(ssoToken);
            this._injector.get<UserLanguageService>(UserLanguageService).language = ssoToken.cultureCode || '';
            this._orgUnitService.data = ssoToken.orgUnitID || '';
        }

        return of(true);
    }
}
