import { CanDisableConstructor } from '../definitions/can-disable';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HasElementRefConstructor } from '../definitions/has-element-ref';

/**
 * Mixin to augment a directive with a `disabled` property.
 */
export function mixinDisabled<T extends HasElementRefConstructor>(
    base: T,
    defaultClass?: string,
): CanDisableConstructor & T {
    return class extends base {
        private _disabled = false;
        private readonly _targetClass = '';

        constructor(...args: Array<any>) {
            super(...args);
            this._targetClass = defaultClass ? defaultClass : this.elementRef?.nativeElement.nodeName.toLowerCase();
        }

        public get disabled(): boolean {
            return this._disabled;
        }

        public set disabled(value: unknown) {
            this._disabled = coerceBooleanProperty(value);
            this._disabled ?
                this.elementRef?.nativeElement.classList.add(`${ this._targetClass }--disabled`) :
                this.elementRef?.nativeElement.classList.remove(`${ this._targetClass }--disabled`);
        }
    };
}
