@if (legend) {
    <div
        class="legend-marker"
        (click)="openLegend()"
    >
        <ui-icon
            [iconName]="'info'"
            [iconSize]="16"
        />

        <span>
            {{ 'content.legend.title' | translate }}
        </span>
    </div>
}
