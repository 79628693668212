<div class="page__header">
    <ht-navbar />
</div>
<div class="page__wrapper">
    <ht-sidebar class="sidebar" />
    <div
        #main
        class="main"
        id="main"
        [class.loading]="isLoading$()"
    >
        <div class="loading__icon">
            <img
                src="/assets/images/loading.gif"
                alt=""
            >
        </div>
        <div class="scroll-container">
            <router-outlet />
        </div>
    </div>
</div>
