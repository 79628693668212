@if (message) {
    <div class="message">
        {{ message }}
    </div>

    @if (modalId) {
        <ui-button
            icon="close"
            [label]="'content.message.close' | translate"
            [iconSize]="16"
            [hasIconLeft]="true"
            (click)="closeModal()"
        />
    }
}
