import { Injectable } from '@angular/core';
import { SidebarContentModel } from '../definitions/sidebar-content.model';
import { SidebarNode } from '../definitions/sidebar-node';

/**
 * Type guard
 */
@Injectable({
    providedIn: 'root',
})
export class SidebarContentTypeGuard {

    public isSidebarContent = (f: SidebarNode): f is SidebarContentModel =>
        (f as SidebarContentModel).name !== undefined
            && (f as SidebarContentModel).submenus !== undefined
            && (f as SidebarContentModel).open !== undefined;
}
