@if (!hidden) {
    <form
        #form="ngForm"
        class="form"
    >
        <ht-textarray
            [value]="value$()"
            [readOnly]="column$().readOnly || disabled"
            [name]="'textarray'"
            (valueChange)="onValueChange($event)"
        />
    </form>
}
