import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ht-sidebar-loading',
    templateUrl: './sidebar-loading.component.html',
    styleUrls: [
        '../link-item/link-item.component.scss',
        '../menu-item/menu-item.component.scss',
        './sidebar-loading.component.scss',
    ],
    standalone: true,
    imports: [
        CommonModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLoadingComponent {
    public skeletonItems: Array<number>;

    private readonly _amountOfSkeletonItems: number = 6;

    constructor() {
        this.skeletonItems = Array.from(Array(this._amountOfSkeletonItems), () => 1);
    }

    public get randomWidth(): string {
        const widthBase = 30;
        const widthFlex = 50;

        return `${ (Math.random() * widthFlex + widthBase).toString() }%`;
    }
}
