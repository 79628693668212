<input
    #htInput
    matInput
    [placeholder]="placeholder ? placeholder : ''"
    [name]="name ? name : ''"
    [type]="type"
    [readonly]="disabled"
    [required]="required"
    [class.disabled]="disabled"
    [matAutocomplete]="autocompleteBinding!"
    [matAutocompleteDisabled]="autocompleteBinding === undefined"
    [style.width.ch]="inputLength$()"
    [tabindex]="tabIndex"
    [min]="nonNeg ? 0 : false"
    [(ngModel)]="value$"
    (keydown.enter)="onEnterKeyPress()"
    (ngModelChange)="valueChange.emit($event)"
    (beforeinput)="validateInput($event)"
/>

@if (disabled) {
    <div class="square">
        <img
            src="data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'&gt;&lt;/svg&gt;"
            alt="square"
        />

        <ui-icon
            class="icon disabled"
            [iconName]="'locked'"
            [iconSize]="20"
        />
    </div>
}
