<div class="paginator-wrapper mat-paginator">
    @if (paginationDetails) {
        <mat-paginator
            class="paginator"
            showFirstLastButtons="true"
            [length]="paginationDetails.numOfItems"
            [pageIndex]="paginationDetails.currentPage - 1"
            [pageSize]="paginationDetails.perPage"
            [pageSizeOptions]="paginationDetails.numOfItems | toPageSizeOptions"
            (page)="page.emit($event)"
        />

        <mat-form-field class="page-selector">
            <mat-select
                [panelWidth]="'auto'"
                [value]="paginationDetails.currentPage"
                (selectionChange)="pageSelection.emit($event)"
            >
                @for (option of paginatorSelectOptions; track option) {
                    <mat-option [value]="option">
                        {{ option }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    }
</div>
