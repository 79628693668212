import { Injectable } from '@angular/core';
import { RequestData } from '@hrs-ui/api/util-api';
import { saveAs } from 'file-saver-es';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    // IE11 Issue: we need to debounce downloads once it was performed till the next time a 'normal' JSON response was parsed
    public downloadDebounced = false;

    // store downloadFileName temporarily after button click, will be set to undefined after download again
    public downloadFileName?: string;

    // store lastOperationId temporarily for redirecting after download
    public lastOperationId?: string;

    public lastRequestData?: RequestData;

    /**
     * download File and redirect to latest Route
     *
     * @param serverResponse
     * @private
     */
    public async downloadFile(serverResponse: Blob): Promise<void> {
        return new Promise(resolve => {
            // IE11 issue: debounce download to not having multiple downloads at once..
            this.downloadDebounced = true;

            const extension = `.${ serverResponse.type.substring(serverResponse.type.indexOf('/') + 1) }`;

            let filename = this.downloadFileName || 'download';

            if (!filename.includes(extension)) {
                filename += extension;
            }

            saveAs(serverResponse, filename);
            resolve();
        });
    }

    /**
     * returns temporarily stored values and resets them to undefined
     */
    public getStoredValuesOnce(): {
        operationId: string | undefined;
        requestData: RequestData | undefined;
    } {

        const operationId = this.lastOperationId;
        const requestData = { ...this.lastRequestData };

        this.lastOperationId = undefined;
        this.lastRequestData = undefined;

        return {
            operationId,
            requestData,
        };
    }
}
