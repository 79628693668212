import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { RequestDataService, DownloadService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { RequestData, defaultRequestData, ApiService } from '@hrs-ui/api/util-api';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { finalize, take } from 'rxjs/operators';
import { HtButton, HtButtType, HtItemType, HtRowData, ServerResponse, ButtonName } from '@hrs-ui/util-definitions';
import { KeyValueMapperUtil } from '@hrs-ui/util-data-mapping';
import { UrlParserUtil } from '@hrs-ui/util-route';
import { ButtonEventData, ButtonClickData, ButtonCallbacks } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonIdIconMap } from '../definitions/button-id-icon-map';

/**
 * A service to handle the logic for our buttons,
 * which is a little more special than the normal button in other apps due to
 * htrak buttons containing the data and logic that will be passed to the API.
 * Often times, the frontend does not know everything that the button will do and we shouldnt have to as we are just
 * passing some data to the api which will perform the logic in the backend for us.
 */
@Injectable({
    providedIn: 'root',
})
export class ButtonService {
    private readonly _buttonEvent$: Subject<ButtonEventData> = new Subject<ButtonEventData>();

    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _requestDataService = inject(RequestDataService);
    private readonly _apiService = inject(ApiService);
    private readonly _modalService = inject(ModalService);
    private readonly _downloadService = inject(DownloadService);

    public get buttonEvent$(): Observable<ButtonEventData> {
        return this._buttonEvent$.asObservable();
    }

    /**
     * returns Icon-Name based on button-type
     *
     * @param buttonId
     */
    public getIconName(buttonId: ButtonName): string {
        // warn if there is a unknown button-id
        if (!ButtonIdIconMap[buttonId]) {
            console.warn('Icon missing for ButtonId:', buttonId);
        }

        return ButtonIdIconMap[buttonId] || ButtonIdIconMap.submit;
    }

    /**
     * handle all buttonClicks
     *
     * @param button
     */
    public buttonClick(button: ButtonClickData): void {
        this._handleButtonClick(button);
    }

    /**
     * handle click on Button
     *
     * @param button
     * @param modalId
     * @param buttonCallbacks
     */
    public handleHtButtonClick(button: HtButton, modalId?: number, buttonCallbacks?: ButtonCallbacks): void {
        const parameter = button.values && button.keys
            ? KeyValueMapperUtil
                .mapKeysValues(button.keys, button.values)
            : {};

        this.buttonClick({
            id: button.id,
            buttType: button.buttType,
            operationId: button.operationId,
            postoperationId: button.postoperationId,
            parameter,
            data: button.data,
            data_validator: button.data_validator,
            modalId,
            filename: button.filename,
            buttonCallbacks,
        });
    }

    /**
     * return data-observable of all tables
     *
     * @param button
     */
    private _getDataOfTables(button: ButtonClickData): Observable<Array<{ [key: string]: HtItemType }>> {
        if (!button.data) {
            return of([]);
        }

        // every table gets an event with subject where it has to pipe its data into
        // TODO: what is if there is not table with given table-key? ignore, block or throw error?
        const subjects = Object.keys(button.data)
            .map(table => {
                const dataSubject = new ReplaySubject<HtRowData>();

                this._buttonEvent$.next({
                    table,
                    tableColumns: button.data && button.data[table] || [],
                    data_validator: button.data_validator && button.data_validator[table] || [],
                    buttType: button.buttType,
                    operationId: button.operationId,
                    parameter: button.parameter,
                    dataSubject,
                });

                return dataSubject;
            });


        return combineLatest(subjects)
            .pipe(
                take(1),
            );
    }

    /**
     * Handles the logic and data within the button that was clicked.
     * Here, the button's buttType will determine what is to happen when the button is clicked and
     * which other properties need to be given to the api in order to perform an action.
     *
     * @param button
     */
    private _handleButtonClick(button: ButtonClickData): void {
        switch (button.buttType) {
            case HtButtType.Modal: {
                // opens a new modal on top
                // to open a new modal and close the existing modal use redirect-buttontype!
                this._handleModal(button);
                break;
            }
            case HtButtType.RedirectNewTab: {
                if (button.operationId) {
                    // if tables/filters etc. are given, then grab all the data and push it into the requestData
                    this._getDataOfTables(button)
                        .subscribe(args => {
                            let requestData = this._generateRequestDataOutOfButtonAndArgs(args, button);

                            requestData = {
                                pageRequest: this._requestDataService.data.pageRequest,
                                ...requestData,
                            };

                            const url = `${ window.location.origin }/#/content?operationId=${ button.operationId
                                }&requestData=${ UrlParserUtil.parseDataToUrl(requestData)
                                }`;

                            window.open(url);
                        });
                }

                break;
            }
            case HtButtType.Redirect:
            case HtButtType.Download: {
                // download and redirect behaves the same because download redirects to a download page

                // if redirect is triggered in a modal we close the modal and open a new one
                // to open a new modal and don't close the existing modal use modal-buttontype!
                if (button.modalId) {
                    // close modal on back-navigation
                    if (button.id === ButtonName.Back) {
                        this._modalService.closeModal(button.modalId);
                    } else {
                        this._handleModal(button, true);
                    }
                } else if (button.operationId) {
                    // store filename and redirect to operationId when this value is updated
                    this._downloadService.downloadFileName = button.filename;
                    this._downloadService.lastRequestData = this._requestDataService.data;
                    this._downloadService.lastOperationId = this._magicRouterService.operationId;

                    this._magicRouterService.operationId = button.operationId;

                    // if tables/filters etc. are given, then grab all the data and push it into the requestData
                    if (button.data) {
                        this._getDataOfTables(button)
                            .subscribe(args => {
                                const requestData = this._generateRequestDataOutOfButtonAndArgs(args, button);

                                this._requestDataService.updateData(requestData);

                            });
                    } else {
                        // without given tables just add the data provided in the button.
                        this._requestDataService.data = {
                            filters: [
                                button.parameter,
                            ],
                        };
                    }
                }

                this._redirectToPostoperationIdIfExist(button);
                break;
            }
            case HtButtType.Send: {
                // plain fire-and-forget rowData submit.
                // used only for table-rows!
                // otherwise use submit to submit other formdata
                if (!button.rowData) {
                    throw new Error('SEND BUTTON ERROR: missing rowData');
                }

                this._submitApiRequest(button, { data: button.rowData }, false);
                break;
            }
            case HtButtType.Reset: {
                // if triggered in modal -> reload the modal
                if (button.modalId) {
                    this._modalService.reloadModal(button.modalId);
                } else {
                    // reload page and set parameters given in reset-button
                    this._magicRouterService.resetPage();
                    this._requestDataService.data = {
                        filters: [
                            button.parameter,
                        ],
                    };
                }

                break;
            }
            case HtButtType.Submit: {
                // just submits data and dont care about the response
                if (button.data) {
                    // submit with data out of tables
                    this._getDataOfTables(button)
                        .subscribe(args => {
                            const requestData = this._generateRequestDataOutOfButtonAndArgs(args, button);

                            this._submitApiRequest(button, requestData);
                        });
                } else {
                    // submit only the data of the button
                    this._submitApiRequest(button, { filters: [button.parameter] });
                }

                break;
            }
            default: {
                // if tables are given we add them to the buttonEvent otherwise we just publish the event
                if (button.data) {
                    Object.keys(button.data)
                        .forEach(table => {
                            this._buttonEvent$.next({
                                table,
                                tableColumns: button.data && button.data[table] || [],
                                data_validator: button.data_validator && button.data_validator[table] || [],
                                buttType: button.buttType,
                                operationId: button.operationId,
                                parameter: button.parameter,
                                buttonCallbacks: button.buttonCallbacks,
                            });
                        });
                } else {

                    this._buttonEvent$.next({
                        buttType: button.buttType,
                        operationId: button.operationId,
                        parameter: button.parameter,
                        buttonCallbacks: button.buttonCallbacks,
                    });
                }

                // redirect if postoperationId is given or if SubMitCrudAll in modal was triggered then close the modal
                if (!this._redirectToPostoperationIdIfExist(button) && button.buttType === HtButtType.SubmitCrudAll && button.modalId) {
                    requestAnimationFrame(() => {
                        this._modalService.closeModal(button.modalId);
                    });
                }
            }
        }
    }

    /**
     * Generates RequestData out of Buttondata and Subject args
     *
     * @param args
     * @param button
     */
    private _generateRequestDataOutOfButtonAndArgs(
        args: Array<object>,
        button: ButtonClickData,
    ): Partial<RequestData> {

        return {
            filters: [{
                ...args.reduce(
                    ((previousValue, currentValue) => ({ ...previousValue, ...currentValue })),
                    {},
                ),
                ...button.parameter,
            }],
        };
    }

    /**
     * redirects to the postoparationId after app-redraw if the postoperationId exist
     *
     * @param button
     */
    private _redirectToPostoperationIdIfExist(button: ButtonClickData): boolean {
        if (button.postoperationId) {
            this._handleButtonClick({
                id: button.id,
                buttType: HtButtType.Redirect,
                parameter: {},
                modalId: button.modalId,
                operationId: button.postoperationId,
            });

            return true;
        }

        return false;
    }

    /**
     * create api request Observable
     *
     * @param button
     * @param data
     * @param addPageData
     */
    private _submitApiRequest(button: ButtonClickData, data: object, addPageData: boolean = true): void {
        if (!button.operationId) {
            throw new Error('missing operationId');
        }

        // getting update function with operationId
        const apiFn = this._apiService.getFunction<ServerResponse>(button.operationId);

        if (!apiFn) {
            throw new Error(`missing API-function for ${ button.operationId }`);
        }

        const requestBody = {
            ...(addPageData ? defaultRequestData : {}),
            ...button.parameter,
            ...data,
        };

        button.buttonCallbacks?.buttonDisableCallback();

        apiFn(requestBody)
            .pipe(
                take(1),
                finalize(() => { button.buttonCallbacks?.buttonEnableCallback(); }),
            )
            .subscribe(() => {
                // automatic handling of page-reloading -> no need to call it in every switch-statement
                // modals will be reloaded and regular pages if no postoperationId is given
                if (button.modalId) {
                    this._modalService.reloadModal(button.modalId);
                } else if (!this._redirectToPostoperationIdIfExist(button)) {
                    this._magicRouterService.reloadPage();
                }
            });
    }

    /**
     * collects data and opens modal
     *
     * @param button
     * @param closeModal
     */
    private _handleModal(button: ButtonClickData, closeModal = false): void {
        // modals can only be handled with operationId
        if (button.operationId) {
            // grab data out of tables to pass it into modal (like redirect)
            if (button.data) {
                this._getDataOfTables(button)
                    .subscribe(args => {
                        const requestData = this._generateRequestDataOutOfButtonAndArgs(args, button);

                        button.parameter = requestData.filters?.[0] ?? {};

                        // close existing modal if closeModal is set to true
                        if (closeModal && button.modalId) {
                            this._modalService.closeModal(button.modalId);
                        }

                        this._modalService.openModal(button);
                    });
            } else {
                if (closeModal && button.modalId) {
                    this._modalService.closeModal(button.modalId);
                }

                this._modalService.openModal(button);
            }
        }
    }
}
