import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';

const enum TokenErrorCodes {
    TokenExpired = 1020,
    TokenUnknown = 1021,
    TokenMissing = 1024,
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly _tokenService = inject(TokenService);
    private readonly _authService = inject(AuthService);
    private readonly _location = inject(Location);

    /**
     * intercept every request and add auth-token
     * handles error responses
     *
     * @param req
     * @param next
     */
    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this._tokenService.getToken$()
            .pipe(
                mergeMap(accessToken => {
                    if (!accessToken) {
                        return next.handle(req);
                    } else if (!this._tokenService.isValid()) {
                        this._authService.redirectUrl = this._location.path();
                        this._authService.clearUser();

                        return next.handle(req);
                    }

                    const cloned = req.clone({
                        headers: req.headers.set('HRS-Access-Token', accessToken),
                    });

                    return next.handle(cloned)
                        .pipe(
                            tap(() => {
                                this._tokenService.renewTokenTime();
                            }),
                        );
                }),
                catchError(
                    (_errorResponse: HttpErrorResponse) => {
                        // All failed requests enter this
                        const errorCode = _errorResponse.error && _errorResponse.error.code;

                        if (
                            [
                                TokenErrorCodes.TokenExpired,
                                TokenErrorCodes.TokenUnknown,
                                TokenErrorCodes.TokenMissing,
                            ].includes(parseInt(errorCode || '', 10))
                        ) {
                            this._authService.redirectUrl = this._location.path();
                            this._authService.clearUser();
                        }

                        // DO NOTHING, this is wanted or handled elsewhere...
                        throw _errorResponse;
                    },
                ),
            );
    }
}
