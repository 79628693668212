import { CanRequireConstructor } from '../definitions/can-require';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HasElementRefConstructor } from '../definitions/has-element-ref';

/**
 * Mixin to augment a directive with a `disabled` property.
 */
export function mixinRequired<T extends HasElementRefConstructor>(base: T, defaultClass?: string): CanRequireConstructor & T {
    return class extends base {
        private _required = false;
        private readonly _targetClass = '';

        constructor(...args: Array<any>) {
            super(...args);
            this._targetClass = defaultClass ? defaultClass : this.elementRef?.nativeElement.nodeName.toLowerCase();
        }

        public get required(): boolean {
            return this._required;
        }

        public set required(value: unknown) {
            this._required = coerceBooleanProperty(value);
            this._required ?
            this.elementRef?.nativeElement.classList.add(`${ this._targetClass }--required`) :
            this.elementRef?.nativeElement.classList.remove(`${ this._targetClass }--required`);
        }
    };
}
