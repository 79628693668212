import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    Signal,
    ViewChild,
    ViewChildren,
    computed,
    inject,
    input,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DataTypes, Settings, HtTableColumn, HtTableRow, HtItemType, HtRowData } from '@hrs-ui/util-definitions';
import { HtTableWidthService } from '../../services/table-width.service';
import { TranslateModule } from '@ngx-translate/core';
import { TableCellMapperComponent } from '../table-cell-mapper/table-cell-mapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HtCheckboxComponent } from '@hrs-ui/ui/ui-input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

export interface HtCellComponent {
    column?: HtTableColumn;
    row?: HtTableRow;
    settings?: Settings;
    tabIndex?: number;
    editModeDisabled?: boolean;
    readonlyClass?: boolean;
    disabled?: boolean;
    defaultFocus?: boolean;
}

@Component({
    selector: 'ht-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: [
        './dynamic-table.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        HtCheckboxComponent,
        UiIconComponent,
        MatTooltipModule,
        TableCellMapperComponent,
        TranslateModule,
    ],
})
export class HtDynamicTableComponent implements AfterViewInit {
    @ViewChildren('tableHeader')
    public tableHeaders?: QueryList<ElementRef<HTMLTableCellElement>>;

    // For TableComponent to lookup the header row height
    @ViewChild('tableHeaderRow', { static: false })
    public tableHeaderRow?: ElementRef<HTMLTableRowElement>;

    @ViewChildren('tableCell')
    public tableCells?: QueryList<TableCellMapperComponent>;

    @HostBinding('class.half-width')
    @Input()
    public halfWidth?: boolean = false;

    @HostBinding('class.fixed-width')
    @HostBinding('style.min-width.px')
    @Input()
    public width?: string;

    @Input()
    public contentType?: string;

    @Output()
    public readonly checkAllRowsOfColumn = new EventEmitter<{ column: string; shouldBeChecked: boolean }>();

    @Output()
    public readonly cellValueChange = new EventEmitter<{ value: HtItemType; rowId: string; column: string }>();

    public readonly columns$ = input<Array<HtTableColumn>>([], { alias: 'columns' });
    public readonly rows$ = input<Array<HtTableRow>>([], { alias: 'rows' });
    public readonly rowData$ = input<Record<string, HtRowData>>({}, { alias: 'rowData' });
    public readonly tableKey$ = input<string | undefined>(undefined, { alias: 'tableKey' });

    public readonly columnWidths$: Signal<Array<Observable<number | undefined>>>;
    public readonly columnMinWidths$: Signal<Array<number | undefined>>;
    public readonly rowsCheckedStatusByColumn$: Signal<Record<string, boolean>>;

    private _resizeElemX = 0;
    private _resizeElemWidth = 0;
    private _resizeElem?: HTMLTableCellElement;
    private _resizeColumnName?: string;

    private readonly _tableWidthService = inject(HtTableWidthService);

    constructor() {
        this.rowsCheckedStatusByColumn$ = computed(() => {
            const rowData = this.rowData$();

            return this.columns$()
                .reduce<Record<string, boolean>>((collection, column) => {
                    if (column.type === DataTypes.Boolean && column.grouped) {
                        return {
                            ...collection,
                            [column.name]: !!Object.values(rowData).every(data => !!data[column.name]),
                        };
                    }

                    return collection;
                }, {});
        });

        this.columnWidths$ = computed(() => {
            const tableKey = this.tableKey$();
            const columns = this.columns$();

            return (tableKey && columns)
                ? columns?.map(column => this._tableWidthService.cellWidth$(tableKey, column.name))
                : [];
        });

        this.columnMinWidths$ = computed(() => {
            const tableKey = this.tableKey$();
            const columns = this.columns$();

            return (tableKey && columns)
                ? columns?.map(column => this._tableWidthService.minWidthByType(column.type))
                : [];
        });
    }

    /**
     * angular after view init
     */
    public ngAfterViewInit(): void {
        if (!this.tableHeaders) {
            return;
        }

        // Sets the width for columns
        if (this.tableHeaders) {
            this.tableHeaders.forEach(tableHeader => {
                const style = tableHeader.nativeElement.style;
                const tableKey = this.tableKey$();

                if (!style.width) {
                    if (tableKey) {
                        const width = tableHeader.nativeElement.offsetWidth;

                        this._tableWidthService.setCellWidth(
                            width,
                            tableKey,
                            tableHeader.nativeElement.dataset['name'] ?? '',
                        );
                    }
                }
            });
        }
    }

    /**
     * resize event
     *
     * @param $event
     * @param elem
     * @param columnName
     */
    public mousedown($event: MouseEvent, elem: HTMLTableCellElement, columnName: string): void {
        this._resizeElem = elem;
        this._resizeElemX = $event.pageX;
        this._resizeElemWidth = elem.offsetWidth;
        this._resizeColumnName = columnName;
    }

    /**
     * resize event
     *
     * @param $event
     */
    public mousemove($event: MouseEvent): void {
        if (this._resizeElem) {
            this._resizeElem.style.width = `${ this._resizeElemWidth + $event.pageX - this._resizeElemX }px`;
        }
    }

    /**
     * resize event
     */
    public mouseup(): void {
        const tableKey = this.tableKey$();

        if (this._resizeElem && tableKey && this._resizeColumnName) {
            this._tableWidthService.setCellWidth(this._resizeElem.offsetWidth, tableKey, this._resizeColumnName);
        }

        this._resizeElem = undefined;
    }

    /**
     * React to value changes in any of the cells
     *
     * @param event
     */
    public handleCellValueChange({ value, rowId, column }: { value: HtItemType; rowId: string; column: string }): void {
        this.cellValueChange.emit({ value, rowId, column });
    }

    /**
     * Tell the parent table and the children to check all cells of the given boolean column
     */
    public triggerCheckAllRowsOfColumn(columnName: string): void {
        const areAllRowsChecked = this.rowsCheckedStatusByColumn$()[columnName];

        this.checkAllRowsOfColumn.emit({ column: columnName, shouldBeChecked: !areAllRowsChecked });
    }
}
