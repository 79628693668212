import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTypes } from '@hrs-ui/util-definitions';
import { HtDatepickerComponent } from '../../ht-datepicker/ht-datepicker.component';

@Component({
    selector: 'ht-editable-datetime',
    templateUrl: './editable-datetime.component.html',
    styleUrls: ['./editable-datetime.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        HtDatepickerComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableDatetimeComponent implements OnChanges {

    @Input()
    public dateTimeString?: string;

    @Input()
    public dateFormat = '';

    @Input()
    public dataType: DataTypes = DataTypes.DateTime;

    @Input()
    public timeFormat = 'dd.mm.yyyy';

    @Input()
    public timeFormatOptions: object = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    @Input()
    public dateFormatOptions: object = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    };

    @Input()
    public cultureCode = 'de-DE';

    @Input()
    public editModeDisabled = true;

    @Input()
    public tabIndex = -1;

    @Output()
    public readonly valueChange: EventEmitter<Date> = new EventEmitter();

    public dateTime: Date | null = null;

    public formattedDate = '';
    public formattedTime = '';

    /**
     * Update the date when the input string changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['dateTimeString']) {
            this._convertDateInput();
        }
    }

    private _convertDateInput(): void {
        // DateTime example: http://localhost:4200/table/put_ousupplies
        if (this.dateTimeString) {
            this.dateTime = new Date(this.dateTimeString);

            if (this.dateTime.toLocaleString(this.cultureCode, this.dateFormatOptions) !== 'Invalid Date') {
                this.formattedDate = this.dateTime.toLocaleString(this.cultureCode, this.dateFormatOptions);
            }

            if (this.dateTime.toLocaleString(this.cultureCode, this.timeFormatOptions) !== 'Invalid Date') {
                this.formattedTime = this.dateTime.toLocaleString(this.cultureCode, this.timeFormatOptions);
            }
        } else {
            this.dateTime = null;
            this.formattedDate = '';
            this.formattedTime = '';
        }
    }
}
