import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, catchError, of } from 'rxjs';

export class TranslationProvider implements TranslateLoader {
    constructor(
        private readonly _http: HttpClient,
        public prefix: string = '/assets/i18n/',
        public suffix: string = '.json',
    ) { }

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<unknown> {
        return this._http
            .get(`${ this.prefix }${ lang }${ this.suffix }`)
            .pipe(
                catchError(() => of({})),
            );
    }
}
