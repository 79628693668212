<div class="dropdown">
    <div class="dropdown-row search">
        <div class="search__wrapper">
            <input
                type="text"
                [placeholder]="'components.textarray.dropdown.search' | translate"
                (input)="setFilterString($event)"
            />

            <ui-icon
                class="icon search__icon"
                [iconName]="'search'"
                [iconSize]="20"
            />
        </div>

        @if (!readOnly) {
            <ui-icon
                class="icon add"
                [iconName]="'add'"
                [iconSize]="20"
                (click)="toggleAdd(true)"
            />
        }
    </div>
    @if (showAddInput && !readOnly) {
        <div class="dropdown-row add grey-inputs">
            <input
                #inputElement
                type="text"
            />

            <ui-icon
                class="icon add"
                [iconName]="'check'"
                [iconSize]="16"
                (click)="addText(inputElement.value)"
            />

            <ui-icon
                class="icon close"
                [iconName]="'close'"
                [iconSize]="16"
                (click)="toggleAdd(false)"
            />
        </div>
    }

    <div class="dropdown-row items">
        @for (value of filteredValues$(); track value) {
            <div class="item">
                <span>{{ value }}</span>

                @if (!readOnly) {
                    <ui-icon
                        class="icon"
                        [iconName]="'delete'"
                        [iconSize]="16"
                        (click)="deleteIndex($index)"
                    />
                }
            </div>
        }
    </div>
</div>
