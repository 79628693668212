import { Event, NavigationStart, Router, RouterModule } from '@angular/router';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Signal, ViewChild, inject } from '@angular/core';
import { LoadingService } from '@hrs-ui/app-status/domain-app-status';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { NavbarComponent } from '../navbar/navbar.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
        NavbarComponent,
        SidebarComponent,
        RouterModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultComponent implements AfterViewInit {
    @ViewChild('main', { static: false })
    private readonly _scrollFrame?: ElementRef;

    public readonly isLoading$: Signal<boolean>;

    private readonly _loadingService = inject(LoadingService);
    private readonly _router = inject(Router);

    constructor() {
        this.isLoading$ = toSignal(this._loadingService.loadingStatus$, { initialValue: false });
    }

    public ngAfterViewInit(): void {
        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart && this._scrollFrame) {
                this._scrollFrame.nativeElement.scroll({
                    top: 0,
                    left: 0,
                });
            }
        });
    }
}
