import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ResponseData } from '@hrs-ui/util-definitions';
import { RequestDataService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { ModalService } from '@hrs-ui/modal/domain-modal';

@Component({
    selector: 'ht-message',
    templateUrl: './message.component.html',
    styleUrls: [
        './message.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        UiButtonComponent,
        TranslationModule,
    ],
})
export class MessageComponent implements OnInit {
    @Input() public responseData?: ResponseData;
    @Input() public modalId?: number;

    public message?: string;

    private readonly _modalService = inject(ModalService);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _requestDataService = inject(RequestDataService);

    public ngOnInit(): void {
        this.message = this.responseData?.message;
    }

    /**
     * close Modal
     */
    public closeModal(): void {
        if (this.modalId) {
            this._modalService.closeModal(this.modalId);
        }

        if (!this.responseData?.postoperationId) {
            return;
        }

        this._magicRouterService.operationId = this.responseData.postoperationId;
        this._requestDataService.updateData({ filters: this.responseData.filters ? [this.responseData.filters] : undefined });
    }
}
