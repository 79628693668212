import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { EventService } from '@hrs-ui/domain-event';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-image-input',
    templateUrl: './ht-image-input.component.html',
    styleUrls: ['./ht-image-input.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatInputModule,
        UiIconComponent,
        TranslationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtImageInputComponent implements AfterViewInit {

    @HostBinding('class') public class = 'form-element';
    @ViewChild('htInput', { static: false }) public htInput?: ElementRef;

    @Input()
    public placeholder?: string;

    @Input()
    public name?: string;

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public tabIndex = -1;

    @Input()
    public titleText?: string;

    @Output()
    public readonly valueChange: EventEmitter<File | null | undefined> = new EventEmitter();

    public fileSizeExceeded = false;

    public value?: File | null;

    private readonly _eventService = inject(EventService);
    private readonly _destroyRef = inject(DestroyRef);

    /**
     * angular on init
     */
    public ngAfterViewInit(): void {
        this._eventService.focusFirstEditableCellByTabIndex$
            .pipe(
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe(firstEditableCellsTabIndex => {
                if (this.tabIndex === firstEditableCellsTabIndex) {
                    this._focus();
                }
            });
    }

    /**
     * Emits changed value
     *
     * @param $event
     */
    public onValueChange($event?: File | null): void {
        this.valueChange.emit($event);
    }

    /**
     * Select file and check for maximum filesize
     */
    public selectFile(event: { target: EventTarget | HTMLInputElement | null }): void {
        if (!event.target || !('files' in event.target)) {
            return;
        }

        this.fileSizeExceeded = false;

        const MAX_FILE_SIZE_IN_BYTE = 5242880;
        const file = event.target.files?.item(0);

        // Don't allow files over 5MB
        if ((file?.size ?? 0) >= MAX_FILE_SIZE_IN_BYTE) {
            this.fileSizeExceeded = true;
            this.value = undefined;

            return;
        }

        this.value = (file);
        this.onValueChange(file);
    }

    /**
     * Focuses this element without scrolling to the element
     */
    private _focus(): void {
        this.htInput?.nativeElement.focus({
            preventScroll: true,
        });
    }

}
