import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiFunction } from './api-function';

/**
 * ApiService is an abstract service that can be imported in services and components.
 * The benefit is that the service and component can be tested without using or mocking the ApiService,
 * which causes long delays in the testing process.
 *
 * ApiService is provided in config.ts
 */
@Injectable({
    providedIn: 'root',
})
export abstract class ApiService {
    public abstract getFunction<T>(operationId: string): ApiFunction<T>;
    public abstract executeOperationId<T>(operationId: string): Observable<T>;
}
