export class SelectOptions {
    public id: string;
    public value: string | number;

    constructor(id: string, value: string | number) {
        this.id = id;
        this.value = value;
    }

    /**
     * toString override
     */
    public toString(): string {
        return this.value.toString();
    }

    /**
     * transform object to json string
     */
    public toJSON(): string {
        return this.id;
    }
}
