@if (!hidden) {
    <ht-editable-text
        [textValue]="value$()"
        [editModeDisabled]="column$().readOnly || column$().editModeDisabled || disabled"
        [values]="column$().values"
        [keys]="column$().keys"
        [isDefaultTextEntry]="isDefaultTextEntry$()"
        [tabIndex]="tabIndex"
        (valueChange)="onValueChange($event)"
    />
}
