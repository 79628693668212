
export class KeyValueMapperUtil {

    /**
     * Maps two Arrays into one Object
     */
    public static mapKeysValues<T>(
        keys: Array<string>,
        values: Array<T>,
    ): {[key: string]: T} {
        const result: {[key: string]: T} = {};

        keys.forEach((key, i) => result[key] = values[i]);

        return result;
    }
}
