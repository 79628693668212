import { LinkModel } from './link.model';

export class SidebarContentModel {

    /**
     * The name of a menu dropdown
     */
    public name: string;

    /**
     * An array of sub menu items related to the menu dropdown.
     * Each contains a link and text
     */
    public submenus: Array<LinkModel | SidebarContentModel>;

    /**
     * Controls whether this menu is open or not.
     */
    public open: boolean;

    /**
     * Controls if this menu item and its sub menus are clickable
     */
    public disabled?: boolean;

    constructor(
        name: string,
        submenus: Array<LinkModel | SidebarContentModel> = [],
        open: boolean = false,
        disabled: boolean = false,
    ) {
        this.name = name;
        this.submenus = submenus;
        this.open = open;
        this.disabled = disabled;
    }
}
