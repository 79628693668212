import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '@hrs-ui/notification/util-notification';
import { NotificationService } from '@hrs-ui/notification/domain-notification';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { NotificationComponent } from '../notification/notification.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { trigger, state, style, transition, animate } from '@angular/animations';

const flyInDuration = 300;

@Component({
    selector: 'ht-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        UiIconComponent,
        NotificationComponent,
    ],
    providers: [
        provideAnimations(),
    ],
    animations: [
        trigger('flyIn', [
            state('in', style({ transform: 'translateX(0)' })),
            transition('void => *', [
                style({ transform: 'translateY(-100%)' }),
                animate(flyInDuration),
            ]),
        ]),
    ],
})
export class NotificationListComponent {
    public readonly notifications$: Observable<Array<Notification>>;

    private readonly _notificationService = inject(NotificationService);

    constructor() {
        this.notifications$ = this._notificationService.notifications$;
    }

    public removeNotification(id: number): void {
        this._notificationService.removeNotification(id);
    }
}
