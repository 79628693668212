import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppRoutes } from '@hrs-ui/util-route';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';

@Injectable({
    providedIn: 'root',
})
export class MFAGuard {
    private readonly _tokenService = inject(TokenService);
    private readonly _authService = inject(AuthService);

    /**
     * checks if a route can activate
     *
     * @param route
     * @param state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (state.url === `/${ AppRoutes.MFA }` && !this._authService.userWaitingForMFA) {
            if (!this._tokenService.isValid()) {
                this._authService.clearUser();
            }

            return of(false);
        }

        return of(true);
    }
}
