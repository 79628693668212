<div class="item">
    <div class="title">
        {{ title | translate }}
    </div>

    <div class="value image-preview">
        @if (hasError !== true) {
            @if (imageUrl) {
                <img
                    [alt]="title | translate"
                    [src]="imageUrl"
                    (click)="showImage.emit(imageUrl)"
                />
            } @else {
                <ng-content select="no-image-template" />
            }
        } @else {
            <ui-icon
                class="error"
                [iconName]="'error-circle'"
                [iconSize]="100"
            />
        }
    </div>
</div>
