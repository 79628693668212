import { SelectOptions } from '../definitions/select-options';

/**
 * map values together, catch missing keys or values array
 */
export function keyValueToSelectOption(values: Array<string | number> | undefined, keys: Array<string> | undefined): Array<SelectOptions> | undefined {
    if(!values && !keys) {
        return undefined;
    }

    const length = Math.max((values || []).length, (keys || []).length);

    return Array.from({ length }, (_item, index) => {
        const key = keys && keys[index];
        const value = values && values[index];

        return new SelectOptions(key !== undefined
            ? key
            : value?.toString() || '',
            value !== undefined
                ? value
                : key || '',
        );
    });
}
