@if (quickAccessItem && isOpen && !isFavorite) {
    <div class="widget widget--card form-wrapper">
        <div class="save-quick-access__form">
            <label>
                {{ 'quickAccess.title' | translate }}
            </label>

            <input
                [placeholder]="'quickAccess.titlePlaceholder' | translate"
                [(ngModel)]="quickAccessItem.title"
            />

            <label>
                {{ 'quickAccess.description' | translate }}
            </label>

            <input
                [placeholder]="'quickAccess.descriptionPlaceholder' | translate"
                [(ngModel)]="quickAccessItem.content"
            />

            @if (saveQuickAccessItemButton) {
                <ht-button
                    class="save-quick-access__save-button"
                    [htButton]="saveQuickAccessItemButton"
                    (clickEvent)="saveChanges()"
                />
            }
        </div>
    </div>
}

@if (isOpen) {
    <div
        class="transparent-curtain"
        (click)="toggleIsOpen(false)"
    ></div>
}
