<div class="input-wrapper">
    <input
        #htInput
        type="file"
        [placeholder]="placeholder || ''"
        [name]="name ?? ''"
        [accept]="['image/jpeg', 'image/png']"
        [readonly]="disabled"
        [disabled]="disabled"
        [required]="required"
        [class.disabled]="disabled"
        [tabindex]="tabIndex"
        (ngModel)="(value)"
        (change)="selectFile($event)"
    />

    @if (disabled) {
        <div class="square">
            <img
                src="data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'&gt;&lt;/svg&gt;"
                alt="square"
            />

            <ui-icon
                class="icon disabled"
                [iconName]="'locked'"
                [iconSize]="20"
            />
        </div>
    }
</div>

@if (fileSizeExceeded) {
    <span class="text-small warning">
        {{ 'inputHint.fileSizeExceeded' | translate }}
    </span>
}
