import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationProvider } from '../providers/translation-provider';

/**
 * required for AOT compilation
 */
const HttpLoaderFactory =
    (http: HttpClient): TranslationProvider =>
        new TranslationProvider(http);

export const ngxRootTranslationModule: ModuleWithProviders<TranslateModule> =
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    });
