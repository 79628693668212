import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserLanguageService, TranslationService } from '@hrs-ui/translation/domain-translation';
import { SidebarContentModel } from '../definitions/sidebar-content.model';
import { LinkModel } from '../definitions/link.model';
import { ApiService } from '@hrs-ui/api/util-api';
import { MainmenuApi } from '@hrs-ui/apiclient';
import { MagicRouterService } from '@hrs-ui/api/domain-api';
import { OrgUnitService } from '@hrs-ui/app-status/domain-app-status';

type MenuPage = MainmenuApi.MenuPage;

/**
 * Service for the sidebar menu which stays on the screen at almost all points in the app.
 */
@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private readonly _menuOperationId$ = new BehaviorSubject('');
    private readonly _menuData$: Observable<Array<SidebarContentModel | LinkModel>>;
    private readonly _loadingStatus$ = new BehaviorSubject(false);

    private readonly _apiService = inject(ApiService);
    private readonly _translationService = inject(TranslationService);
    private readonly _userLanguageService = inject(UserLanguageService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _magicRouterService = inject(MagicRouterService);

    constructor() {
        this._menuData$ = combineLatest([
            this._menuOperationId$,
            this._orgUnitService.data$,
            this._userLanguageService.language$,
        ])
            .pipe(
                tap(() => this.loading = true),
                switchMap(([operationId]) => this.getMenu$(operationId)
                    .pipe(
                        map(menu => ({ menu, operationId })),
                    ),
                ),
                withLatestFrom(
                    this._translationService.streamTranslate$('menu.home'),
                    this._translationService.streamTranslate$('menu.backButton'),
                ),
                map(([{ menu, operationId }, homeText, backText]) => [
                    // Add Link at the beginning of the Menu
                    new LinkModel({
                        text: operationId ? (backText as string) : (homeText as string),
                        operationId: 'get_mainmenu',
                        onClickFunction: ($event: Event) => {
                            $event.preventDefault();

                            if (operationId) {
                                this.menuOperationId = '';
                            } else {
                                this._magicRouterService.operationId = '';
                            }
                        },
                    }),
                    ...menu,
                ]),
                tap(() => this.loading = false),
            );
    }

    public set loading(status: boolean) {
        this._loadingStatus$.next(status);
    }

    public get loading$(): BehaviorSubject<boolean> {
        return this._loadingStatus$;
    }

    public set menuOperationId(operationId: string) {
        this._menuOperationId$.next(operationId);
    }

    public get menuData$(): Observable<Array<SidebarContentModel | LinkModel>> {
        return this._menuData$;
    }

    /**
     * gets menu data based on operation ID
     *
     * @param operationId
     */
    public getMenu$(operationId?: string): Observable<Array<SidebarContentModel | LinkModel>> {
        return this._apiService.executeOperationId<MenuPage>(operationId || 'get_mainmenu')
            .pipe(
                map(menuData => this._parseBackendMenuData(menuData)),
            );
    }

    /**
     * resets the application to get to the start page
     */
    public resetToStartPage(): void {
        this.menuOperationId = '';
        this._magicRouterService.operationId = '';
    }

    /**
     * parse backend response to custom menu model
     *
     * @param menuData
     */
    private _parseBackendMenuData(menuData: MenuPage): Array<SidebarContentModel> {
        const sidebarContent: Array<SidebarContentModel> = [];

        if (menuData.menuBlocks === undefined) {
            return sidebarContent;
        }

        const menuBlocks = menuData.menuBlocks;

        Object.keys(menuBlocks)
            .forEach(blockKey => {
                sidebarContent.push(
                    new SidebarContentModel(
                        menuBlocks[blockKey as keyof typeof menuBlocks],
                        (menuData.menuItems || [])
                            .reduce((array: Array<LinkModel>, item) => {
                                if (item.blocktype === blockKey) {
                                    array.push(new LinkModel({
                                        text: item.titletext,
                                        operationId: item.operationId,
                                        hasSubmenu: item.hasSubmenu,
                                        ajaxannotate: item.ajaxannotate,
                                        httpaddress: item.httpaddress,
                                    }));
                                }

                                return array;
                            }, []),
                        true,
                    ),
                );
            });

        return sidebarContent;
    }

}
