import { Injectable, Injector, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, tap, BehaviorSubject } from 'rxjs';
import { APP_CONFIG, Environment } from '@hrs-ui/config/util-config';

@Injectable({
    providedIn: 'root',
})
export class ApiConfigService {
    public basePath$ = new BehaviorSubject<string | undefined>(undefined);

    private readonly _jsonConfigPath: string = `/assets/json/config/api-config.json?timestamp=${ Date.now().toString() }`;

    private readonly _http = inject(HttpClient);
    private readonly _injector = inject(Injector);
    private readonly _appConfig: Environment = inject(APP_CONFIG);

    public static initializerFn =
        (apiConfigService: ApiConfigService): (() => Observable<void>) =>
            () => apiConfigService._init();

    /**
     * Reads basePath from json file and emits it
     *
     * @private
     */
    private _init(): Observable<void> {
        return this._http.get(this._jsonConfigPath).pipe(
            tap({
                next: response => {
                    if (response) {
                        const typedResponse = response as object & { BASE_PATH: string };

                        this.basePath$.next(typedResponse.BASE_PATH || this._appConfig.BASE_PATH);
                    } else {
                        this.basePath$.next(this._appConfig.BASE_PATH);
                    }
                },
                error: () => {
                    this.basePath$.next(this._appConfig.BASE_PATH);
                },
            }),
            switchMap(() => of()),
        );
    }
}
