<div class="buttons-wrapper">
    <ui-button
        [label]="buttonText || button$().text"
        [icon]="activeIcon$()"
        [iconSize]="iconSize"
        [hasIconLeft]="true"
        [disabled]="disabled"
        [processing]="isProcessing$()"
        [isOutlined]="isOutlined"
        (click)="buttonClick()"
    />
</div>
