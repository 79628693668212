import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    inject,
    signal,
} from '@angular/core';
import { DataTypes, ResponseTable } from '@hrs-ui/util-definitions';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { InsertData } from '../../../definitions/insert-data';
import { CollectHiddenDataFromDataItemsUtil, fileToUrl$ } from '@hrs-ui/util-core';
import { finalize, Observable, shareReplay, tap } from 'rxjs';
import { HtImageComponent } from '@hrs-ui/ui/ui-presentation';
import { PCImageService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { CommonModule } from '@angular/common';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { FormsModule } from '@angular/forms';
import { HtImageInputComponent, HtInputMapperComponent } from '@hrs-ui/ui/ui-input';
import { ImagePreviewComponent } from './image-preview/image-preview.component';

@Component({
    selector: 'ht-insert-image',
    templateUrl: './insert-image.component.html',
    styleUrls: ['./insert-image.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        UiButtonComponent,
        TranslationModule,
        FormsModule,
        HtInputMapperComponent,
        HtImageInputComponent,
        ImagePreviewComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertImageComponent implements OnChanges {
    @Input() public tableKey?: string;
    @Input() public itemData?: ResponseTable;
    @Input() public modalId?: number;
    @Input() public openInModal = false;
    @ViewChild('wrapper', { static: false }) public wrapper?: ElementRef<HTMLElement>;

    public insertData?: InsertData;
    public imageId?: string;
    public itemId?: string;
    public newImage?: File | null;
    public currentImageUrl$?: Observable<string>;
    public newImageUrl$?: Observable<string>;

    public readonly isProcessing$ = signal(false);
    public readonly hasError$ = signal(false);

    private readonly _modalService = inject(ModalService);
    private readonly _pcImageService = inject(PCImageService);
    private readonly _magicRouterService = inject(MagicRouterService);

    /**
     * update itemData on change
     *
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const itemData = changes['itemData'];

        if (itemData && itemData.currentValue) {
            this.insertData = this.parseResponseTable(itemData.currentValue);
        }

        if (this.imageId && this.itemId) {
            this.currentImageUrl$ = this._pcImageService.getImageAsUrl(this.itemId, this.imageId)
                .pipe(
                    tap({
                        next: () => this.hasError$.set(false),
                        error: () => this.hasError$.set(true),
                    }),
                    shareReplay(),
                );
        } else {
            this.currentImageUrl$ = undefined;
        }
    }

    /**
     * save the form-data
     */
    public saveUpload(): void {
        if (!this.imageId || !this.itemId || !this.newImage) {
            return;
        }

        this.isProcessing$.set(true);

        this._pcImageService.updateImage(this.itemId, this.imageId, this.newImage)
            .pipe(
                finalize(() => this.isProcessing$.set(false)),
            )
            .subscribe(() => {
                if (this.modalId) {
                    this._modalService.closeModal(this.modalId);
                }

                this._magicRouterService.reloadPage();
            });

        return;
    }

    /**
     * delete the current image
     */
    public delete(): void {
        if (!this.imageId || !this.itemId) {
            return;
        }

        this.isProcessing$.set(true);

        this._pcImageService.deleteImage(this.itemId, this.imageId)
            .pipe(
                finalize(() => this.isProcessing$.set(false)),
            )
            .subscribe(() => {
                if (this.modalId) {
                    this._modalService.closeModal(this.modalId);
                }

                this._magicRouterService.reloadPage();
            });
    }

    /**
     * Turns ResponseTable into InsertData
     * Perfect for displaying data as a key / value pair
     * ex. 'DescriptionOfValueType : Value'
     *
     * @param data: ResponseTable
     */
    public parseResponseTable(data: ResponseTable): InsertData {
        const itemValues = data.rows && data.rows[0] && data.rows[0].rowData || {};

        this.currentImageUrl$ = undefined;

        const insertData = {
            tableTitle: data.tableTitle || data.title || '',
            items: data.columns.reduce((items, column) => {
                if (column.dataType === 'image_link') {
                    const imageId = itemValues[column.id];

                    if (typeof imageId === 'string') {
                        this.imageId = imageId;
                    }

                    return items;
                }

                if (column.id === 'pcid') {
                    const itemId = itemValues[column.id];

                    if (typeof itemId === 'string') {
                        this.itemId = itemId;
                    }

                    return items;
                }

                return [...items, {
                    name: column.id,
                    displayName: column.titletext,
                    type: column.dataType,
                    values: column.values,
                    keys: column.keys,
                    readOnly: column.readOnly,
                    hidden: column.settings?.visible === 'false',
                    maxLength: column.maxLength,
                    ajaxoperationId: column.ajaxoperationId,
                    ajaxproperty: column.ajaxproperty,
                    ajaxtrigger: column.ajaxtrigger,
                    required: false,
                    value: itemValues[column.id] !== undefined ? itemValues[column.id] : '',
                }];
            }, [] as InsertData['items']),
            insertsp: data.insertsp,
        };

        insertData.items.forEach(i => {
            if (i.type === DataTypes.AjaxArray) {
                i.filters = CollectHiddenDataFromDataItemsUtil.collectHiddenData(insertData.items);
            }
        });

        return insertData;
    }

    /**
     * generate the image url from the selected image file for the preview
     */
    public imageSelected(image?: File | null): void {
        if (image) {
            this.newImage = image;
            this.newImageUrl$ = fileToUrl$(this.newImage)
                .pipe(
                    shareReplay(),
                );
        } else {
            this.newImage = undefined;
            this.newImageUrl$ = undefined;
        }
    }

    /**
     * close modal
     */
    public closeModal(modalId: number): void {
        this._modalService.closeModal(modalId);
    }

    /**
     * open Insert in Modal
     */
    public openInsertInModal(): void {
        if (!this.itemData) {
            return;
        }

        this._modalService
            .openContent({
                pageData: {
                    tables: {
                        insertImage: this.itemData,
                    },
                },
            });
    }

    /**
     * show image in Modal
     */
    public showImage(imageUrl: string): void {
        if (!imageUrl) {
            return;
        }

        this._modalService
            .open(HtImageComponent, { imageUrl });
    }

    /**
     * Reset focus to first focusable element
     */
    public resetFocus(): void {
        if (!this.wrapper) { return; }

        const wrapperElement = this.wrapper.nativeElement;

        if (wrapperElement) {
            const firstFocusableElement = wrapperElement.querySelector<HTMLElement>('[tabindex="0"]');

            if (firstFocusableElement) {
                firstFocusableElement.focus({
                    preventScroll: true,
                });
            }
        }
    }
}
