import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OrgUnitService, TokenService } from '@hrs-ui/app-status/domain-app-status';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TranslationModule, UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { version } from '@hrs-ui/config/util-config';
import { MenuService } from '../../services/menu.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'ht-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslationModule,
        FormsModule,
        UiIconComponent,
        MatSelectModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
    public readonly orgUnits$: Observable<Array<{ id: string; value: string }>>;
    public readonly languages$: Observable<Array<{ CultureCode: string; DisplayName: string }>>;
    public readonly currentOrgUnit$: Observable<string>;
    public readonly currentLanguage$: Observable<string>;

    public isOpen = false;
    public userName: string;

    private readonly _authService = inject(AuthService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _userLanguageService = inject(UserLanguageService);
    private readonly _menuService = inject(MenuService);
    private readonly _tokenService = inject(TokenService);

    constructor() {
        this.orgUnits$ = this._orgUnitService.getAvailableOrgUnits();
        this.languages$ = this._userLanguageService.languages$;
        this.currentLanguage$ = this._userLanguageService.language$;
        this.userName = this._tokenService.getUserName();
        this.currentOrgUnit$ = this._orgUnitService.data$;
    }

    public get appVersion(): string {
        return version;
    }

    /**
     * changes the orgUnit
     *
     * @param $event
     */
    public changeOrgUnit($event: { value: string }): void {
        this.isOpen = false;
        this._orgUnitService.changeOrgUnit$($event.value).subscribe(() => {
            this._menuService.resetToStartPage();
        });
    }

    /**
     * changes the language
     *
     * @param $event
     */
    public changeLanguage($event: { value: string }): void {
        this.isOpen = false;
        this._userLanguageService.changeLanguage($event.value);
    }

    /**
     * logs out the current user
     */
    public logout(): void {
        this._authService.logoutUser();
        this._userLanguageService.removeUserLanguage();
    }

    /**
     * shows or hide the dropdowm menu
     */
    public toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Use the menuService to navigate to the main menu.
     *
     * @param $event
     */
    public navigateHome($event: MouseEvent): void {
        $event.preventDefault();

        this._menuService.resetToStartPage();
    }
}
