import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Notification } from '@hrs-ui/notification/util-notification';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('notification', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s ease-out', style({ opacity: '1' })),
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.2s ease-in', style({ opacity: '0' })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        UiIconComponent,
    ],
})
export class NotificationComponent {
    @HostBinding('@notification')
    public hostBinding = true;

    @Input()
    public notification?: Notification;
}
