import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationSeverity } from '@hrs-ui/notification/util-notification';
import { NotificationService } from '@hrs-ui/notification/domain-notification';
import { TranslationService } from '@hrs-ui/translation/domain-translation';
import { APP_CONFIG, Environment } from '@hrs-ui/config/util-config';

@Injectable({
    providedIn: 'root',
})
export class HotReloadService {
    protected readonly _appConfig: Environment = inject(APP_CONFIG);

    private readonly _remoteVersionPath = '/assets/json/config/remote-config.json';
    private readonly _hotReloadStorageKey = 'hrs_is_hot_reload_done';

    private readonly _currentRunningVersion = this._appConfig.VERSION;

    private readonly _http = inject(HttpClient);
    private readonly _notificationService = inject(NotificationService);
    private readonly _router = inject(Router);
    private readonly _translationService = inject(TranslationService);

    constructor() {
        this._checkReloadMessageState();
    }

    public checkHotReload(): void {
        this._retrieveAvailableVersion$()
            .pipe(
                map(availableVersion => {
                    if (availableVersion !== this._currentRunningVersion) {
                        this._triggerReload();
                    }
                }),
            )
            .subscribe();
    }

    private _retrieveAvailableVersion$(): Observable<string> {
        return this._http
            .get<{ currentVersion: string }>(`${ this._remoteVersionPath }?timestamp=${ Date.now() }`)
            .pipe(map(({ currentVersion }) => currentVersion));
    }

    private _triggerReload(): void {
        window.localStorage.setItem(this._hotReloadStorageKey, 'true');
        window.location.assign(`${ window.location.origin }/#${ this._router.url }`);
        window.location.reload();
    }

    private _checkReloadMessageState(): void {
        const isHotReload = window.localStorage.getItem(this._hotReloadStorageKey);

        if (!isHotReload) {
            return;
        }

        window.localStorage.removeItem(this._hotReloadStorageKey);

        this._translationService
            .translate$('app.pageReload')
            .subscribe((text: string | undefined) => {
                this._notificationService.push(text || '', NotificationSeverity.Info);
            });
    }
}
