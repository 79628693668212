import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, Signal, computed, input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { DataTypes, FormElement, DataItem } from '@hrs-ui/util-definitions';
import { SelectOptions } from '../../definitions/select-options';
import { keyValueToSelectOption } from '../../utils/key-value-to-select-option-mapper';
import { CommonModule } from '@angular/common';
import { HtTextComponent } from '../ht-text/ht-text.component';
import { HtTextarrayComponent } from '../ht-textarray/ht-textarray.component';
import { HtTextareaComponent } from '../ht-textarea/ht-textarea.component';
import { HtMultiSelectComponent } from '../ht-select/ht-multi-select/ht-multi-select.component';
import { HtInputComponent } from '../ht-input/ht-input.component';
import { HtDatepickerComponent } from '../ht-datepicker/ht-datepicker.component';
import { HtCheckboxComponent } from '../ht-checkbox/ht-checkbox.component';
import { HtAjaxAutocompleteComponent } from '../ht-ajax-autocomplete/ht-ajax-autocomplete.component';

interface ItemValueTypes {
    asDate: Date | null;
    asString: string;
    asStringArray: Array<string>;
    asBoolean: boolean;
}

const blankItemValues = {
    asDate: null,
    asString: '',
    asStringArray: [],
    asBoolean: false,
};

/**
 * Maps multiple inputs
 * INPUT TYPES: text inputs, arrays of text, password inputs,
 * number inputs, datepicker selects,
 * auto-complete inputs with dropdowns, & checkboxes
 */
@Component({
    selector: 'ht-input-mapper',
    templateUrl: './ht-input-mapper.component.html',
    styleUrls: [
        './ht-input-mapper.component.scss',
    ],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        HtDatepickerComponent,
        HtTextComponent,
        HtTextarrayComponent,
        HtInputComponent,
        HtCheckboxComponent,
        HtAjaxAutocompleteComponent,
        HtTextareaComponent,
        HtMultiSelectComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HtInputMapperComponent {
    @Input()
    @HostBinding('class.grey-inputs')
    public greyInput = true;

    @Input()
    public tabIndex = 0;

    @Output()
    public readonly valueChange: EventEmitter<string | boolean | Date | Array<string>> = new EventEmitter();

    public readonly isFirstInput$ = input<boolean>(false, { alias: 'isFirstInput' });

    public readonly item$ = input<DataItem | FormElement | undefined, DataItem | FormElement | undefined>(
        undefined,
        {
            alias: 'item',
            // Set the item value for incoming items that don't have one.
            transform: item => {
                if (item && (item.value === undefined || item.value === null)) {
                    if (item.type === DataTypes.Boolean) {
                        item.value = false;
                    } else {
                        item.value = item.defaultValue ?? '';
                    }
                }

                return item;
            },
        });

    public readonly itemValue$: Signal<ItemValueTypes>;
    public readonly itemDefaultValue$: Signal<string>;
    public readonly isDefaultTextEntry$: Signal<boolean>;
    public readonly selectValues$: Signal<Array<SelectOptions> | undefined>;

    public readonly DataTypes = DataTypes;

    constructor() {
        this.itemValue$ = computed(() => {
            const item = this.item$();

            return item
                ? this._prepareItemValues(item)
                : blankItemValues;
        });

        this.itemDefaultValue$ = computed(() => {
            const item = this.item$();

            return item
                ? this._isString(item.defaultValue) ? item.defaultValue : ''
                : '';
        });

        this.isDefaultTextEntry$ = computed(() => {
            const item = this.item$();
            const isFirstInput = this.isFirstInput$();

            if (this._isItemDefaultTextEntry(item)) {
                return true;
            } else {
                return isFirstInput;
            }
        });

        this.selectValues$ = computed(() => {
            const item = this.item$();

            return keyValueToSelectOption(item?.values, item?.keys);
        });
    }

    private _isString(x: unknown): x is string {
        return typeof x === 'string';
    }

    private _isNumber(x: unknown): x is number {
        return typeof x === 'number';
    }

    private _isBoolean(x: unknown): x is boolean {
        return typeof x === 'boolean';
    }

    private _isItemDefaultTextEntry(item?: DataItem | FormElement): boolean {
        return !!(item?.name && item.settings?.cells?.[item.name]?.default_textentry === 'true');
    }

    private _prepareItemValues(item: DataItem | FormElement): ItemValueTypes {
        const value = item.value;

        const types: ItemValueTypes = { ...blankItemValues };

        if ((item.type === DataTypes.DateTime || item.type === DataTypes.Date) && value) {
            types.asDate = new Date(item.value as string | number);
        } else if (this._isString(value)) {
            types.asString = value;
            types.asStringArray = value ? [value] : [];
        } else if (Array.isArray(value)) {
            types.asStringArray = value.filter(line => this._isString(line));
        } else if (this._isNumber(value)) {
            const valueAsString = `${ value }`;

            types.asString = valueAsString;
            types.asStringArray = valueAsString ? [valueAsString] : [];
        } else if (this._isBoolean(value)) {
            types.asBoolean = value;
        }

        return types;
    }

}
