import { ChangeDetectionStrategy, Component, Input, Signal, computed, input } from '@angular/core';
import { HtButton, PageData } from '@hrs-ui/util-definitions';
import { ButtonUtil } from '@hrs-ui/ht-button/util-ht-button';
import {
    BlobMapperComponent,
    ButtonMapperComponent,
    LegendMapperComponent,
    MultitableMapperComponent,
    TableMapperComponent,
} from '@hrs-ui/ui-content-mapping';

@Component({
    selector: 'ht-content-widget',
    templateUrl: './content-widget.component.html',
    styleUrls: [
        './content-widget.component.scss',
    ],
    imports: [
        TableMapperComponent,
        MultitableMapperComponent,
        LegendMapperComponent,
        ButtonMapperComponent,
        BlobMapperComponent,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWidgetComponent {

    @Input()
    public wrapped = false;

    @Input()
    public modalId?: number;

    /**
     * All data for page
     */
    public readonly pageData$ = input<PageData | undefined>(undefined, { alias: 'pageData' });

    public readonly filteredButtons$: Signal<Array<HtButton> | undefined>;

    constructor() {
        // The buttons are derived from the page data.
        this.filteredButtons$ = computed(() => {
            const data = this.pageData$();

            return data?.buttons && ButtonUtil.filterNeededButtonTypes(data.buttons);
        });
    }
}
