import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NumericCellComponent } from './numeric-cell.component';
import { CommonModule } from '@angular/common';
import { EditableNumberComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-numeric-nonneg-cell',
    templateUrl: './numeric-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './numeric-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        EditableNumberComponent,
    ],
})
export class NumericNonNegCellComponent extends NumericCellComponent {
    public override readonly nonNeg = true;
}
