<div class="widget__wrapper">
    <div class="widget--card">
        @if (editData$().tableTitle; as title) {
            <h2 class="edit-title">
                {{ title }}
            </h2>
        }

        <form #editForm="ngForm">
            <div class="form--elements">
                @for (item of editData$().items; track $index) {
                    @if (!item.hidden) {
                        <div class="item">
                            <div class="title">{{ item.displayName }}</div>
                            <div class="value">
                                <ht-input-mapper [item]="item" />
                            </div>
                        </div>
                    }
                }
            </div>

            @if (modalId || deleteButtonData || editButtonData) {
                <div class="button-row">
                    @if (deleteButtonData) {
                        <ht-button
                            icon="delete"
                            [buttonText]="'content.edit.delete' | translate"
                            [isOutlined]="true"
                            (clickEvent)="saveEdit(deleteButtonData, $event)"
                        />
                    }

                    <div class="spacer"></div>

                    @if (modalId) {
                        <ht-button
                            icon="close"
                            [buttonText]="'content.edit.close' | translate"
                            [isOutlined]="true"
                            (clickEvent)="closeModal(modalId)"
                        />
                    }

                    @if (editButtonData) {
                        <ht-button
                            icon="check"
                            [buttonText]="'content.edit.save' | translate"
                            [disabled]="!editForm.form.valid"
                            (clickEvent)="saveEdit(editButtonData, $event)"
                        />
                    }
                </div>
            }
        </form>
    </div>
</div>
