import { TranslationModule, TranslationService, UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TabTitleService } from '@hrs-ui/app-status/domain-app-status';
import { NotificationSeverity } from '@hrs-ui/notification/util-notification';
import { NotificationService } from '@hrs-ui/notification/domain-notification';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { FormsModule } from '@angular/forms';
import { UiInputComponent } from '@hrs-ui/ui/ui-input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TitleScreenLogoComponent } from '../title-screen-logo/title-screen-logo.component';

@Component({
    selector: 'ht-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        UiButtonComponent,
        FormsModule,
        UiInputComponent,
        UiIconComponent,
        TitleScreenLogoComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    public username = '';
    public password = '';
    public showingPassword = false;
    public invalidTokenErrorMessage?: string;
    public readonly isLoading$ = signal(false);

    private readonly _authService = inject(AuthService);
    private readonly _tabTitleService = inject(TabTitleService);
    private readonly _notificationService = inject(NotificationService);
    private readonly _translationService = inject(TranslationService);
    private readonly _quickAccessService = inject(QuickAccessService);
    // Inject UserLanguageService only to initialize the translation language.
    private readonly _userLanguageService = inject(UserLanguageService);

    constructor() {
        this._translationService.translate$('errorCodes.invalidToken')
            .subscribe(errorMessage => {
                this.invalidTokenErrorMessage = errorMessage;
            });
    }

    public ngOnInit(): void {
        this._authService.updateAccessToken();

        this._tabTitleService.setTitleByTranslationKey('login.tabTitle');
    }

    /**
     * login based on userData
     */
    public login(): void {
        this.isLoading$.set(true);

        this._authService.loginUser$({
            user: this.username,
            password: this.password,
        }).subscribe({
            next: () => {
                this.isLoading$.set(false);
                this._quickAccessService.onUserChanged();
            },
            error: error => {
                if (this.invalidTokenErrorMessage && error.message === 'errorCodes.invalidToken') {
                    this._notificationService.push(this.invalidTokenErrorMessage, NotificationSeverity.Error);
                }

                this.isLoading$.set(false);
            },
        });
    }

    /**
     * Toggles the state of the password being shown as text or hidden as ****
     */
    public toggleHideShowPassword(): void {
        this.showingPassword = !this.showingPassword;
    }
}
