import { ChangeDetectionStrategy, Component, Input, Signal, computed, inject, input } from '@angular/core';
import { DataTypes, HtButtType, HtButton, ResponseTable, ButtonName } from '@hrs-ui/util-definitions';
import { KeyValueMapperUtil } from '@hrs-ui/util-data-mapping';

import { DetailData } from '../../../definitions/detail-data';
import { ButtonCallbacks } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { FormsModule } from '@angular/forms';
import { HtButtonComponent } from '@hrs-ui/ht-button/ui-ht-button';
import { ConvertLineBreaksPipe } from '@hrs-ui/util-core';
import { HtTextarrayComponent } from '@hrs-ui/ui/ui-input';

const defaultDetailData: DetailData = {
    detailTitle: '',
    items: [],
};

@Component({
    selector: 'ht-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslationModule,
        UiIconComponent,
        FormsModule,
        HtTextarrayComponent,
        HtButtonComponent,
        ConvertLineBreaksPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailComponent {

    @Input()
    public tableKey?: string;

    public readonly itemData$ = input<ResponseTable | undefined>(undefined, { alias: 'itemData' });

    public readonly detailData$: Signal<DetailData>;

    private readonly _buttonService = inject(ButtonService);

    constructor() {
        this.detailData$ = computed(() => {
            const itemData = this.itemData$();

            return this._generateDetailData(itemData);
        });
    }

    /**
     * handle click on Button
     *
     * @param button
     * @param buttonCallbacks
     */
    public handleButtonClick(button: HtButton, buttonCallbacks: ButtonCallbacks): void {
        const parameter = button.values && button.keys
            ? KeyValueMapperUtil
                .mapKeysValues(button.keys, button.values)
            : {};

        this._buttonService.buttonClick({
            id: button.id,
            buttType: button.buttType,
            operationId: button.operationId,
            postoperationId: button.postoperationId,
            parameter,
            data: button.data,
            data_validator: button.data_validator,
            buttonCallbacks,
        });
    }

    /**
     * Turns ResponseTable into DetailData
     * Perfect for displaying data as a key / value pair
     * ex. 'DescriptionOfValueType : Value'
     *
     * @param data
     * @private
     */
    private _generateDetailData(data?: ResponseTable): DetailData {
        if (!data || !data.rows.length) {
            return defaultDetailData;
        }

        const itemValues = data.rows && data.rows[0] && data.rows[0].rowData;

        return {
            detailTitle: data.tableTitle || '',
            items: data.columns.map(column => {
                const itemVal = itemValues?.[column.id] as string;
                let value: Array<string> | undefined;

                if (itemVal) {
                    value = Array.isArray(itemVal) ? itemVal : [itemVal];
                } else {
                    value = undefined;
                }

                let buttonData: HtButton | undefined;

                if (column.dataType === DataTypes.Button && column.values) {
                    const buttonValues: Array<string> = Array.isArray(itemVal) ? itemVal : [];
                    const buttonObj = KeyValueMapperUtil.mapKeysValues(column.values, buttonValues);

                    buttonData = {
                        id: column.id as ButtonName,
                        text: buttonObj['buttontext'] || '',
                        buttType: column.buttType || HtButtType.Redirect,
                        operationId: buttonObj['operationId'] || '',
                        keys: column.values,
                        values: buttonValues,
                    };
                }

                return {
                    name: column.id,
                    displayName: column.titletext,
                    type: column.dataType,
                    values: column.values,
                    keys: column.keys,
                    readOnly: column.readOnly,
                    maxLength: column.maxLength,
                    button: buttonData,
                    value,
                };
            }),
        };
    }

}
