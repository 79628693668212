import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { HtRowData, HtTableColumn } from '@hrs-ui/util-definitions';

@Component({
    selector: 'ht-default-cell',
    styleUrls: ['../base-cell.component.scss', './default-cell.component.scss'],
    template: '{{ value }}',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class HtDefaultCellComponent implements HtCellComponent {
    @Input()
    public column?: HtTableColumn;

    @Input()
    public rowData?: HtRowData;

    public get value(): string {
        const value = this.rowData?.[this.column?.name || ''];

        return value ? value.toString() : '';
    }
}
