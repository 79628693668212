import {
    HttpErrorResponse,
    HttpEvent,
    HttpResponse, HttpStatusCode,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpStatusCodeHandlerService } from '@hrs-ui/api/domain-api';

@Injectable({
    providedIn: 'root',
})
export class StatusCodeHandler {
    private readonly _httpStatusCodeHandlerService = inject(HttpStatusCodeHandlerService);

    /**
     * Triggers any dialogs or messages based on the response content.
     */
    public handleContentByStatusCode<T>(): OperatorFunction<HttpEvent<T>, HttpEvent<T>> {
        return (response$: Observable<HttpEvent<T>>) =>
            response$
                .pipe(
                    map(response => {
                        if (response instanceof HttpResponse) {
                            return this._handleHttpResponse(response);
                        }

                        return response;
                    }),
                    catchError(
                        (errorResponse: HttpErrorResponse) => {
                            throw this._handleErrorResponse(errorResponse);
                        },
                    ),
                );
    }

    /**
     * Handle http response.
     *
     * @param response - The http response to handle.
     * @private
     */
    private _handleHttpResponse<T>(response: HttpResponse<T>): HttpResponse<T> {
        if (response.status === HttpStatusCode.PartialContent && response.body) {
            this._httpStatusCodeHandlerService.handleMessageResponseBody(response.body);

            return response.clone<T>({ body: null });
        }

        if (response.status === HttpStatusCode.Created) {
            if (!response.body) {
                return response;
            }

            this._httpStatusCodeHandlerService.handleRedirectResponseBody(response.body);

            return response.clone<T>({ body: null });
        }

        return response;
    }

    /**
     * Handle http error responses.
     *
     * @param errorResponse - The http error response to handle.
     * @private
     */
    private _handleErrorResponse(errorResponse: HttpErrorResponse): HttpErrorResponse {
        const status = errorResponse.status;
        const message = errorResponse.message || this._httpStatusCodeHandlerService
            .getValues(errorResponse.error).join('\n');

        this._httpStatusCodeHandlerService.handleErrorResponseMessage(status, message);

        throw errorResponse;
    }
}
