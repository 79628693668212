import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertLineBreaks',
    standalone: true,
})
export class ConvertLineBreaksPipe implements PipeTransform {

    /**
     * transform javascript line breaks to textarea or html line breaks for display.
     *
     * @param value
     * @param format
     */
    public transform(value: string, format: 'html' | 'textarea'): string {
        const newLine = 13;
        const lineFeed = 10;

        const lineBreakString = String.fromCharCode(newLine, lineFeed);

        if (format === 'html') {
            return value.replace(/\\n/g, '<br/>');
        }

        if (format === 'textarea') {
            return value.replace(/\\n/g, lineBreakString);
        }

        return value;
    }
}
