import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { FormsModule } from '@angular/forms';
import { EditableBooleanComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-boolean-cell',
    templateUrl: './boolean-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './boolean-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        EditableBooleanComponent,
    ],
})
export class BooleanCellComponent extends BaseCellComponent<boolean> implements HtCellComponent {
    public defaultValue = false;
}
