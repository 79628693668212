<mat-checkbox
    class="ht-mat-checkbox"
    [name]="name"
    [disabled]="disabled"
    [required]="required"
    [tabIndex]="tabIndex"
    [disableRipple]="true"
    [(ngModel)]="value$"
    (change)="toggleChecked({ checked: $event.checked })"
    (keydown.enter)="toggleChecked()"
>
    {{ titleText }}
</mat-checkbox>
