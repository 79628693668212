@if (editModeDisabled) {
    <span>
        {{ textValue }}
    </span>
} @else {
    <ui-input
        type="password"
        [id]="textValue"
        [name]="textValue"
        [placeholder]="placeholderText"
        [hideValidityIcons]="true"
        [(ngModel)]="textValue"
        (ngModelChange)="valueChange.emit(textValue)"
    />
}
