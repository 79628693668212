import { ButtonName, IconSvg } from '@hrs-ui/util-definitions';

/**
 * Maps a matching icon to all button types
 */
export const ButtonIdIconMap: Record<ButtonName, IconSvg> = {
    [ButtonName.AddButton]: IconSvg.Add,
    [ButtonName.AddDevice]: IconSvg.Add,
    [ButtonName.AddDevicesButton]: IconSvg.Add,
    [ButtonName.AddNew]: IconSvg.Add,
    [ButtonName.AddRemoveFamily]: IconSvg.Add,
    [ButtonName.ApplyButton]: IconSvg.Check,
    [ButtonName.AssignItemCode]: IconSvg.ChevronRight,
    [ButtonName.AssignItems]: IconSvg.ChevronRight,
    [ButtonName.Back]: IconSvg.Back,
    [ButtonName.Confirm]: IconSvg.Check,
    [ButtonName.ConfirmButton]: IconSvg.Check,
    [ButtonName.ContractTypeButton]: IconSvg.Document,
    [ButtonName.Contrast]: IconSvg.Edit,
    [ButtonName.CopyButton]: IconSvg.Copy,
    [ButtonName.CreateModify]: IconSvg.Edit,
    [ButtonName.CreateSend]: IconSvg.Send,
    [ButtonName.Delete]: IconSvg.Delete,
    [ButtonName.DeleteButton]: IconSvg.Delete,
    [ButtonName.DevDel]: IconSvg.Edit,
    [ButtonName.DeviceDetailButton]: IconSvg.Unknown,
    [ButtonName.DevicesButton]: IconSvg.Unknown,
    [ButtonName.DisplayReport]: IconSvg.Display,
    [ButtonName.DownloadFile]: IconSvg.Download,
    [ButtonName.DrillUp]: IconSvg.ExternalLink,
    [ButtonName.EditButton]: IconSvg.Edit,
    [ButtonName.EditDevice]: IconSvg.Edit,
    [ButtonName.EditProcedureDetailButton]: IconSvg.Edit,
    [ButtonName.EmailReport]: IconSvg.Mail,
    [ButtonName.FamilyButton]: IconSvg.Send,
    [ButtonName.History]: IconSvg.Updated,
    [ButtonName.InsertButton]: IconSvg.Send,
    [ButtonName.JumpButton]: IconSvg.Send,
    [ButtonName.Kit]: IconSvg.MedicalKit,
    [ButtonName.ListSn]: IconSvg.ProductList,
    [ButtonName.Location]: IconSvg.Edit,
    [ButtonName.Meds]: IconSvg.Edit,
    [ButtonName.Menu]: IconSvg.ListView,
    [ButtonName.Mkergeproc]: IconSvg.Edit,
    [ButtonName.NextPage]: IconSvg.ChevronRight,
    [ButtonName.NonChargeableHealthFundGroup]: IconSvg.ClinicGroup,
    [ButtonName.OuSupplyId]: IconSvg.Send,
    [ButtonName.Overview]: IconSvg.Show,
    [ButtonName.PatRefs]: IconSvg.Edit,
    [ButtonName.PdfButton]: IconSvg.Download,
    [ButtonName.PoBack]: IconSvg.ChevronLeft,
    [ButtonName.PrevPage]: IconSvg.ChevronLeft,
    [ButtonName.Print]: IconSvg.Print,
    [ButtonName.ProcedureDetailButton]: IconSvg.Show,
    [ButtonName.ProcedureIdButton]: IconSvg.Show,
    [ButtonName.Procs]: IconSvg.Edit,
    [ButtonName.ProsthesesPriceList]: IconSvg.Show,
    [ButtonName.PurchaseRequisitionButton]: IconSvg.Send,
    [ButtonName.PurchOrderDetailButton]: IconSvg.Show,
    [ButtonName.ReleaseButton]: IconSvg.ChevronRight,
    [ButtonName.Reset]: IconSvg.Reset,
    [ButtonName.ScanItemButton]: IconSvg.Scan,
    [ButtonName.ScannerButton]: IconSvg.Scan,
    [ButtonName.SearchByDesc]: IconSvg.Search,
    [ButtonName.SearchBySpc]: IconSvg.Search,
    [ButtonName.Select]: IconSvg.ExportSelect,
    [ButtonName.SelectAll]: IconSvg.ExportSelect,
    [ButtonName.SelectNone]: IconSvg.ExportDeselect,
    [ButtonName.Staff]: IconSvg.Edit,
    [ButtonName.StaffTypeRoles]: IconSvg.Administration,
    [ButtonName.StorageLocation]: IconSvg.World,
    [ButtonName.Submit]: IconSvg.Send,
    [ButtonName.SubstituteButton]: IconSvg.Substitute,
    [ButtonName.Timeouts]: IconSvg.Edit,
    [ButtonName.Timers]: IconSvg.Edit,
    [ButtonName.Unit]: IconSvg.Edit,
    [ButtonName.View]: IconSvg.Send,
};
