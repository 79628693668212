@if (buttons) {
    <div class="content__wrapper">
        @for (button of buttons; track button.id) {
            <span class="ht-button-wrapper">
                <ht-button
                    [htButton]="button"
                    (clickEvent)="handleButtonClick(button, $event)"
                />
            </span>
        }
    </div>
}
