import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppRoutes } from '@hrs-ui/util-route';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    private readonly _tokenService = inject(TokenService);
    private readonly _authService = inject(AuthService);
    private readonly _router = inject(Router);

    /**
     * Blocks login pages if already logged in, redirects to dashboard
     * Blocks other pages if not logged in, redirects to login or mfa respectively
     *
     * @param route
     * @param state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const isLoggedIn = this._tokenService.isValid();
        const isTargetingLogin = [
            `/${ AppRoutes.Login }`,
            `/${ AppRoutes.MFA }`,
        ].includes(state.url);

        if (
            isLoggedIn === isTargetingLogin
        ) {
            if (isTargetingLogin) {
                if (!this._router.lastSuccessfulNavigation) {
                    this._router.navigate([`/${ AppRoutes.Dashboard }`]);
                }
            } else {
                this._authService.redirectUrl = state.url;

                if (this._authService.userWaitingForMFA) {
                    this._authService.redirectToMFA();
                } else {
                    this._authService.clearUser();
                }
            }

            return of(false);
        }

        return of(true);
    }
}
