import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ResizableModule, ResizeEvent } from 'angular-resizable-element';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidebarService } from '../../services/sidebar.service';
import { MenuService } from '../../services/menu.service';
import { SidebarContentTypeGuard } from '../../utils/sidebar-content.type-guard';
import { LinkTypeGuard } from '../../utils/link.type-guard';
import { SidebarContentModel } from '../../definitions/sidebar-content.model';
import { LinkModel } from '../../definitions/link.model';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { LinkItemComponent } from './link-item/link-item.component';
import { SidebarLoadingComponent } from './loading/sidebar-loading.component';

@Component({
    selector: 'ht-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        UiIconComponent,
        MenuItemComponent,
        LinkItemComponent,
        SidebarLoadingComponent,
        ResizableModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
    public readonly menuData$: Observable<Array<{
        asSidebarItem: SidebarContentModel | undefined;
        asLinkItem: LinkModel | undefined;
    }> | undefined>;

    public sidebarUiState$: Observable<{ width: number; isCollapsed: boolean }>;

    private readonly _sidebarDefaultWidth = 250;
    private readonly _sidebarCollapsedWidth = 10;
    private readonly _minWidthAllowed = 60;

    private _sidebarWidth: number;

    private readonly _menuService = inject(MenuService);
    private readonly _sidebarService = inject(SidebarService);
    private readonly _sidebarContentTypeGuard = inject(SidebarContentTypeGuard);
    private readonly _linkTypeGuard = inject(LinkTypeGuard);

    constructor() {
        this._sidebarWidth = this._sidebarDefaultWidth;

        this.menuData$ = combineLatest([
            this._menuService.menuData$,
            this._menuService.loading$,
        ])
            .pipe(
                map(([menuData, loading]) =>
                    loading
                        ? undefined
                        : menuData.map(menuItem => ({
                            asSidebarItem: this._sidebarContentTypeGuard.isSidebarContent(menuItem) ? menuItem : undefined,
                            asLinkItem: this._linkTypeGuard.isLink(menuItem) ? menuItem : undefined,
                        })),
                ),
            );

        this.sidebarUiState$ = this._sidebarService.getSidebarState$()
            .pipe(
                map(({ isOpen, width }) => ({
                    isCollapsed: !isOpen,
                    width: isOpen ? this._calculateWidth(width) : this._sidebarCollapsedWidth,
                })),
            );
    }

    /**
     * This triggers some size and position changes after the resize ends. For the sidebar, only the width changes
     *
     * @param event some size and position data
     */
    public onResizeEnd(event: ResizeEvent): void {
        if (event.rectangle.width) {
            this._sidebarWidth = event.rectangle.width;

            if (this._sidebarWidth < this._minWidthAllowed) {
                this._sidebarWidth = this._minWidthAllowed;
            }

            this._sidebarService.setSidebarState({ width: this._sidebarWidth });
        }
    }

    public openSidebar(): void {
        this._sidebarService.setSidebarState({ isOpen: true });
    }

    public closeSidebar(): void {
        this._sidebarService.setSidebarState({ isOpen: false });
    }

    protected _calculateWidth(width: number): number {
        return width < this._minWidthAllowed ? this._sidebarDefaultWidth : width ?? this._sidebarDefaultWidth;
    }
}
