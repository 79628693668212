import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Type,
    ViewChild,
    ViewContainerRef,
    inject,
} from '@angular/core';
import { Modal } from '@hrs-ui/modal/util-modal';


@Component({
    selector: 'ht-modal-content',
    templateUrl: './modal-content.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentComponent {
    @ViewChild('content', { read: ViewContainerRef, static: true })
    public content!: ViewContainerRef;

    @ViewChild('content', { read: ViewContainerRef, static: false })
    private readonly _viewContainerRef?: ViewContainerRef;

    private readonly _cdr = inject(ChangeDetectorRef);

    @Input()
    public set modal(modal: Modal) {
        requestAnimationFrame(() => {
            this._show(modal.component, modal.id, modal.inject);
        });
    }

    /**
     * show component instance
     *
     * @param component component to be created
     * @param modalId
     * @param properties data to inject into the component
     */
    private _show<T extends Component>(component: Type<T>, modalId: number, properties?: Partial<T>): void {
        if (!this._viewContainerRef) {
            return;
        }

        this._viewContainerRef.clear();

        const componentRef = this._viewContainerRef.createComponent(component);

        if (properties) {
            Object.assign(componentRef.instance, { modalId });

            Object.entries(properties).forEach(([key, value]) => {
                // There is a risk of error when writing over a readonly or nonexistent property.
                // The whole process isn't particularly typesafe.
                // To avoid errors, take care not to supply a modal with readonly properties, and use aliases for signals.
                if (Object.prototype.hasOwnProperty.call(componentRef.instance, key)) {
                    componentRef.instance[key as keyof T] = value;
                } else {
                    try {
                        // Any properties that don't exist on the component could be aliases. These have to be assigned via setInput.
                        componentRef.setInput(key, value);
                    } catch (error) {
                        console.warn(`Property ${ key } is not an input or property of the created modal component.`);
                    }
                }
            });
        }

        this._cdr.detectChanges();
    }
}
