import { Injectable, inject } from '@angular/core';
import { TokenService } from './token.service';

interface Broadcast {
    message: BroadcastMessage;
    sessionId: string;
}

type BroadcastMessage = 'org_unit_change' | 'login_change';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {
    private readonly _broadcastChannel = new BroadcastChannel('reloadNotifier');

    private readonly _tokenService = inject(TokenService);

    constructor() {
        this._broadcastChannel.onmessage =
            (event: MessageEvent<Broadcast>) => {
                const sessionId = this._tokenService.getSessionId();

                if (event.data.sessionId === sessionId) {
                    window.location.reload();
                }
            };
    }

    /**
     * Will take a new org unit and broadcast its change to all other
     * duplicated browser tabs that share the same session.
     * When another tab is notified of this org unit change, it will reload the page,
     * forcing the org unit and subsequential data to refresh.
     * - new tabs that are also logged in will not be changed).
     * - broadcast does not work on IE or mobile Safari
     *
     * @param newOrgUnit New Org Unit change that is broadcasted
     */
    public broadcastOrgUnitChangeToOtherTabsWithSameSession(newOrgUnit: string): void {
        const currentOrgUnit = this._tokenService.getOrgUnit();

        if (currentOrgUnit !== '' && currentOrgUnit !== newOrgUnit) {
            this._broadcastToOtherTabsWithSameSession('org_unit_change');
        }
    }

    public broadcastLoginChangeToOtherTabsWithSameSession(): void {
        this._broadcastToOtherTabsWithSameSession('login_change');
    }

    private _broadcastToOtherTabsWithSameSession(message: BroadcastMessage): void {
        const sessionId = this._tokenService.getSessionId();
        const broadcast: Broadcast = { message, sessionId };

        this._broadcastChannel.postMessage(broadcast);
    }
}
