import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { StatusCodeHandler } from '../utils/status-code-handler';
import { parseResponseType } from '../utils/parse-response-type';

@Injectable()
export class ContentInterceptor implements HttpInterceptor {

    private readonly _statusCodeHandler = inject(StatusCodeHandler);

    /**
     * Prepares the content for processing and displays any triggered dialogs or messages.
     *
     * @param req The outgoing request object to handle.
     * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
     */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // We request a text body no matter the request. The response is later converted into the required format.
        const cloned = req.clone({
            responseType: 'text',
        });

        return next.handle(cloned)
            .pipe(
                // Converts the response to a suitable format
                parseResponseType(),
                // Handle dialogs or messages
                this._statusCodeHandler.handleContentByStatusCode(),
            );
    }
}
