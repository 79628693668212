@if (!hidden) {
    <ht-editable-number
        [textValue]="value$()"
        [readOnly]="column$().readOnly || disabled"
        [keys]="column$().keys"
        [values]="column$().values"
        [tabIndex]="tabIndex"
        [nonNeg]="nonNeg"
        (valueChange)="onValueChange($event)"
    />
}
