<div class="login-wrapper">
    <div class="login-box">
        <ht-title-screen-logo />

        <div class="login-box__text">
            <h4 class="subline">{{ 'login.subline' | translate }}</h4>
            <h3 class="headline">{{ 'login.headline' | translate }}</h3>
        </div>

        <form (ngSubmit)="login()">
            <div class="login-row username">
                <ui-input
                    type="text"
                    id="usernameInput"
                    name="username"
                    autocomplete="username"
                    [placeholder]="'login.userNamePlaceholder' | translate"
                    [hideValidityIcons]="true"
                    [tabIndex]="0"
                    [(ngModel)]="username"
                />
                <ui-icon
                    iconName='user'
                    [iconSize]="20"
                />
            </div>
            <div class="login-row password">
                <ui-input
                    id="passwordInput"
                    name="password"
                    autocomplete="current-password"
                    [type]="showingPassword ? 'text' : 'password'"
                    [placeholder]="'login.passwordPlaceholder' | translate"
                    [hideValidityIcons]="true"
                    [tabIndex]="1"
                    [(ngModel)]="password"
                />
                <ui-icon
                    iconName='locked'
                    [iconSize]="20"
                />
                <ui-icon
                    class="show-hide-password"
                    [iconName]="showingPassword ? 'show' : 'hide'"
                    [iconSize]="20"
                    (click)="toggleHideShowPassword()"
                />
            </div>
            <div class="login-row">
                <ui-button
                    class="login-button"
                    type="submit"
                    icon="arrow-right"
                    [label]="'login.loginLabel' | translate"
                    [iconSize]="16"
                    [hasIconLeft]="true"
                    [tabIndex]="2"
                    [processing]="isLoading$()"
                />
            </div>
        </form>
    </div>
</div>
