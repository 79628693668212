import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { FormsModule } from '@angular/forms';
import { HtTextarrayComponent } from '@hrs-ui/ui/ui-input';
import { HtRowData } from '@hrs-ui/util-definitions';

@Component({
    selector: 'ht-list-cell',
    templateUrl: './list-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './list-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        HtTextarrayComponent,
    ],
})
export class ListCellComponent extends BaseCellComponent<Array<string>> implements HtCellComponent {

    public defaultValue: Array<string> = [];

    protected override _getValue(rowData: HtRowData, column: string): Array<string> {
        return [...(rowData[column] || []) as Array<string>];
    }
}
