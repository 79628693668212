<div class="dashboard-wrapper">
    <h1 class="page-title">
        {{ 'content.dashboard.welcomeUser' | translate: userNameParam }}
    </h1>

    <hr class="separator" />

    <h2 class="page-subtitle">
        <ui-icon
            class="page-subtitle__icon"
            [iconName]="'favorite'"
            [iconSize]="20"
        />

        {{ 'content.dashboard.quickAccess' | translate }}
    </h2>

    @if ((cards$ | async)?.length) {
        <div class="card-container">
            @for (card of cards$ | async; track $index) {
                <div class="card">
                    <div
                        #cardElem
                        class="widget widget--card"
                        (dragstart)="dragStart($event, card, cardElem)"
                        (dragenter)="dragEnter($event, card)"
                        (dragend)="dragEnd($event, cardElem)"
                    >
                        <ht-save-quick-access-edit
                            [isOpen]="card.isOpen"
                            [quickAccessItem]="card"
                            [quickAccessItems]="cards$ | async"
                            (backgroundClickHandler)="card.isOpen = $event"
                        />

                        <div
                            class="dashboard-card"
                            (click)="clickCard(card)"
                        >
                            <div class="hovericons">
                                <button
                                    class="button drag-icon"
                                    (click)="dragHandleClick($event)"
                                    (mousedown)="enableDrag($event, cardElem)"
                                >
                                    <ui-icon
                                        iconName="direction"
                                        [iconSize]="20"
                                    />
                                </button>

                                <button
                                    class="button delete-icon"
                                    (click)="deleteCard($event, card)"
                                >
                                    <ui-icon
                                        iconName="delete"
                                        [iconSize]="20"
                                    />
                                </button>

                                <button
                                    class="button delete-icon"
                                    (click)="card.isOpen = !card.isOpen; $event.stopPropagation()"
                                >
                                    <ui-icon
                                        iconName="edit"
                                        [iconSize]="20"
                                    />
                                </button>
                            </div>

                            <div class="dashboard-card__icon">
                                <ui-icon
                                    class="card-icon"
                                    [iconName]="card.iconName"
                                    [iconSize]="40"
                                />
                            </div>

                            <div class="dashboard-card__text">
                                <div class="dashboard-card__text--title">
                                    {{ card.title }}
                                </div>

                                <div class="dashboard-card__text--content">
                                    {{ card.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty-card"></div>
                </div>
            }
        </div>
    } @else {
        <div class="usage-advice">
            <p class="usageAdvice__title text--large">
                {{ 'content.dashboard.usageAdviceTitle' | translate }}
            </p>

            <p class="usageAdvice__text text--large">
                {{ 'content.dashboard.usageAdviceText' | translate }}
            </p>

            <div class="usageAdvice__icon">
                <ui-icon
                    [iconName]="'arrow-down'"
                    [iconSize]="40"
                />
            </div>

            <img
                class="usageAdvice__image"
                src="assets/images/quick-access-example.png"
                srcset="
                    assets/images/quick-access-example.png    1x,
                    assets/images/quick-access-example@2x.png 2x,
                    assets/images/quick-access-example@3x.png 3x
                "
                alt="quick-access-example"
            />
        </div>
    }
</div>
