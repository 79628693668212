import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { HttpStatusCodeHandlerService } from '../services/https-status-code-handler.service';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';

@Injectable({
    providedIn: 'root',
})
export class XhrDownloaderUtil {
    private readonly _tokenService = inject(TokenService);
    private readonly _httpStatusCodeHandlerService = inject(HttpStatusCodeHandlerService);

    /**
     * use xhr to download blob
     *
     * This is a workaround, since angulars httpClient breaks the download
     * of zip file encoded data.
     */
    public getBlobWithXHR$(
        url: string,
        operation: 'GET' | 'PUT' = 'PUT',
        reqBodyOrParams: string = '',
    ): Observable<Blob | null> {

        return from<Promise<Blob | null>>(new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) {
                    return;
                }

                if (xhr.status === HttpStatusCode.Ok) {
                    resolve(xhr.response);
                }

                if (xhr.status === HttpStatusCode.Created) {
                    xhr.response.text()
                        .then((data: string) => {
                            this._httpStatusCodeHandlerService
                                .handleRedirectResponseBody(JSON.parse(data));
                        });
                }

                if (xhr.status === HttpStatusCode.PartialContent) {
                    xhr.response.text()
                        .then((data: string) => {
                            this._httpStatusCodeHandlerService
                                .handleMessageResponseBody(JSON.parse(data));
                        });
                }

                resolve(null);
            };

            xhr.onerror = () => {
                this._httpStatusCodeHandlerService.handleErrorResponseMessage(xhr.response.status, xhr.response.message);
                reject(new Error('A xhr error occurred while fetching resources'));
            };

            xhr.open(operation, operation === 'GET' ? `${ url }${ reqBodyOrParams }` : url, true);

            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('HRS-Access-Token', this._tokenService.getToken() as string);

            xhr.responseType = 'blob';

            if (operation === 'PUT') {
                xhr.send(reqBodyOrParams);
            } else {
                xhr.send();
            }
        }));
    }
}
