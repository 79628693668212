@if (menuItem$(); as menuItem) {
    <div
        class="menu-item-wrapper"
        [class.menu-item-wrapper--closed]="!menuItem.open"
    >
        <span
            class="submenu-name"
            (click)="menuItem.open = !menuItem.open"
        >
            {{ menuItem.name }}

            @if (menuItem.submenus.length) {
                <ui-icon
                    iconName="chevron-right"
                    class="menu-item-wrapper-chevron"
                    [iconSize]="20"
                    [class.menu-item-wrapper--closed]="!menuItem.open"
                />
            }
        </span>

        @for (submenu of submenus$(); track $index) {
            @if (submenu.asSidebarItem) {
                <ht-sidebar-menu-item
                    [menuItem]="submenu.asSidebarItem"
                />
            }

            @if (submenu.asLinkItem) {
                <ht-sidebar-link-item
                    [linkItem]="submenu.asLinkItem"
                />
            }
        }
    </div>
}
