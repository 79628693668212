<div class="legend__wrapper">
    @if (legendTitle) {
        <h2 class="legend-title">
            {{ legendTitle }}
        </h2>
    }

    @if (legend) {
        @for (row of legend.rows; track $index) {
            <div class="legend-item">
                <div class="legend-item__color-sample">
                    <div
                        class="legend-item__color-box ht-table-cell"
                        [ngClass]="row | tableRowToColorClass"
                    ></div>
                </div>

                <div class="legend-item__value">
                    {{ row.rowData['legendtext'] }}
                </div>
            </div>
        }
    }
</div>
