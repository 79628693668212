import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, firstValueFrom } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ApiService } from '@hrs-ui/api/util-api';
import { OrgUnitService } from '@hrs-ui/app-status/domain-app-status';
import { DataTypes } from '@hrs-ui/util-definitions';
import _ from 'lodash';

interface TableWidth {
    [key: string]: { // Table
        [key: string]: number; // Column
    };
}

@Injectable({
    providedIn: 'root',
})
export class HtTableWidthService {
    private readonly _debounceTime = 500;
    private readonly _debouncedUpdateData = _.debounce(this.updateData, this._debounceTime);
    private readonly _tableWidthStorageKey: string = 'tableWidth';
    private readonly _data$: BehaviorSubject<TableWidth> = new BehaviorSubject<TableWidth>({});
    private readonly _getData: (orgUnit: string) => Observable<TableWidth>;
    private readonly _setData: (orgUnit: string, data: TableWidth) => Promise<undefined>;

    /**
     * Column width values
     */
    private readonly _defaultColumnWidth = 120;
    private readonly _minWidthProperty = 120;
    private readonly _minWidthDateProperty = 180;

    private readonly _apiService = inject(ApiService);
    private readonly _orgUnitService = inject(OrgUnitService);

    constructor() {
        this._getData = (orgUnit: string) =>
            this._apiService.getFunction<TableWidth>('get-me')(this._tableWidthStorageKey + orgUnit);
        this._setData = async (orgUnit: string, data: TableWidth) =>
            firstValueFrom(
                this._apiService.getFunction<undefined>('put-me')(this._tableWidthStorageKey + orgUnit, data),
            );

        this._orgUnitService.data$
            .pipe(
                switchMap(orgUnit => this._getData(orgUnit)),
                catchError((err: HttpErrorResponse) => {
                    if (err.error.code === '6002') { // Error Code: invalid storage key
                        this._initStoreKey(this._tableWidthStorageKey + this._orgUnitService.data);
                    }

                    return NEVER;
                }),
            )
            .subscribe((response: TableWidth) => {
                this._data$.next(response);
            });
    }

    public get data$(): Observable<TableWidth> {
        return this._data$.asObservable();
    }

    /**
     * return cellWidth
     *
     * @param tableName
     * @param columnName
     */
    public cellWidth$(
        tableName: string,
        columnName: string,
    ): Observable<number | undefined> {
        return this.data$.pipe(
            map(width => width[tableName] && width[tableName][columnName] || this._defaultColumnWidth),
        );
    }

    /**
     * setCellWidth
     *
     * @param cellWidth
     * @param tableName
     * @param columnName
     */
    public setCellWidth(cellWidth: number, tableName: string, columnName: string): void {
        const width = this._data$.getValue();

        if (!width[tableName]) {
            width[tableName] = {};
        }

        width[tableName][columnName] = cellWidth;
        this._data$.next(width);
        this._debouncedUpdateData();
    }

    /**
     * update Data on Server
     */
    public updateData(): void {
        this._setData(this._orgUnitService.data, this._data$.getValue());
    }

    /**
     * minWidthByType
     */
    public minWidthByType(type: DataTypes): number | undefined {
        if (type === DataTypes.DateTime || type === DataTypes.Date) {
            return this._minWidthDateProperty;
        } else {
            return this._minWidthProperty;
        }
    }

    /**
     * inits storage for given key
     *
     * @param key
     */
    private _initStoreKey(key: string): void {
        this._apiService.getFunction('post-me')(key, {})
            .pipe(
                take(1),
            )
            .subscribe();
    }


}
