<div class="save-quick-access-container">
    <button
        class="save-quick-access"
        [class.is-favorite]="isFavorite"
        [title]="
            isFavorite
                ? 'saveQuickAccess.removeFromQuickAccess'
                : ('saveQuickAccess.addToQuickAccess' | translate)
        "
        (click)="toggleQuickAccessIcon()"
    >
        <ui-icon
            [iconName]="isFavorite ? 'favorite-filled' : 'favorite'"
            [iconSize]="20"
        />
    </button>

    <div class="save-quick-access-edit-container">
        @if (isOpen) {
            <div class="triangle"></div>
        }

        <ht-save-quick-access-edit
            [isOpen]="isOpen"
            [quickAccessItem]="quickAccessItem"
            [pageData]="pageData"
            [isFavorite]="isFavorite"
            (backgroundClickHandler)="toggleQuickAccessIcon($event)"
        />
    </div>
</div>
