import { RequestData } from './request-data';

const defaultPerPage = 10;

export const defaultRequestData: RequestData = {
    pageRequest: {
        page: 1,
        perPage: defaultPerPage,
        pageTable: '',
    },
};
