import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    ViewChild,
    ElementRef,
    HostBinding,
} from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import {
    AppTheming,
    CanThemeConstructor,
    CanTheme,
    mixinTheme,
    mixinDisabled,
    mixinRequired,
    CanDisable,
    CanDisableConstructor,
    CanRequire,
    CanRequireConstructor,
} from '@hrs-ui/util-component-support';
import { InputBase } from '../../definitions/input-base';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

const _InputMixinBase: CanThemeConstructor
    & CanDisableConstructor
    & CanRequireConstructor
    & typeof InputBase = mixinTheme(mixinRequired(mixinDisabled(InputBase)));

const defaultIconSize = 16;

const tabIndexOffset = 100;

/**
 * This input is only used in rare contexts where the usual ht-input is not wanted.
 * It is much less styled and more open to parent component styling.
 */
@Component({
    selector: 'ui-input',
    styleUrls: ['./ui-input.component.scss'],
    templateUrl: './ui-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiInputComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => UiInputComponent), multi: true },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        UiIconComponent,
    ],
})
export class UiInputComponent extends _InputMixinBase implements CanTheme, CanDisable, CanRequire {
    // TODO: required -> <input />

    @Input()
    public label = '';

    @Input()
    public hideLabel = false;

    @Input()
    public placeholder = 'placeholder';

    @Input()
    public type = 'text';

    @Input()
    public override disabled = false;

    @Input()
    public override required = false;

    @Input()
    public mimicText = false;

    @Input()
    public maxLength: number | null = null;

    /**
     * Autocomplete attributes help password managers to infer the purpose of a field in a form
     */
    @Input() public autocomplete: string | undefined;

    @Input()
    public icon = '';

    @Input()
    public iconSize: number = defaultIconSize;

    /**
     * Don't show the valid/invalid icon on an input without ng-content
     */
    @Input()
    public hideValidityIcons = false;

    @Input()
    public override theme: AppTheming = AppTheming.Primary;

    @Input()
    public keyDownFn?: (event: KeyboardEvent) => void;

    @Input()
    public inputValidationFn?: (event: InputEvent) => boolean;

    @ViewChild('element', { static: false })
    public input: ElementRef<HTMLInputElement> | undefined;

    @HostBinding('class.input')
    private readonly _hasInputClass = true;

    private _tabIndex: number | undefined;

    public get remainingCharacters(): number {
        if (this.value && this.value.length) {
            return this.value.length;
        } else {
            return 0;
        }
    }

    public get tabIndex(): number | undefined {
        return this._tabIndex;
    }

    @Input()
    public set tabIndex(value: number | undefined) {
        // Adds an offset to the tabindex to avoid single digit indices.
        this._tabIndex = (typeof value === 'number')
            ? value + tabIndexOffset
            : undefined;
    }

    public validateInput(event: InputEvent): boolean {
        return this.inputValidationFn ? this.inputValidationFn(event) : true;
    }
}
