<div class="sidebar__menu-item-wrapper">
    <a class="menu-item">
        <div
            class="skeleton"
            [ngStyle]="{ width: randomWidth }"
        >
            <div class="runner"></div>
        </div>
    </a>

    <span class="submenu-name">
        <div
            class="skeleton"
            [ngStyle]="{ width: randomWidth }"
        >
            <div class="runner"></div>
        </div>

        <div
            class="skeleton"
            style="width: 20px"
        >
            <div class="runner"></div>
        </div>
    </span>

    @for (item of skeletonItems; track $index) {
        <a class="menu-item">
            <div
                class="skeleton"
                [ngStyle]="{ width: randomWidth }"
            >
                <div class="runner"></div>
            </div>
        </a>
    }
</div>
