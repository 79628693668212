@if (linkItem$(); as linkItem) {
    @if (!linkItem.httpaddress) {
        <a
            class="menu-item"
            tabindex="-1"
            [class.disabled]="linkItem.disabled"
            [class.active]="isActive$ | async"
            [attr.href]="linkUrl$()"
            (click)="clickLinkItem($event, linkItem)"
        >
            {{ linkItem.text }}
            {{ linkItemAjax$ | async }}

            @if (linkItem.hasSubmenu) {
                <ui-icon
                    iconName="chevron-right"
                    [iconSize]="16"
                />
            }
        </a>
    } @else {
        <a
            target="_blank"
            rel="noopener noreferrer"
            class="menu-item"
            tabindex="-1"
            [class.disabled]="linkItem.disabled"
            [class.active]="isActive$ | async"
            [attr.href]="linkItem.httpaddress"
        >
            {{ linkItem.text }}

            <ui-icon
                iconName="external-link"
                [iconSize]="16"
            />
        </a>
    }
}
