@if (selectValues) {
    <ht-select
        [placeholder]="placeholder"
        [value]="value"
        [values]="selectValues"
        [disabled]="disabled"
        [required]="required"
        [name]="name"
        [tabIndex]="tabIndex"
        [isDefaultSelect]="isDefaultTextEntry"
        [allowSearch]="true"
        (valueChange)="onValueChange($event)"
    />
} @else {
    <ht-input
        #tooltip="matTooltip"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="disabled"
        [required]="required"
        [name]="name"
        [type]="type"
        [isDefaultTextEntry]="isDefaultTextEntry"
        [tabIndex]="tabIndex"
        [matTooltip]="tooltipText ? tooltipText : ''"
        [matTooltipPosition]="'below'"
        (valueChange)="onValueChange($event)"
        (beforeinput)="validateInput($event, tooltip)"
    />
}
