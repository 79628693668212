import { Injectable } from '@angular/core';
import { LinkModel } from '../definitions/link.model';
import { SidebarNode } from '../definitions/sidebar-node';

/**
 * Type guard
 */
@Injectable({
    providedIn: 'root',
})
export class LinkTypeGuard {

    public isLink = (f: SidebarNode): f is LinkModel =>
        (f as LinkModel).text !== undefined
            && (f as LinkModel).operationId !== undefined;
}
