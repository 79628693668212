<div class="widget__wrapper">
    <div class="widget--card">
        @if (itemData$()?.tableTitle; as tableTitle) {
            <h2 class="filter-title">
                {{ tableTitle }}
            </h2>
        }

        <form #filterForm="ngForm">
            @if (filterItems$().length) {
                <div class="widget__wrapper filter-items">
                    @for (filterItem of filterItems$(); track filterItem.name) {
                        <div
                            class="options-wrapper"
                            [class.hidden-item]="filterItem.hidden"
                        >
                            <label>
                                {{ filterItem.titletext }}
                            </label>

                            <ht-input-mapper [item]="filterItem" />
                        </div>
                    }
                </div>
            }

            @if (checkboxes$().length) {
                <div class="widget__wrapper checkboxes">
                    @for (checkbox of checkboxes$(); track checkbox.name) {
                        <ht-input-mapper
                            [class.hidden-item]="checkbox.hidden"
                            [item]="checkbox"
                        />
                    }
                </div>
            }
        </form>

        @if (itemData$()?.note; as note) {
            <ht-note [note]="note" />
        }

        @if (buttons$().length) {
            <ht-button-mapper [buttons]="buttons$()" />
        }
    </div>
</div>
