import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
    @Input({ required: true })
    public imageUrl?: string | null;

    @Input({ required: true })
    public title = '';

    @Input()
    public hasError: boolean | null = false;

    @Output()
    public readonly showImage = new EventEmitter<string>();
}
