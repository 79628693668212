import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export abstract class TranslationService {
    public abstract translate: (key: string, parameters?: object) => string;

    public abstract translate$: (key: string, parameters?: object) => Observable<string>;

    public abstract streamTranslate$: (key: string, parameters?: object) => Observable<string>;

    public abstract setLanguage: (languageCode: string) => void;

    public abstract setDefaultLanguage: (languageCode: string) => void;
}
