import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    HostBinding,
    ViewChild,
    inject,
} from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { DataTypes } from '@hrs-ui/util-definitions';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-datepicker',
    templateUrl: './ht-datepicker.component.html',
    styleUrls: ['./ht-datepicker.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatInputModule,
        UiIconComponent,
        CalendarModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class HtDatepickerComponent {
    @HostBinding('class')
    public class = 'form-element';

    @ViewChild('calendar', { static: false })
    public calendarElement?: Calendar;

    @Input()
    public placeholder = '';

    @Input()
    public value?: Date | null;

    @Input()
    public name = '';

    @Input()
    public dataType: DataTypes = DataTypes.DateTime;

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public tabIndex = -1;

    @Output()
    public readonly valueChange: EventEmitter<Date> = new EventEmitter();

    public dateFormat = 'mm/dd/y';

    private readonly _userLanguageService = inject(UserLanguageService);

    constructor() {
        this._userLanguageService.language$
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe(lang => {
                this._setDateFormat(lang);
            });
    }

    /**
     * set the date format according to the selected language
     */
    private _setDateFormat(lang: string): void {
        switch (lang) {
            case 'de-DE':
            case 'nl-NL':
                this.dateFormat = 'dd.mm.y';
                break;
            case 'en-AU':
            case 'en-NZ':
            case 'en-GB':
            default:
                this.dateFormat = 'dd/mm/y';
        }
    }
}
