<div
    #textarrayElem
    class="ht-text-array-container"
    [class.ht-text-array-container--readonly]="readOnly"
    [class.ht-text-array-container--with-button]="!readOnly"
    (click)="openDropdown()"
>
    @for (line of value$(); track line) {
        <div class="text">
            {{ line }}
        </div>
    }
    @if (!readOnly) {
        <ui-icon
            class="edit"
            [iconName]="'edit'"
            [iconSize]="16"
        />
    }
</div>
