import { ChangeDetectionStrategy, Component, Input, Signal, ViewChild, computed, inject, input } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { DataTypes, ResponseTable, FormElement, HtButton } from '@hrs-ui/util-definitions';
import { CollectHiddenDataFromDataItemsUtil, FormDataUtil } from '@hrs-ui/util-core';
import { ButtonEventData } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { NoteComponent } from '@hrs-ui/ui/ui-presentation';
import { HtInputMapperComponent } from '@hrs-ui/ui/ui-input';
import { ButtonMapperComponent } from '../../button-mapper/button-mapper.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        FormsModule,
        UiIconComponent,
        NoteComponent,
        ButtonMapperComponent,
        HtInputMapperComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent {
    @ViewChild('filterForm', { static: true }) public form?: NgForm;

    @Input() public tableKey = '';

    public readonly itemData$ = input<ResponseTable | undefined>(undefined, { alias: 'itemData' });

    public readonly checkboxes$: Signal<Array<FormElement>>;
    public readonly filterItems$: Signal<Array<FormElement>>;
    public readonly buttons$: Signal<Array<HtButton>>;

    private readonly _buttonService = inject(ButtonService);

    constructor() {
        const formData$ = computed(() => FormDataUtil.makeFormData(this.itemData$()));

        this.filterItems$ = computed(() => {
            const formData = formData$();

            return formData.filter(filterItem => {
                if (filterItem.type === DataTypes.AjaxArray) {
                    filterItem.filters = CollectHiddenDataFromDataItemsUtil.collectHiddenData(formData);
                }

                return (filterItem.type !== DataTypes.Boolean) && this._isItemVisible(filterItem);
            });
        });

        this.checkboxes$ = computed(() =>
            formData$().filter(filterItem =>
                (filterItem.type === DataTypes.Boolean) && this._isItemVisible(filterItem),
            ),
        );

        this.buttons$ = computed(() => this.itemData$()?.rows[0]?.buttons ?? []);

        this._buttonService.buttonEvent$
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe((buttonEvent: ButtonEventData) => {
                this._handleButtonEvent(buttonEvent);
            });
    }

    // In this component, hidden inputs and formfields are still displayed.
    // This is overridden in the extending QuickFilterComponent.
    protected _isItemVisible(_item: FormElement): boolean {
        return true;
    }

    /**
     * handle events from the buttons
     *
     * @param buttonEvent
     */
    private _handleButtonEvent(buttonEvent: ButtonEventData): void {
        if (buttonEvent.table
            && buttonEvent.table.toLowerCase() === this.tableKey.toLowerCase()
            && buttonEvent.dataSubject
        ) {
            buttonEvent.dataSubject.next(FormDataUtil.getFormSubmitData(this.form, buttonEvent));
        }
    }
}
