import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-select-dropdown-action',
    templateUrl: './select-dropdown-action.component.html',
    styleUrls: ['./select-dropdown-action.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SelectDropdownActionComponent {
    @Input()
    public disabled = false;
}
