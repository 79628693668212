@if (!modalId) {
    <span
        class="multiline-preview"
        [matTooltip]="
            (
                editModeDisabled
                    ? 'content.table.cellTypes.textarea.view'
                    : 'content.table.cellTypes.textarea.edit'
                ) | translate
        "
        tabIndex="{{ tabIndex }}"
        (click)="openInModal()"
        (keydown.enter)="openInModal()"
        (keydown.space)="openInModal()"
    >
        @if (innerTextValue || !editModeDisabled) {
            <ui-icon
                [iconName]="editModeDisabled ? 'search' : 'edit'"
                [iconSize]="20"
            />
        }

        {{ innerTextValue }}
    </span>
} @else {
    <div class="widget--card">
        <form
            #editForm="ngForm"
            (ngSubmit)="returnValueAndClose()"
        >
            <div class="item">
                @if (editModeDisabled) {
                    <span
                        class="value"
                        [innerHtml]="innerTextDisplayValue"
                    ></span>
                }

                @if (!editModeDisabled) {
                    <textarea
                        #textarea
                        class="value"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="40"
                        [placeholder]="placeholderText ? placeholderText : ''"
                        [name]="name ?? ''"
                        [required]="required"
                        [(ngModel)]="innerTextValue"
                    ></textarea>
                }
            </div>
            <div class="button-row">
                <div class="spacer"></div>

                <ui-button
                    icon="close"
                    [label]="
                        (editModeDisabled
                            ? 'content.table.cellTypes.textarea.close'
                            : 'content.table.cellTypes.textarea.cancel'
                        ) | translate
                    "
                    [iconSize]="16"
                    [hasIconLeft]="true"
                    [isOutlined]="true"
                    (click)="closeModal()"
                />

                @if (!editModeDisabled) {
                    <ui-button
                        type="submit"
                        icon="check"
                        [label]="'content.table.cellTypes.textarea.confirm' | translate"
                        [iconSize]="16"
                        [hasIconLeft]="true"
                        [tabIndex]="0"
                        (focusout)="resetFocus()"
                    />
                }
            </div>
        </form>
    </div>
}
