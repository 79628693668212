import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventType } from '../definitions/event.type';
import { EventAction } from '../definitions/event-action.type';
import { EventButtonsModel } from '../definitions/event-buttons-model';
import { EventModel } from '../definitions/event-model';

export class EventService {
    public buttonEvents: Observable<EventButtonsModel>;
    public events: Observable<EventModel>;
    public focusFirstEditableCellByTabIndex$ = new BehaviorSubject<number>(-1);

    private readonly _buttonEvents: Subject<EventButtonsModel> = new Subject();
    private readonly _events: Subject<EventModel> = new Subject();

    constructor() {
        this.buttonEvents = this._buttonEvents.asObservable();
        this.events = this._events.asObservable();
    }

    /**
     * Ised within tables for button clicks
     *
     * @param payload
     * @param type
     * @param values
     * @param buttonType
     */
    public emitButton<Payload>(
        payload: Payload,
        type: Array<string>,
        values: Array<string>,
        buttonType?: string,
    ): void {
        this._buttonEvents.next(new EventButtonsModel(
            payload,
            type,
            values,
            buttonType,
        ));
    }

    /**
     * ised by boolean-cell and "selectAll"/"submit"
     *
     * @param payload
     * @param type
     * @param action
     */
    public emit<Payload>(
        payload: Payload,
        type: EventType,
        action?: EventAction,
    ): void {
        this._events.next(new EventModel(payload, type, action));
    }


}
