import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Signal,
    ViewChild,
    computed,
    effect,
    inject,
    input,
    model,
    signal,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import {
    HtButtType,
    HtItemType,
    HtRowData,
    HtTableColumn,
    HtTableRow,
    PageKey,
    PageResponse,
    ResponseRow,
    ResponseTable,
    Settings,
} from '@hrs-ui/util-definitions';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { RequestDataService, MagicRouterService, CrudService } from '@hrs-ui/api/domain-api';
import { RowCollectorUtil, SettingsUtil } from '@hrs-ui/util-core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { ButtonEventData } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { NoteComponent } from '@hrs-ui/ui/ui-presentation';
import { HtDynamicTableComponent } from '@hrs-ui/ui/ui-dynamic-table';
import { TablePaginatorComponent } from './table-paginator/table-paginator.component';
import { v4 as uuidv4 } from 'uuid';

interface TableData {
    tableTitle: string;
    rows: Array<HtTableRow>;
    columns: Array<HtTableColumn>;
    settings?: Settings;
    contentType?: string;
    note?: string;
    pageKey?: PageKey;
}

const defaultTableData: TableData = {
    tableTitle: '',
    rows: [],
    columns: [],
};

@Component({
    selector: 'ht-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslationModule,
        UiIconComponent,
        NoteComponent,
        HtDynamicTableComponent,
        TablePaginatorComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
    ],
})
export class TableComponent {
    @ViewChild('table', { static: false }) public table?: HtDynamicTableComponent;

    @Input()
    public tableKey = '';

    @Input()
    public modalId?: number;

    public readonly itemData$ = input<ResponseTable | undefined>(undefined, { alias: 'itemData' });

    public readonly paginationDetails$ = model<PageResponse | null | undefined>(null, { alias: 'paginationDetails' });

    public readonly paginatorSelectOptions$: Signal<Array<number>>;

    public readonly tableData$: Signal<TableData>;
    public readonly rowData$: Signal<Record<string, HtRowData>>;

    public readonly isLoading$ = signal(false);
    public readonly isCollapsed$ = signal(false);
    public collapseHeightRemainder = 0;

    public collapseOptionIndicatorIcon = 'arrow-down';
    public expandOptionIndicatorIcon = 'arrow-up';

    private readonly _changedRowData$ = signal<Record<string, HtRowData>>({});

    private readonly _buttonService = inject(ButtonService);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _modalService = inject(ModalService);
    private readonly _requestDataService = inject(RequestDataService);
    private readonly _crudService = inject(CrudService);

    constructor() {
        // Reset changed row data whenever item data is new.
        effect(
            () => {
                this.itemData$();

                this._changedRowData$.set({});
            },
            {
                allowSignalWrites: true,
            },
        );

        this.tableData$ = computed(() => this._mapTableData(this.itemData$()));

        // Extract the row data from the table data and keep it updated with any marked changes.
        this.rowData$ = computed(() => {
            const changedRowData = this._changedRowData$();

            return this.tableData$().rows
                .reduce((collection, row) => ({
                    ...collection,
                    [row.rowUId]: {
                        ...(changedRowData[row.rowUId] ?? row.rowData),
                    },
                }), {});
        }, { equal: () => false });

        this.paginatorSelectOptions$ = computed(() => {
            const paginationDetails = this.paginationDetails$();

            return paginationDetails && paginationDetails.perPage !== 1
                ? Array.from(
                    // Create Array with the length of the amount of pages and fill it with pageNumbers
                    Array(Math.ceil(paginationDetails.numOfItems / paginationDetails.perPage)),
                    (val, index) => index + 1,
                )
                : [1];
        });

        this._buttonService.buttonEvent$
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe((buttonEvent: ButtonEventData) => {
                this._handleButtonEvent(buttonEvent);
            });
    }

    /**
     * gets triggered if page is changed
     *
     * @param $event
     */
    public pageEvent($event: PageEvent): void {
        const paginationDetails = this.paginationDetails$();

        if (paginationDetails) {
            const updatedPaginationDetails = {
                ...paginationDetails,
                currentPage: ($event.pageIndex + 1) || paginationDetails.currentPage,
                perPage: $event.pageSize || paginationDetails.perPage,
            };

            this.paginationDetails$.set(updatedPaginationDetails);

            this._requestDataService.updateData({
                pageRequest: {
                    page: updatedPaginationDetails.currentPage,
                    perPage: updatedPaginationDetails.perPage,
                },
            });

        }
    }

    public selectionChanged($event: MatSelectChange): void {
        const currentPage = $event.source.value as number;
        const paginationDetails = this.paginationDetails$();

        if (paginationDetails) {
            const updatedPaginationDetails = {
                ...paginationDetails,
                currentPage,
            };

            this.paginationDetails$.set({
                ...updatedPaginationDetails,
                currentPage,
            });

            this._requestDataService.updateData({
                pageRequest: {
                    page: updatedPaginationDetails.currentPage,
                    perPage: updatedPaginationDetails.perPage,
                },
            });

        }
    }

    /**
     * Toggles the current table to either collapsed or expanded
     */
    public toggleCollapsibleTable(): void {
        this.collapseHeightRemainder = this.table?.tableHeaderRow?.nativeElement.offsetHeight ?? 0;

        this.isCollapsed$.update(set => !set);
    }

    /**
     * Check or uncheck all cells of a boolean column
     */
    public checkAllRowsOfColumn(shouldBeChecked: boolean, columns?: Array<string>, dataValidator?: Array<string>): void {
        this.tableData$().rows
            .forEach(row => {
                if (
                    dataValidator
                        ? dataValidator.every(validator => row.rowData[validator] === true)
                        : true
                ) {
                    columns
                        ?.filter(column => !SettingsUtil.isCellDisabled(row, column))
                        .forEach(column => {
                            this.updateChangedRowData({ value: shouldBeChecked, rowId: row.rowUId, column });
                        });
                }
            });
    }

    /**
     * Update the changed row data tracking with a single change in a single cell.
     */
    public updateChangedRowData({ value, rowId, column }: { value: HtItemType; rowId: string; column: string }): void {
        // Join rows with changes for the most up-to-date state to build on.
        const matchedRow =
            this._joinAllRowsWithChanges()
                .find(row => row.rowUId === rowId);

        if (!matchedRow) {
            return;
        }

        this._changedRowData$.update(changedRowData => ({
            ...changedRowData,
            [rowId]: RowCollectorUtil.applyChangedValue(value, { ...matchedRow }, column).rowData,
        }));
    }

    /**
     * parse response data to table data
     *
     * @param data
     */
    private _mapTableData(data?: ResponseTable): TableData {
        if (!data) {
            return defaultTableData;
        }

        return {
            tableTitle: data.tableTitle || '',
            columns: this._mapColumns(data),
            rows: this._mapRows(data),
            settings: data.settings,
            contentType: data.contentType,
            note: data.note,
            pageKey: data.pageKey,
        };
    }

    /**
     * Extract columns from the response data and converts to internal structure
     * Removes columns that are set to not be visible
     * Adds a column UID to every column
     * Marks the first editable column as such
     *
     * @param data
     */
    private _mapColumns(data: ResponseTable): Array<HtTableColumn> {
        let hasSetEditableColumn = false;

        return data.columns
            .filter(column =>
                !column.settings?.visible
                || column.settings.visible !== 'false',
            )
            .map((column, index) => {
                let isFirstEditableColumn = false;

                if (!hasSetEditableColumn && !column.readOnly) {
                    isFirstEditableColumn = true;
                    hasSetEditableColumn = true;
                }

                return {
                    name: column.id,
                    displayName: column.titletext,
                    type: column.dataType,
                    buttType: column.buttType,
                    values: column.values,
                    keys: column.keys,
                    readOnly: column.readOnly,
                    maxLength: column.maxLength,
                    headerAlign: column.settings?.text_justify,
                    hint: column.settings?.hint,
                    grouped: column.settings?.grouped,
                    firstEditableColumn: isFirstEditableColumn,
                    columnUId: uuidv4(),
                    columnIndex: index,
                };
            });
    }

    /**
     * Extract rows from the response data
     * Adds a row UID to every row
     * Marks the first row as the first editable row
     *
     * @param data
     */
    private _mapRows(data?: ResponseTable): Array<HtTableRow> {
        return data?.rows
            .map((row, index) => ({
                ...row,
                firstEditableRow: index === 0,
                rowUId: uuidv4(),
                rowIndex: index,
            }))
            ?? [];
    }

    /**
     * handle events from the buttons
     *
     * @param buttonEvent
     */
    private _handleButtonEvent(buttonEvent: ButtonEventData): void {
        if (buttonEvent.table
            && buttonEvent.table.toLowerCase() !== this.tableKey.toLowerCase()
            && (buttonEvent.buttType !== HtButtType.SubmitCrud && buttonEvent.buttType !== HtButtType.SubmitCrudAll)
        ) {
            return;
        }

        switch (buttonEvent.buttType) {
            case HtButtType.CheckboxFalse:
            case HtButtType.CheckboxTrue: {
                const columns = buttonEvent.tableColumns;

                const shouldBeChecked = buttonEvent.buttType === HtButtType.CheckboxTrue;

                this.checkAllRowsOfColumn(shouldBeChecked, columns, buttonEvent.data_validator);
                break;
            }
            case HtButtType.SubmitCrud: {
                const rowsToSave =
                    RowCollectorUtil.collectChangedRows(
                        this._joinAllRowsWithChanges(),
                    )
                        .filter(row => row.updatesp !== undefined);

                if (rowsToSave?.length) {
                    buttonEvent.buttonCallbacks?.buttonDisableCallback();

                    combineLatest(
                        rowsToSave
                            .map(row => this._crudService.saveRow(row, buttonEvent.operationId)),
                    )
                        .pipe(
                            take(1),
                            finalize(() => { buttonEvent.buttonCallbacks?.buttonEnableCallback(); }),
                        )
                        .subscribe(() => {
                            this._reloadModalOrPage();
                        });
                }

                break;
            }
            case HtButtType.SubmitCrudAll: {
                const rowsToSave =
                    this._joinAllRowsWithChanges()
                        .filter(row => row.updatesp !== undefined);


                if (rowsToSave?.length) {
                    buttonEvent.buttonCallbacks?.buttonDisableCallback();

                    combineLatest(
                        rowsToSave
                            .map(row => this._crudService.saveRow(row, buttonEvent.operationId)),
                    )
                        .pipe(
                            take(1),
                            finalize(() => { buttonEvent.buttonCallbacks?.buttonEnableCallback(); }),
                        )
                        .subscribe(() => {
                            this._reloadModalOrPage();
                        });
                }

                break;
            }
            default: {
                if (buttonEvent.dataSubject) {
                    buttonEvent.dataSubject.next(this._getTableSubmitData(buttonEvent));
                }
            }
        }
    }

    /**
     * create table submit data
     *
     * @param buttonEvent
     */
    private _getTableSubmitData(buttonEvent: ButtonEventData): HtRowData {
        const rows = this._joinAllRowsWithChanges();

        if (!rows.length) {
            return {};
        }

        return rows
            .filter((row: ResponseRow) => buttonEvent.data_validator
                ? buttonEvent.data_validator.every(validator => row.rowData[validator] === true)
                : true,
            )
            .reduce<HtRowData>((tableSubmitData: HtRowData, currentValue) => buttonEvent.tableColumns
                ? buttonEvent.tableColumns
                    .reduce((innerTableSubmitData, column) => {
                        const columnData = innerTableSubmitData[column];

                        return Array.isArray(columnData)
                            ? { ...innerTableSubmitData, [column]: [...columnData, currentValue.rowData[column]] }
                            : { ...innerTableSubmitData, [column]: [currentValue.rowData[column]] };
                    }, tableSubmitData)
                : { ...tableSubmitData, ...currentValue.rowData }, {});
    }

    /**
     * Reloads modal or page
     */
    private _reloadModalOrPage(): void {
        if (this.modalId) {
            this._modalService.reloadModal(this.modalId);
        } else {
            this._magicRouterService.reloadPage();
        }
    }

    /**
     * Get all rows and join any changes
     *
     * @returns All rows, with the changes in the rowData
     */
    private _joinAllRowsWithChanges(): Array<HtTableRow> {
        return RowCollectorUtil.joinRowsWithChanges(this.tableData$().rows, this._changedRowData$());
    }
}
