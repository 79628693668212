<div
    class="widget__wrapper"
    [ngClass]="{ 'widget__wrapper--compressed': itemData$()?.settings?.layout === 'tight' }"
>
    <div class="widget widget--card detail-root">
        @if (detailData$(); as detailData) {
            @if (detailData.detailTitle; as title) {
                <h2 class="detail-title">
                    {{ title }}
                </h2>
            }

            <div class="detail-content">
                @for (item of detailData.items; track $index) {
                    <div class="item">
                        <div class="title">
                            {{ item.displayName }}
                        </div>

                        <div class="value">
                            @if (item.button) {
                                <ht-button
                                    [htButton]="item.button"
                                    (clickEvent)="handleButtonClick(item.button, $event)"
                                />
                            } @else {
                                @switch (item.type) {
                                    @case ('textarray') {
                                        <form>
                                            <ht-textarray
                                                [value]="item.value ? item.value : []"
                                                [name]="item.name"
                                                [readOnly]="item.readOnly ? item.readOnly : false"
                                            />
                                        </form>
                                    }

                                    @case ('textarea') {
                                        <div [innerHtml]="item.value?.[0] ?? '' | convertLineBreaks: 'html'"></div>
                                    }

                                    @default {
                                        {{ item.value?.[0] }}
                                    }
                                }
                            }
                        </div>
                    </div>

                    <hr class="separator" />
                }

                @if (!detailData.items.length) {
                    <span class="widget no-data">
                        <ui-icon
                            iconName="error-circle"
                            [iconSize]="20"
                        />

                        {{ 'content.detail.noData' | translate }}
                    </span>
                }
            </div>
        }
    </div>
</div>
