import { Observable, Subscriber } from 'rxjs';

export function fileToUrl$(file: File): Observable<string> {
    return new Observable(
        (subscriber: Subscriber<string>): void => {
            const fileReader = new FileReader();

            fileReader.onload = (event: ProgressEvent<FileReader>): void => {
                subscriber.next(event.target?.result as string ?? '');
            };

            fileReader.onerror = (event: ProgressEvent<FileReader>): void => {
                subscriber.error(event);
            };

            fileReader.readAsDataURL(file);
        },
    );
}
