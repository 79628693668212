@if (notification) {
    <div class="notification notification__{{ notification.severity }}">
        <ui-icon
            class="notification__icon"
            [iconName]="'error-circle'"
            [iconSize]="20"
        />

        <div class="notification__message">
            {{ notification.message }}
        </div>
    </div>
}
