import { CanThemeConstructor } from '../definitions/can-theme';
import { AppTheming } from '../definitions/app-theming';
import { HasElementRefConstructor } from '../definitions/has-element-ref';
import { ElementRef } from '@angular/core';

/**
 * a mixin for themes
 */
export function mixinTheme<T extends HasElementRefConstructor>(
    base: T, defaultColor?: AppTheming): CanThemeConstructor & T {
    return class extends base {
        public override elementRef: ElementRef | undefined = undefined;

        private _theme: AppTheming = AppTheming.Primary;

        constructor(...args: Array<any>) {
            super(...args);

            // Set the default color that can be specified from the mixin.
            this.theme = defaultColor ? defaultColor : AppTheming.Primary;
        }

        /**
         * gets the set theme
         */
        public get theme(): AppTheming { return this._theme; }

        /**
         * sets a theme of type AppTheming
         */
        public set theme(value: AppTheming) {
            const colorPalette = value || defaultColor;

            if (colorPalette !== this._theme) {
                if (this._theme) {
                    this.elementRef?.nativeElement.classList.remove(`theme--${ this._theme }`);
                }

                if (colorPalette) {
                    this.elementRef?.nativeElement.classList.add(`theme--${ colorPalette }`);
                }

                this._theme = colorPalette;
            }
        }
    };
}
