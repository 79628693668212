@if (!!value$() && !hidden) {
    @if (imageUrl$) {
        <div
            class="button-container"
            (click)="showImage()"
        >
            <ui-icon
                [iconName]="'document'"
                [iconSize]="20"
            />
        </div>
    }
}
