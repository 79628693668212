@if (hasError$()) {
    <ui-icon
        class="error"
        [iconName]="'error-circle'"
        [iconSize]="200"
    />
} @else {
    @if (imageUrl || (imageUrl$ | async); as url) {
        <img
            alt=""
            class="image"
            [src]="url"
        />
    } @else {
        <div class="loading-icon">
            <img
                src="/assets/images/loading.gif"
                [alt]="'loading.loading' | translate"
            />
        </div>
    }
}
