import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, signal } from '@angular/core';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'ht-image',
    templateUrl: './ht-image.component.html',
    styleUrls: ['./ht-image.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        UiIconComponent,
        TranslationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtImageComponent {

    @Input()
    public imageUrl?: string;

    public readonly hasError$ = signal<boolean>(false);

    private _imageUrl$?: Observable<string> | undefined;

    @Input()
    public set imageUrl$(source: Observable<string> | undefined) {
        // Add an error state control to the observable
        this._imageUrl$ = source
            ?.pipe(
                tap({
                    next: () => this.hasError$.set(false),
                    error: () => this.hasError$.set(true),
                }),
            );
    }

    public get imageUrl$(): Observable<string> | undefined {
        return this._imageUrl$;
    }

}
