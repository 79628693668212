import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTypes } from '@hrs-ui/util-definitions';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { EditableDatetimeComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-datetime-cell',
    templateUrl: './datetime-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './datetime-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        EditableDatetimeComponent,
        TranslateModule,
    ],
})
export class DateTimeCellComponent extends BaseCellComponent<string> implements HtCellComponent {

    @Input()
    public dataType: DataTypes = DataTypes.DateTime;

    @Input()
    public dateFormat = '';

    @Input()
    public timeFormat = 'dd/MM/yy';

    @Input()
    public timeFormatOptions: object = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    @Input()
    public dateFormatOptions: object = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    };

    @Input()
    public cultureCode = 'de-DE';

    @Input()
    public override editModeDisabled = true;

    public defaultValue = '';
}
