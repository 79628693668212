@for (table of tables$() | keyvalue: returnZero; track table.key) {
    @switch (table.value.contentType) {
        @case (ContentTypes.Table) {
            <ht-table
                [tableKey]="table.key"
                [itemData]="table.value"
                [modalId]="modalId"
                [paginationDetails]="paginationDetails$ | async"
            />
        }

        @case (ContentTypes.Detail) {
            <ht-detail
                [tableKey]="table.key"
                [itemData]="table.value"
            />
        }

        @case (ContentTypes.EditableTable) {
            <ht-table
                [tableKey]="table.key"
                [itemData]="table.value"
                [paginationDetails]="paginationDetails$ | async"
            />
        }

        @case (ContentTypes.Filter) {
            <ht-filter
                [tableKey]="table.key"
                [itemData]="table.value"
            />
        }

        @case (ContentTypes.QuickFilter) {
            <ht-quickfilter
                [tableKey]="table.key"
                [itemData]="table.value"
            />
        }

        @case (ContentTypes.Edit) {
            <ht-edit
                [tableKey]="table.key"
                [itemData]="table.value"
                [modalId]="modalId"
            />
        }

        @case (ContentTypes.Insert) {
            <ht-insert
                [tableKey]="table.key"
                [itemData]="table.value"
                [modalId]="modalId"
                [openInModal]="shouldOpenInModal$()"
            />
        }

        @case (ContentTypes.InsertImage) {
            <ht-insert-image
                [tableKey]="table.key"
                [itemData]="table.value"
                [modalId]="modalId"
                [openInModal]="shouldOpenInModal$()"
            />
        }

        @case (ContentTypes.Spacer) {
            <ht-spacer
                [itemData]="table.value"
            />
        }
    }
}
