import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toPageSizeOptions',
    standalone: true,
})
export class PageSizeOptionsPipe implements PipeTransform {
    /**
     * getPageSizeOptions in dependence on maxCount to be sure to not exceed the maxCount
     *
     * @param maxCount
     */
    public transform(maxCount: number): Array<number> {
        const firstPageSizeOption = 10;
        const secondPageSizeOption = 20;
        const thirdPageSizeOption = 50;
        const fourthPageSizeOption = 100;
        const pageSizeOptions = [firstPageSizeOption, secondPageSizeOption, thirdPageSizeOption, fourthPageSizeOption];

        pageSizeOptions.push(maxCount);

        return pageSizeOptions.filter(option => option <= maxCount);
    }
}
