@if (!hidden) {
    <form #form="ngForm">
        <ht-editable-datetime
            [dateTimeString]="value$()"
            [editModeDisabled]="column$().readOnly || column$().editModeDisabled || disabled"
            [dateFormat]="dateFormat"
            [dataType]="dataType"
            [timeFormat]="timeFormat"
            [timeFormatOptions]="timeFormatOptions"
            [dateFormatOptions]="dateFormatOptions"
            [cultureCode]="cultureCode"
            [tabIndex]="tabIndex"
            (valueChange)="onValueChange($event)"
        />
    </form>
}
