import { XhrDownloaderUtil } from './xhr-downloader.util';
import { RequestData } from '@hrs-ui/api/util-api';
import { Observable } from 'rxjs';
import { UrlParserUtil } from '@hrs-ui/util-route';

export class BlobDownloaderUtil {
    constructor(
        private readonly _xhrDownloader: XhrDownloaderUtil,
        private readonly _basePath: string,
        private readonly _path: string,
    ) {}

    public downloadFileAsGet(params?: Record<string, string | number | boolean>): Observable<Blob | null> {
        return this._xhrDownloader.getBlobWithXHR$(
            `${ this._basePath }${ this._path }`,
            'GET',
            UrlParserUtil.parseDataToUrlParams(params),
        );
    }

    public downloadFileAsPut(requestData?: RequestData): Observable<Blob | null> {
        return this._xhrDownloader.getBlobWithXHR$(
            `${ this._basePath }${ this._path }`,
            'PUT',
            JSON.stringify(requestData),
        );
    }
}
