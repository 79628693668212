import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponseTable } from '@hrs-ui/util-definitions';
import { CommonModule } from '@angular/common';
import { TableRowColorClassPipe } from '../../utilities/table-row-color-class.pipe';

/**
 * A Legend that often accompanies tables, like in Supply Contracts,
 * which describes content about the page, like what certain background colors mean on a specific
 * table the user is at
 */
@Component({
    selector: 'ht-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TableRowColorClassPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent {
    // to retrieve a title that is the same for every legend table
    @Input()
    public legendTitle?: string;

    // we will ignore legend.columns because it only seems to serve as a way
    @Input()
    public legend?: ResponseTable;
}
