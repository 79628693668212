/**
 * Documentation: https://docs.google.com/document/d/1aPPFsSmGn1Uc6pVa4zevg3s55yzmJZB_Sw7FvXB7r_Q/edit?usp=sharing
 */
export enum ContentTypes {
    EditableTable = 'editableTable',
    Filter = 'filter',
    Insert = 'insert',
    Detail = 'detail',
    Edit = 'edit',
    Table = 'table',
    QuickFilter = 'quickFilter',
    InsertImage = 'insertImage',
    Spacer = 'spacer',
}
