import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { EditablePasswordComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-password-cell',
    templateUrl: './password-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './password-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EditablePasswordComponent,
    ],
})
export class PasswordCellComponent extends BaseCellComponent<string> implements HtCellComponent {

    public defaultValue = '';
}
