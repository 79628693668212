/**
 * hTrak HRS API
 * An API allows users to access list, create, update and delete purchase request approvers.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: admin@prospitalis-htrak.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorMessage } from '../model/models';
import { HRSMessageResponse } from '../model/models';
import { HRSPageRequest } from '../model/models';
import { HRSPageResponse } from '../model/models';
import { HRSRedirect } from '../model/models';
import { PurchaseRequestApproversCRUD } from '../model/models';
import { PurchaseRequestApproversRecord } from '../model/models';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrapprovalsmaintApi {

    protected basePath = 'https://dev.htrak.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Org Unit Administration/Maintenance Menu/Staffing/Purchase Request Approvers
     * Get the specified PurchaseRequestApprovers object.
     * @param staffuserid 
     */
    public getPrapprovalsmaint(staffuserid: string): Observable<PurchaseRequestApproversRecord> {
        if (staffuserid === null || staffuserid === undefined) {
            throw new Error('Required parameter staffuserid was null or undefined when calling getPrapprovalsmaint.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (staffuserid !== undefined && staffuserid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>staffuserid, 'staffuserid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<PurchaseRequestApproversRecord>('get', `${this.configuration.basePath}/ouadmin/PR_Approvals_Maint/*`,
            {
                body: null,
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Org Unit Administration/Maintenance Menu/Staffing/Purchase Request Approvers
     * Add new PurchaseRequestApprovers object to the system.
     * @param purchaseRequestApproversCRUD 
     */
    public postPrapprovalsmaint(purchaseRequestApproversCRUD?: PurchaseRequestApproversCRUD): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<any>('post', `${this.configuration.basePath}/ouadmin/PR_Approvals_Maint/*`,
            {
                body: purchaseRequestApproversCRUD,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Org Unit Administration/Maintenance Menu/Staffing/Purchase Request Approvers
     * Retrieves Purchase Request Approvers objects filtered by OrgUnitID and SearchText. All Purchase Request Approvers objects containing search text, which is part of StaffUserID and Cost Center are retrieved.
     * @param hRSPageRequest 
     */
    public putPrapprovalsmaint(hRSPageRequest?: HRSPageRequest): Observable<HRSPageResponse> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (APIKeyHeader) required
        localVarCredential = this.configuration.lookupCredential('APIKeyHeader');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('HRS-Access-Token', localVarCredential);
        }
        let localVarHttpHeaderAcceptSelected: string | undefined;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

    return this.httpClient.request<HRSPageResponse>('put', `${this.configuration.basePath}/ouadmin/PR_Approvals_Maint`,
            {
                body: hRSPageRequest,
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

}
