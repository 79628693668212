@if (!isLoading$() && tableData$(); as tableData) {
    <div
        class="widget__wrapper"
        [class.widget__wrapper--compressed]="tableData?.settings?.layout === 'tight'"
        [class.widget__wrapper--scrollable]="tableData?.settings?.scrollable"
    >
        @if (tableData.tableTitle) {
            <h2
                #tableTitleHeader
                class="table-title"
            >
                {{ tableData.tableTitle }}
            </h2>
        }

        @if (tableData?.settings?.collapsible) {
            <button
                #collapseButton
                [ngClass]="{ 'collapsed ': isCollapsed$() }"
                [class]="'collapse-button compressed'"
                (click)="toggleCollapsibleTable()"
            >
                <ui-icon
                    [class]="'icon'"
                    [ngClass]="{ collapsed: isCollapsed$() }"
                    [iconName]="isCollapsed$() ? expandOptionIndicatorIcon : collapseOptionIndicatorIcon"
                    [iconSize]="16"
                />
            </button>
        }

        <div
            class="widget__table"
            [style.max-height]="isCollapsed$() ? collapseHeightRemainder + 'px' : 'none'"
            [class.collapsed]="isCollapsed$()"
        >
            @if (tableData.rows.length) {
                <ht-dynamic-table
                    #table
                    [halfWidth]="tableData.settings?.half_width"
                    [width]="tableData.settings?.width"
                    [rows]="tableData.rows"
                    [rowData]="rowData$()"
                    [columns]="tableData.columns"
                    [tableKey]="tableKey"
                    [contentType]="tableData.contentType"
                    (cellValueChange)="updateChangedRowData($event)"
                    (checkAllRowsOfColumn)="checkAllRowsOfColumn($event.shouldBeChecked, [$event.column])"
                />
            }

            @if (tableData.rows.length && tableData.pageKey) {
                <ht-table-paginator
                    [paginationDetails]="paginationDetails$()"
                    [paginatorSelectOptions]="paginatorSelectOptions$()"
                    (page)="pageEvent($event)"
                    (pageSelection)="selectionChanged($event)"
                />
            }

            @if (!tableData.rows.length) {
                <span class="widget no-data">
                    <ui-icon
                        iconName="error-circle"
                        [iconSize]="20"
                    />

                    {{ 'content.table.noData' | translate }}
                </span>
            }

            @if (tableData.note) {
                <ht-note
                    [class.note-spacing]="!tableData.rows.length || !tableData.pageKey"
                    [note]="tableData.note"
                />
            }
        </div>
    </div>
}
