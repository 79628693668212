import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment, APP_CONFIG } from '@hrs-ui/config/util-config';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app/app-routing.module';
import { defaultStoreProvider } from '@state-adapt/angular';
import { authInterceptorProvider } from '@hrs-ui/authentication/ui-authentication';
import { contentInterceptorProvider } from '@hrs-ui/api/ui-api';
import { apiConfigProvider } from '@hrs-ui/config/domain-config';
import { apiServiceProvider } from '@hrs-ui/api/domain-apiclient';
import { messageComponentProvider } from '@hrs-ui/modal/ui-modal';
import { contentWidgetComponentProvider } from '@hrs-ui/feature-htrak-shell';
import { bugsnagProvider } from './bugsnag';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            // General
            AppRoutingModule,
            FormsModule,
            TranslationModule.forRoot(),
        ),
        {
            provide: APP_CONFIG,
            useValue: environment,
        },
        contentWidgetComponentProvider,
        messageComponentProvider,
        apiServiceProvider,
        apiConfigProvider,
        contentInterceptorProvider,
        authInterceptorProvider,
        defaultStoreProvider,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        bugsnagProvider,
    ],
})
    .catch(err => console.error(err));
