import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HasElementRefConstructor } from '../definitions/has-element-ref';
import { CanSetProcessingConstructor } from '../definitions/can-set-processing';

/**
 * Mixin to augment a directive with a `processing` property.
 */
export function mixinProcessing<T extends HasElementRefConstructor>(
    base: T,
    defaultClass?: string,
): CanSetProcessingConstructor & T {
    return class extends base {
        private _processing = false;
        private readonly _targetClass = '';

        constructor(...args: Array<any>) {
            super(...args);
            this._targetClass = defaultClass ? defaultClass : this.elementRef?.nativeElement.className;
        }

        public get processing(): boolean {
            return this._processing;
        }

        public set processing(value: unknown) {
            this._processing = coerceBooleanProperty(value);
            this._processing ?
                this.elementRef?.nativeElement.classList.add(`${ this._targetClass }--processing`) :
                this.elementRef?.nativeElement.classList.remove(`${ this._targetClass }--processing`);
        }
    };
}
