import {
    HttpErrorResponse,
    HttpEvent,
    HttpResponse,
    HttpStatusCode,
} from '@angular/common/http';
import { Observable, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Ensures that the response body is in the desired format by parsing it based on content-type.
 * The body is previously requested in text format so that we can handle it safely.
 * (e.g. a JSON response is always stringified and we don't have to differentiate before parsing it)
 *
 * @returns
 */
export function parseResponseType<T extends string>(): OperatorFunction<HttpEvent<T>, HttpEvent<T | object>> {
    return (response$: Observable<HttpEvent<T>>) =>
        response$
            .pipe(
                map(response => {
                    if (response instanceof HttpResponse) {
                        if (response.status === HttpStatusCode.NoContent) {
                            return response.clone({ body: {} });
                        }

                        const contentType = response.headers.get('content-type') || '';

                        if (contentType.includes('application/json')) {
                            return response.clone<object>({
                                body: response.body
                                    ? JSON.parse(response.body)
                                    : null,
                            });
                        } else if (contentType.includes('image/svg+xml')) {
                            return response;
                        } else {
                            return response.clone({
                                body: new Blob(response.body ? [response.body] : [], { type: contentType }),
                            });
                        }
                    }

                    return response;
                }),
                catchError(
                    (_errorResponse: HttpErrorResponse) => {
                        if (_errorResponse.error && (_errorResponse.headers.get('content-type') || '').includes('application/json')) {
                            throw new HttpErrorResponse({
                                ..._errorResponse,
                                url: _errorResponse.url ?? undefined,
                                error: JSON.parse(_errorResponse.error),
                            });
                        } else {
                            // DO NOTHING, this is wanted or handled elsewhere...
                            throw _errorResponse;
                        }
                    },
                ),
            );
}
