import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CrudService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-delete-cell',
    templateUrl: './delete-cell.component.html',
    styleUrls: [
        './delete-cell.component.scss', '../base-cell.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        UiIconComponent,
    ],
})
export class DeleteCellComponent extends BaseCellComponent implements HtCellComponent {

    @Input()
    public override editModeDisabled = false;

    public defaultValue = '';

    private _operationId?: string;

    private readonly _crudService = inject(CrudService);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _route = inject(ActivatedRoute);

    constructor() {
        super();

        this._route.queryParams
            .subscribe(queryParams => {
                this._operationId = queryParams['operationId'];
            });
    }

    /**
     * When cell is clicked, delete this row and reload the page.
     */
    public click(): void {
        const row = this.row$();

        if (row && this._operationId) {
            this._crudService.deleteRow(row, this._operationId)
                .pipe(
                    take(1),
                )
                .subscribe(() => {
                    this._magicRouterService.reloadPage();
                });
        }
    }
}
