import { ColumnConfig, HtTableColumn, ResponseRow } from '@hrs-ui/util-definitions';

/**
 * Util functions that help deal with our Settings, that affect columns, tables, buttons and rows.
 */
export class SettingsUtil {

    /**
     * Used in table cells to get any disabled setting specified in this row.
     *
     * @return String list of only those settings that are disabled.
     */
    public static isCellDisabled(
        currentRow: ResponseRow,
        columnName: string,
    ): boolean {
        return !!(
            columnName &&
            currentRow.settings?.cells?.[columnName]?.enabled === 'false'
        );
    }

    /**
     * Used in table cells to get any hidden setting specified in this row.
     *
     * @return String list of those settings that are hidden.
     */
    public static isCellHidden(
        currentRow: ResponseRow,
        column: ColumnConfig & {
            values?: Array<string>;
            keys?: Array<string>;
            readOnly?: boolean;
            maxLength?: number;
            editModeDisabled?: boolean;
        },
    ): boolean {
        return !(
            !currentRow.settings
            || !currentRow.settings.cells
            || !(
                column.name &&
                currentRow.settings?.cells?.[column.name]?.visible === 'false'
            )
        );
    }

    /**
     * Retrieve color for table row or cell.
     *
     * Row color settings are overwritten by cell colors, since they are more specific.
     */
    public static getTableColor(row: ResponseRow, column: HtTableColumn): string | undefined {
        let color = this.getTableRowColor(row);

        if (row.settings?.cells?.[column.name]?.colour) {
            color = row.settings.cells[column.name].colour;
        }

        return color;
    }

    /**
     * Retrieve color defined for table row.
     */
    public static getTableRowColor(row: ResponseRow): string | undefined {
        return row.settings?.row?.['colour'];
    }
}
