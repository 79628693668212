import { FormElement, DataItem } from '@hrs-ui/util-definitions';

export class CollectHiddenDataFromDataItemsUtil {

    /**
     * Collects and combines filter items with hidden columns
     */
    public static collectHiddenData(insertItems: Array<DataItem | FormElement>): object {
        let hiddenDataFromFilterItems: object = {};

        insertItems.forEach(item => {
            if (item.hidden) {
                hiddenDataFromFilterItems = {
                    ...hiddenDataFromFilterItems,
                    [item.name] : item.value,
                };
            }
        });

        return hiddenDataFromFilterItems;
    }
}
