<div class="square">
    <img
        src="data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'&gt;&lt;/svg&gt;"
        alt="square"
    />

    @if (disabled) {
        <ui-icon
            class="icon disabled"
            [iconName]="'locked'"
            [iconSize]="20"
        />
    } @else {
        <ui-icon
            class="icon chevron"
            [iconName]="'chevron-down'"
            [iconSize]="20"
        />
    }
</div>
