import { ChangeDetectionStrategy, Component, HostBinding, Input, model } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-checkbox',
    templateUrl: './ht-checkbox.component.html',
    styleUrls: [
        './ht-checkbox.component.scss',
    ],
    standalone: true,
    imports: [
        FormsModule,
        MatCheckboxModule,
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class HtCheckboxComponent {
    @HostBinding('class')
    public class = 'form-element';

    @Input()
    public name = '';

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public titleText?: string;

    @Input()
    public tabIndex = -1;

    public readonly value$ = model<boolean>(false, { alias: 'value' });

    /**
     * toggles the checkbox and emits
     *
     * @param $event
     */
    public toggleChecked(event?: { checked: boolean }): void {
        this.value$.set(event ? event.checked : !this.value$());
    }
}
