@if (readOnly) {
    <span class="text">
        {{ disabledValue }}
    </span>
} @else {
    <form #form="ngForm">
        <ht-text
            [placeholder]="placeholderText"
            [value]="textValue"
            [selectValues]="selectValues"
            [disabled]="readOnly"
            [tabIndex]="tabIndex"
            [name]="'number'"
            [nonNeg]="nonNeg"
            (valueChange)="valueChange.emit($event)"
        />
    </form>
}
