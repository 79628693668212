import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    HostBinding,
    inject,
    input,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { IconService } from '../../services/icon.service';

import { AppTheming, CanThemeConstructor, CanTheme, mixinTheme } from '@hrs-ui/util-component-support';
import { IconBase } from '../../definitions/icon-base';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const _IconMixinBase: CanThemeConstructor & typeof IconBase = mixinTheme(IconBase);

/**
 * Represents an icon
 */
@Component({
    selector: 'ui-icon',
    templateUrl: './ui-icon.component.html',
    styleUrls: ['./ui-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class UiIconComponent extends _IconMixinBase implements CanTheme {
    @Input()
    public override theme: AppTheming = AppTheming.Primary;

    @HostBinding('class.icon')
    private readonly _hasIconClass = true;

    /**
     * Name of the symbol in the svg sprite.
     */
    public readonly iconName$ = input<string | undefined>(undefined, { alias: 'iconName' });

    /**
     * Size of the symbol in the svg sprite.
     */
    public readonly iconSize$ = input<number | undefined>(undefined, { alias: 'iconSize' });

    public readonly iconData$: Observable<SafeHtml>;

    private readonly _iconService = inject(IconService);
    private readonly _domSanitizer = inject(DomSanitizer);

    constructor() {
        super();

        this.iconData$ = combineLatest([
            toObservable(this.iconName$),
            toObservable(this.iconSize$),
        ])
            .pipe(
                switchMap(([name, size]) => (name && size) ? this._iconService.icon$(name, size) : of('')),
                map(iconData => this._domSanitizer.bypassSecurityTrustHtml(iconData)),
            );
    }
}
