@if (editModeDisabled) {
    <ui-icon
        [iconName]="iconName$()"
        [iconSize]="20"
        [class]="iconStyle$()"
    />
} @else {
    <ht-checkbox
        #htCheckbox
        class="ht-checkbox"
        [name]="'checkbox'"
        [disabled]="isDisabled$()"
        [titleText]="label"
        [tabIndex]="tabIndex"
        [(value)]="isChecked$"
    />
}
