@if (openInModal) {
    <ui-button
        class="insert-add-button"
        type="button"
        icon="add"
        [label]="insertData?.tableTitle || 'content.insert.add' | translate"
        [iconSize]="16"
        [hasIconLeft]="true"
        (click)="openInsertInModal()"
    />
} @else {
    <div
        #wrapper
        class="widget--card"
    >
        @if (insertData?.tableTitle) {
            <h2 class="edit-title">
                {{ insertData?.tableTitle }}
            </h2>
        }

        <form
            #insertForm="ngForm"
            (ngSubmit)="saveInsert()"
        >
            <div class="form--elements">
                @for (item of insertData?.items; track item.name) {
                    @if (!item.hidden) {
                        <div class="item">
                            <div class="title">
                                {{ item.displayName }}
                            </div>

                            <div class="value">
                                <ht-input-mapper
                                    [isFirstInput]="$first"
                                    [item]="item"
                                    [tabIndex]="0"
                                />
                            </div>
                        </div>
                    }
                }
            </div>

            @if (modalId || insertButtonData) {
                <div class="button-row">
                    <div class="spacer"></div>

                    @if (modalId) {
                        <ui-button
                            icon="close"
                            [label]="'content.insert.close' | translate"
                            [iconSize]="16"
                            [hasIconLeft]="true"
                            [isOutlined]="true"
                            (click)="closeModal(modalId)"
                        />
                    }

                    @if (insertButtonData) {
                        <ui-button
                            type="submit"
                            icon="check"
                            [label]="'content.insert.save' | translate"
                            [disabled]="!insertForm.form.valid"
                            [iconSize]="16"
                            [hasIconLeft]="true"
                            [tabIndex]="0"
                            (focusout)="resetFocus()"
                        />
                    }
                </div>
            }
        </form>
    </div>
}
