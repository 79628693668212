import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { ResponseTable } from '@hrs-ui/util-definitions';

@Component({
    selector: 'ht-spacer',
    template: '',
    styleUrls: ['./spacer.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpacerComponent {
    @HostBinding('style.height.px')
    @HostBinding('style.width.px')
    public spacing = '0';

    @Input({ required: true })
    public set itemData(itemData: ResponseTable) {
        this.spacing = itemData.settings?.width ?? '0';
    }
}
