import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PageData } from '@hrs-ui/util-definitions';

import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { TableMapperComponent } from '../table-mapper/table-mapper.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'ht-multitable-mapper',
    templateUrl: './multitable-mapper.component.html',
    styleUrls: [
        './multitable-mapper.component.scss',
    ],
    standalone: true,
    imports: [
        CommonModule,
        TranslationModule,
        TableMapperComponent,
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultitableMapperComponent {
    @Input() public multitables?: PageData['multitables'];
    @Input() public modalId?: number;
}
