@if (item$(); as item) {
    @switch (item.type) {
        @case (DataTypes.Text) {
            <ht-text
                [name]="item.name"
                [disabled]="item.readOnly ? item.readOnly : false"
                [placeholder]="itemDefaultValue$()"
                [required]="item.required ? item.required : false"
                [selectValues]="selectValues$()"
                [isDefaultTextEntry]="isDefaultTextEntry$()"
                [tabIndex]="tabIndex"
                [(value)]="itemValue$().asString"
                (valueChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.Multiselect) {
            <ht-multi-select
                [placeholder]="itemDefaultValue$()"
                [values]="selectValues$() ?? []"
                [disabled]="item.readOnly ? item.readOnly : false"
                [required]="item.required ? item.required : false"
                [name]="item.name"
                [tabIndex]="tabIndex"
                [isDefaultSelect]="isDefaultTextEntry$()"
                [allowSearch]="true"
                [(value)]="itemValue$().asStringArray"
                (valueChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.Textarea) {
            <ht-textarea
                [name]="item.name"
                [editModeDisabled]="item.readOnly ? item.readOnly : false"
                [placeholderText]="itemDefaultValue$()"
                [required]="item.required ? item.required : false"
                [tabIndex]="tabIndex"
                [(ngModel)]="itemValue$().asString"
                (ngModelChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.TextArray) {
            <ng-container [ngTemplateOutlet]="textArray" />
        }

        @case (DataTypes.EmailArray) {
            <ng-container [ngTemplateOutlet]="textArray" />
        }

        @case (DataTypes.Password) {
            <ht-input
                [type]="'password'"
                [name]="item.name"
                [disabled]="item.readOnly ? item.readOnly : false"
                [required]="item.required ? item.required : false"
                [placeholder]="itemDefaultValue$()"
                [isDefaultTextEntry]="isDefaultTextEntry$()"
                [(value)]="itemValue$().asString"
                (valueChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.Numeric) {
            <ht-input
                [type]="'number'"
                [value]="itemValue$().asString"
                [name]="item.name"
                [disabled]="item.readOnly ? item.readOnly : false"
                [required]="item.required ? item.required : false"
                [placeholder]="itemDefaultValue$()"
                [isDefaultTextEntry]="isDefaultTextEntry$()"
                [tabIndex]="tabIndex"
                (valueChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.NumericNonNeg) {
            <ht-input
                [nonNeg]="true"
                [type]="'number'"
                [value]="itemValue$().asString"
                [name]="item.name"
                [disabled]="item.readOnly ? item.readOnly : false"
                [required]="item.required ? item.required : false"
                [placeholder]="itemDefaultValue$()"
                [isDefaultTextEntry]="isDefaultTextEntry$()"
                [tabIndex]="tabIndex"
                (valueChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.DateTime) {
            <ng-container [ngTemplateOutlet]="datePicker" />
        }

        @case (DataTypes.Date) {
            <ng-container [ngTemplateOutlet]="datePicker" />
        }

        @case (DataTypes.AjaxArray) {
            <ht-ajax-autocomplete
                [placeholder]="itemDefaultValue$()"
                [disabled]="item.readOnly ? item.readOnly : false"
                [name]="item.name"
                [ajaxoperationId]="item.ajaxoperationId"
                [ajaxproperty]="item.ajaxproperty"
                [ajaxtrigger]="item.ajaxtrigger"
                [filters]="item.filters"
                [isDefaultTextEntry]="isDefaultTextEntry$()"
                [tabIndex]="tabIndex"
                [(value)]="itemValue$().asString"
                (valueChange)="valueChange.emit($event)"
            />
        }

        @case (DataTypes.Boolean) {
            <ht-checkbox
                [name]="item.name"
                [disabled]="item.readOnly ? item.readOnly : false"
                [titleText]="item.titletext"
                [required]="item.required ? item.required : false"
                [tabIndex]="tabIndex"
                [(value)]="itemValue$().asBoolean"
                (valueChange)="valueChange.emit($event)"
            />
        }
    }

    @if (item.settings?.hint) {
        <span class="text-small">
            {{ item.settings?.hint }}
        </span>
    }

    <ng-template #datePicker>
        <ht-datepicker
            [dataType]="item.type"
            [name]="item.name"
            [disabled]="item.readOnly ? item.readOnly : false"
            [required]="item.required ? item.required : false"
            [placeholder]="itemDefaultValue$()"
            [tabIndex]="tabIndex"
            [(value)]="itemValue$().asDate"
            (valueChange)="valueChange.emit($event)"
        />
    </ng-template>

    <ng-template #textArray>
        <ht-textarray
            [readOnly]="item.readOnly ? item.readOnly : false"
            [name]="item.name"
            [tabIndex]="tabIndex"
            [(value)]="itemValue$().asStringArray"
            (valueChange)="valueChange.emit($event)"
        />
    </ng-template>
}
