import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { LegendComponent } from '../legend/legend.component';
import { ResponseTable } from '@hrs-ui/util-definitions';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';

@Component({
    selector: 'ht-legend-mapper',
    templateUrl: './legend-mapper.component.html',
    styleUrls: ['./legend-mapper.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
        TranslationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendMapperComponent {

    @Input() public legend?: ResponseTable;

    private readonly _modalService = inject(ModalService);

    /**
     * used within the template to open legend-modal
     */
    public openLegend(): void {
        this._modalService
            .open(LegendComponent, {
                legend: this.legend,
            });
    }
}
