import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    Signal,
    computed,
    effect,
    inject,
    input,
    signal,
} from '@angular/core';
import { ButtonCallbacks } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { HtButton, HtButtType, ButtonName } from '@hrs-ui/util-definitions';

/* Currently available icon svg sizes are 16px, 20px and 40px.
The nearest available icon size will be selected and scaled to the provided iconSize. */
const iconSizeInPixel = 18;

const defaultButton: HtButton = {
    id: ButtonName.OuSupplyId,
    text: '',
    buttType: HtButtType.Submit,
    operationId: '',
};

/**
 * A button that handles logic pertaining to buttons that make requests to the backend
 * and are generated by htraks api
 * Info on buttons can be found here: https://jira.prospitalia.de/browse/HRS-136
 */
@Component({
    selector: 'ht-button',
    templateUrl: './ht-button.component.html',
    styleUrls: ['./ht-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        UiButtonComponent,
    ],
})
export class HtButtonComponent {

    /**
     * A manual setting of the button text.
     *
     * The supplied string will overwrite htButton.text if both are used.
     */
    @Input()
    public buttonText?: string;

    @Input()
    public disabled = false;

    /**
     * Whether to display the icon with an outlined style.
     */
    @Input()
    public isOutlined = false;

    /**
     * Event on button clicked.
     */
    @Output()
    public readonly clickEvent: EventEmitter<ButtonCallbacks> = new EventEmitter();

    public readonly button$ = input<HtButton>(defaultButton, { alias: 'htButton' });

    public readonly icon$ = input<string | undefined>(undefined, { alias: 'icon' });

    public readonly isProcessing$ = signal(false);

    public readonly activeIcon$: Signal<string | undefined>;

    public iconSize = iconSizeInPixel;

    private readonly _buttonService = inject(ButtonService);

    constructor() {
        this.activeIcon$ = computed(() => {
            const button = this.button$();
            const icon = this.icon$();

            // Whenever the button has an icon name, override the current icon.
            return this._buttonService.getIconName(button.id) ?? icon;
        });

        effect(() => {
            // Whenever the button changes, reset the processing state.
            if (this.button$()) {
                this.isProcessing$.set(false);
            }
        }, { allowSignalWrites: true });
    }

    /**
     * Handle button click.
     */
    public buttonClick(): void {
        // disable button until page reload
        this.clickEvent.emit({
            buttonDisableCallback: () => { this.isProcessing$.set(true); },
            buttonEnableCallback: () => { this.isProcessing$.set(false); },
        });
    }
}
