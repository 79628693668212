<button
    class="button"
    [class.button--disabled]="disabled"
    [class.button--processing]="processing"
    [class.button--outlined]="isOutlined"
    [disabled]="disabled || processing || undefined"
    [type]="type || 'button'"
    [tabindex]="tabIndex"
>
    @if (processing) {
        <img
            class="button__processing-icon"
            src="/assets/images/loading.gif"
            alt=""
        />
    }

    @if (icon && hasIconLeft) {
        <ui-icon
            class="button__icon"
            [iconName]="icon"
            [iconSize]="iconSize"
        />
    }

    @if (label) {
        <div
            class="button__label"
            [innerHtml]="label"
        ></div>
    }

    @if (icon && !hasIconLeft) {
        <ui-icon
            class="button__icon"
            [iconName]="icon"
            [iconSize]="iconSize"
        />
    }
</button>
