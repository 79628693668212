import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { PageResponse } from '@hrs-ui/util-definitions';
import { PageSizeOptionsPipe } from '../../../../utilities/page-size-options.pipe';

@Component({
    selector: 'ht-table-paginator',
    templateUrl: './table-paginator.component.html',
    styleUrls: ['./table-paginator.component.scss'],
    standalone: true,
    imports: [
        MatPaginatorModule,
        PageSizeOptionsPipe,
        MatSelectModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePaginatorComponent {
    @Input() public paginationDetails?: PageResponse | null;
    @Input() public paginatorSelectOptions: Array<number> = [];

    @Output() public readonly page = new EventEmitter<PageEvent>();
    @Output() public readonly pageSelection = new EventEmitter<MatSelectChange>();
}
