@if (!hidden) {
    <form #form="ngForm">
        <ht-editable-boolean
            [checked]="value$()"
            [editModeDisabled]="column$().readOnly || disabled"
            [disabled]="disabled"
            [tabIndex]="tabIndex"
            (checkedChange)="onValueChange($event)"
        />
    </form>
}
