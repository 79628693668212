import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APP_CONFIG, Environment } from '@hrs-ui/config/util-config';
import { BehaviorSubject, Observable, distinctUntilChanged, of, switchMap, shareReplay } from 'rxjs';

const iconBasePath = 'assets/icons';

@Injectable({
    providedIn: 'root',
})
export class IconService {
    public readonly icons$ = new BehaviorSubject<Map<number, Map<string, Observable<string>>>>(new Map());

    private readonly _httpClient = inject(HttpClient);
    private readonly _appConfig: Environment = inject(APP_CONFIG);

    public icon$(name: string, size: number): Observable<string> {
        return this.icons$
            .pipe(
                switchMap(icons => {
                    if (!icons.get(size)) {
                        icons.set(size, new Map());
                    }

                    const existingIcon = icons.get(size)?.get(name);

                    if (existingIcon) {
                        return existingIcon;
                    }

                    const request$ = this._getIconFromAssets$(name, size)
                        .pipe(
                            shareReplay(1),
                        );

                    icons.get(size)?.set(name, request$);

                    this.icons$.next(new Map(icons));

                    return request$;
                }),
                distinctUntilChanged(),
            );
    }

    /**
     * map given size to nearest available icon size
     *
     * @param size
     */
    private _getValidIconSize(size: number): number {
        const iconSizes = this._appConfig.ICON_SIZES;

        if (iconSizes && iconSizes.length) {
            let current = iconSizes[0];

            for (const value of iconSizes) {
                if (Math.abs(size - value) < Math.abs(size - current)) {
                    current = value;
                }
            }

            return current;
        }

        // if no matching size was found, use the largest size
        return iconSizes[iconSizes.length - 1];
    }

    private _getIconFromAssets$(name: string, size: number): Observable<string> {
        const validSize = this._getValidIconSize(size);

        return name
            ? this._httpClient.get<string>(`${ iconBasePath }/icon-${ validSize }-plain-${ name }.svg`)
            : of('');
    }
}
