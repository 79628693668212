import { ChangeDetectionStrategy, Component, Signal, computed, inject, input } from '@angular/core';
import { SidebarContentModel } from '../../../definitions/sidebar-content.model';
import { LinkTypeGuard } from '../../../utils/link.type-guard';
import { SidebarContentTypeGuard } from '../../../utils/sidebar-content.type-guard';
import { LinkModel } from '../../../definitions/link.model';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { LinkItemComponent } from '../link-item/link-item.component';

@Component({
    selector: 'ht-sidebar-menu-item',
    styleUrls: ['./menu-item.component.scss'],
    templateUrl: './menu-item.component.html',
    standalone: true,
    imports: [
        UiIconComponent,
        LinkItemComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
    public readonly menuItem$ = input<SidebarContentModel | undefined>(undefined, { alias: 'menuItem' });

    public readonly submenus$: Signal<Array<{
        asSidebarItem: SidebarContentModel | undefined;
        asLinkItem: LinkModel | undefined;
    }>>;

    private readonly _sidebarContentTypeGuard = inject(SidebarContentTypeGuard);
    private readonly _linkTypeGuard = inject(LinkTypeGuard);

    constructor() {
        this.submenus$ = computed(() =>
            this.menuItem$()?.submenus
                .map(submenu => ({
                    asSidebarItem: this._sidebarContentTypeGuard.isSidebarContent(submenu) ? submenu : undefined,
                    asLinkItem: this._linkTypeGuard.isLink(submenu) ? submenu : undefined,
                }))
            ?? []);
    }
}
