@if (note) {
    <div class="ht-note">
        <ui-icon
            [iconName]="'info'"
            [iconSize]="40"
        />

        <div class="separator"></div>

        <span class="text">
            {{ note }}
        </span>
    </div>
}
