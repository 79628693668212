import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent {
    @Input()
    public note?: string;
}
