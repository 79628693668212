import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import { Injectable, inject } from '@angular/core';
import { ApiService } from '@hrs-ui/api/util-api';

interface Language { CultureCode: string; DisplayName: string }
interface LanguageResponse {
    items: Array<Language>;
}

/**
 * Service for handling the language being switched for the entire app,
 * which is currently being used in the dropdown menu on the top navbar.
 */
@Injectable({
    providedIn: 'root',
})
export class UserLanguageService {
    private readonly _accessTokenCultureCode = 'hrs_culture_code';
    private readonly _defaultLanguage = 'en-AU';

    // set default to be 'en'
    private readonly _language$: BehaviorSubject<string> = new BehaviorSubject<string>(this._defaultLanguage);

    private readonly _translationService = inject(TranslationService);
    private readonly _apiService = inject(ApiService);

    constructor() {
        this._translationService.setDefaultLanguage(UserLanguageService._parseCultureCode(this._defaultLanguage));
        this._storeUserLanguage(window.localStorage.getItem(this._accessTokenCultureCode) || this._defaultLanguage);
    }

    public get language$(): Observable<string> {
        return this._language$.asObservable();
    }

    public get languages$(): Observable<Array<{ CultureCode: string; DisplayName: string }>> {
        return this._apiService.executeOperationId<LanguageResponse>('auth_languages').pipe(map(response => response.items));
    }

    public get language(): string {
        return this._language$.getValue();
    }

    public set language(language: string) {
        this._storeUserLanguage(language);
    }

    /**
     * parse the provided cultureCode (eg. de-DE) for the translateService (eg. de)
     *
     * @param cultureCode
     */
    private static _parseCultureCode(cultureCode: string): string {
        const cultureCodeStringEndIndex = 2;

        return cultureCode.substr(0, cultureCodeStringEndIndex);
    }

    /**
     * Change user language
     */
    public changeLanguage(cultureCode: string): void {
        this._apiService
            .getFunction('auth_switch_language')(cultureCode)
            .pipe(take(1))
            .subscribe(() => {
                this.language = cultureCode;
            });
    }

    /**
     * Set User language for internal use
     */
    public removeUserLanguage(): void {
        window.localStorage.removeItem(this._accessTokenCultureCode);
    }

    /**
     * Set User language for internal use
     */
    private _storeUserLanguage(language: string): void {
        window.localStorage.setItem(this._accessTokenCultureCode, language);
        this._translationService.setLanguage(UserLanguageService._parseCultureCode(language));
        this._language$.next(language);
    }
}
