@if (mimicText) {
    {{ value || placeholder }}
} @else {
    @if (label) {
        <label
            [style.display]="hideLabel ? 'none' : 'block'"
            [attr.for]="'input--' + name"
            >{{ label }}</label
        >
    }

    @if (type !== 'textarea') {
        <div class="input__wrapper">
            <input
                #input="ngModel"
                #element
                class="input__form-element {{ 'input--' + name }}"
                type="{{ type || 'text' }}"
                [placeholder]="placeholder || ''"
                [disabled]="disabled"
                [tabindex]="tabIndex"
                [autocomplete]="autocomplete"
                [(ngModel)]="value"
                (focus)="focusSet.next(input)"
                (blur)="blurSet.next(input); onTouched(value)"
                (beforeinput)="validateInput($event)"
                (keydown)="keyDownFn?.($event)"
            />

            <div #ref>
                <ng-content />
            </div>

            @if (icon) {
                <div>
                    <ui-icon
                        class="input__icon"
                        [iconName]="icon"
                        [iconSize]="iconSize"
                    />
                </div>
            } @else {
                <!-- There is currently no validation mechanism in place. These validity icons are always hidden. -->
                @if (!disabled) {
                    @if (!hideValidityIcons && ref.children && ref.children.length === 0) {
                        <div>
                            <ui-icon
                                class="input__icon input__icon--invalid"
                                [iconName]="'error-circle'"
                                [iconSize]="iconSize"
                            />

                            <ui-icon
                                class="input__icon input__icon--valid"
                                [iconName]="'check-circle'"
                                [iconSize]="iconSize"
                            />
                        </div>
                    }
                } @else {
                    @if (ref.children && ref.children.length === 0) {
                        <ui-icon
                            class="input__icon input__icon--disabled"
                            [iconName]="'inputlock'"
                            [iconSize]="iconSize"
                        />
                    }
                }
            }
        </div>
    } @else {
        <div class="input__wrapper">
            <textarea
                #input="ngModel"
                rows="6"
                class="input__form-element"
                [maxlength]="maxLength"
                [attr.id]="'input--' + name"
                [placeholder]="placeholder || ''"
                [disabled]="disabled"
                [tabindex]="tabIndex"
                [(ngModel)]="value"
                (focus)="focusSet.next(input)"
                (blur)="blurSet.next(input); onTouched(value)"
            ></textarea>

            @if (maxLength) {
                <div class="input__counter">{{ remainingCharacters }} | {{ maxLength }}</div>
            }
        </div>
    }
}
