@if (listeningForAjaxTrigger) {
    <ht-select
        [name]="name"
        [disabled]="disabled"
        [required]="required"
        [placeholder]="placeholder"
        [values]="values$ | async"
        [allowSearch]="true"
        [tabIndex]="tabIndex"
        [(value)]="value$"
    />
} @else {
    <ht-input
        [name]="name"
        [disabled]="disabled"
        [required]="required"
        [placeholder]="placeholder"
        [autocompleteBinding]="auto"
        [isDefaultTextEntry]="isDefaultTextEntry"
        [tabIndex]="tabIndex"
        [(value)]="value$"
        (input)="requestOptions($event)"
    />

    <mat-autocomplete #auto="matAutocomplete">
        <!-- Hidden option is needed to stop material to close panel -->
        <mat-option style="display: none" />

        @if (values$ | async; as options) {
            @if (!isLoading$()) {
                @for (option of options; track option.id) {
                    <mat-option [value]="option.id">
                        {{ option.value }}
                    </mat-option>
                }
            }
        }
    </mat-autocomplete>
}
