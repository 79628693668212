/**
 * Creates an Array of FilterData out of the ResponseTable
 *
 * @param data
 */
import { DataTypes, HtItemType, HtRowData, ResponseTable, FormElement } from '@hrs-ui/util-definitions';
import { ButtonEventData } from '@hrs-ui/ht-button/util-ht-button';
import { NgForm } from '@angular/forms';


export class FormDataUtil {
    /**
     * Creates an Array of FilterData out of the ResponseTable
     *
     * @param data
     */
    public static makeFormData(data: ResponseTable | undefined): Array<FormElement> {
        if (!data || !data.rows[0]) {
            return [];
        }

        const row = data.rows[0];

        return data.columns.map(column => {
            const defaultValue = FormDataUtil._parseValueByType(column.dataType, row.rowData[column.id]);

            return {
                name: column.id,
                titletext: column.titletext,
                type: column.dataType,
                values: column.values,
                keys: column.keys,
                readOnly: column.readOnly,
                hidden: column.settings && column.settings.visible === 'false',
                ajaxoperationId: column.ajaxoperationId,
                ajaxproperty: column.ajaxproperty,
                ajaxtrigger: column.ajaxtrigger,
                settings: row.settings ? row.settings : undefined,
                defaultValue,
                value: defaultValue !== undefined
                    ? defaultValue
                    : '',
            };
        });
    }

    /**
     * create filter submit Data
     *
     * @param form
     * @param buttonEvent
     */
    public static getFormSubmitData(form: NgForm | undefined, buttonEvent: ButtonEventData): HtRowData {
        if (!form) {
            return {};
        }

        const formValue = form.value;
        const formData = { ...formValue, ...buttonEvent.parameter };

        Object.keys(formData)
            .map(key => {
                if (formData[key] instanceof Date) {
                    const timezoneOffsetMultiplier = 60000;

                    formData[key] = new Date(formData[key].getTime() - (formData[key].getTimezoneOffset() * timezoneOffsetMultiplier))
                        .toISOString();
                }
            });

        return formData;
    }

    /**
     * parse the default Value by Column Type
     *
     * @param dataType
     * @param value
     */
    private static _parseValueByType(
        dataType: DataTypes,
        value: HtItemType,
    ): HtItemType {
        if (value === undefined) {
            return undefined;
        }

        switch (dataType) {
            case DataTypes.Boolean: {
                return !!value;
            }
            case DataTypes.DateTime:
            case DataTypes.Date: {
                return value ? new Date(value.toString()) : new Date();
            }
            default: {
                return value;
            }
        }
    }
}
